import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import LinkButton from '../../../../../components/buttons/LinkButton';
import { connectionAvatar } from '../../../../../helpers/ConnectionHelpers';
import ActionShape from '../../../../../shapes/ActionShape';
import { formatDateTimeWithSeconds, formatDate } from '../../../../../helpers/DateHelpers';
import useMe from '../../../../../context/CurrentUserContext';
import LogShape from '../../../../../shapes/LogShape';
import ModalBody from '../../../../Action/ShowPage/LogDetailModalBody';
import Modal from '../../../../../components/Modal';
import RequestListModalBody from './RequestListModalBody';
import EventBody from './EventBody';

function logStatusColor(log) {
  const statusCode = log.body?.http_status;
  if (statusCode >= 200 && statusCode < 299) return 'bg-teal-50 text-teal-500 ring-teal-500';
  if (statusCode >= 400 && statusCode < 499) return 'bg-yellow-50 text-yellow-500 ring-yellow-500';
  if (statusCode >= 500) return 'bg-red-50 text-red-500 ring-red-500';
  return 'bg-gray-50 text-gray-500 ring-gray-500';
}

export default function Succeeded({ action, actionLogs, parentAction }) {
  const [displayEvent, setDisplayEvent] = useState(null);
  const [displayRequest, setDisplayRequest] = useState(null);
  const [displayRequestList, setDisplayRequestList] = useState(false);

  const { connections } = useMe();
  const destinationConnection = connections.find((c) => c.id === action.destination_connection_id);
  const eventId = actionLogs[0].records.event_id;
  const processLog = actionLogs[0];

  const attempts = actionLogs.length;
  // eslint-disable-next-line radix
  const maxRetryCount = parseInt(action.retry_strategy?.max_attempts || 5);
  const maxAttemptsReached = attempts >= maxRetryCount;

  return (
    <>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={!!displayEvent}
        onClose={() => setDisplayEvent()}
      >
        <EventBody
          eventId={eventId}
          onClose={() => setDisplayEvent()}
        />
      </Modal>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={displayRequestList}
        onClose={() => setDisplayRequestList(false)}
      >
        <RequestListModalBody
          logs={actionLogs}
          onClose={() => setDisplayRequestList(false)}
          setCurrentLog={setDisplayRequest}
        />
      </Modal>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={!!displayRequest}
        onClose={() => setDisplayRequest(null)}
      >
        <ModalBody log={displayRequest} onClose={() => setDisplayRequest(null)} />
      </Modal>
      <div className="rounded p-2 text-gray-500 border border-gray-300 text-xs space-y-2">
        <div className="flex justify-between border-b w-full pb-1">
          <LinkButton path={`/actions/${action?.id}`} buttonText={action.name} />
          <span className="flex">
            <Tooltip
              id={`tool${action.id}`}
              content={(
                <p>
                  Succeeded at
                  {' '}
                  {formatDate(actionLogs[0].inserted_at)}
                  {' '}
                  {formatDateTimeWithSeconds(actionLogs[0].inserted_at)}
                </p>
              )}
            />
            <span data-tooltip-id={`tool${action.id}`} className="flex space-x-1 text-teal-500 hover:cursor-pointer">
              <p>Success</p>
              <IoCheckmarkCircleOutline className="h-4 w-4" />
            </span>
          </span>
        </div>

        <span className="flex space-x-1 md:flex">
          <p className="text-gray-500">Endpoint:</p>
          <div className="text-gray-500 flex space-x-1">
            <span>
              {connectionAvatar(destinationConnection, { size: 'xxs' })}
            </span>
            <span className="hidden xl:block">
              {destinationConnection?.configuration?.url}
            </span>
            <p className="text-teal-600 font-semibold">
              {processLog.body.method}
            </p>
            <p>{processLog.body.url?.replace(destinationConnection?.configuration?.url, '')}</p>
            <p
              className={classNames(
                'max-h-4 inline-flex items-center rounded px-0.5 text-xs font-medium ring-1 ring-inset',
                logStatusColor(processLog),
              )}
            >
              {processLog.body.http_status}
            </p>
          </div>
        </span>
        <span className="flex space-x-1 w-full">
          <p className="text-gray-500">Requests:</p>
          <span className="flex">
            <LinkButton
              className="font-medium text-xs"
              onClick={() => setDisplayRequestList(true)}
              buttonText={`${actionLogs?.length} Attempt${actionLogs?.length === 1 ? '' : 's'}`}
            />
            { maxAttemptsReached ? <p>(Max attempts reached)</p> : null }
          </span>
        </span>
        <span className="flex space-x-1 w-full">
          <p className="text-gray-500">Trigger:</p>
          <span className="flex">
            <LinkButton
              className="font-medium text-xs"
              buttonText={parentAction ? `${parentAction.name} Response` : `${action.topic} Event`}
              onClick={() => setDisplayEvent(eventId)}
            />
          </span>
        </span>
      </div>
    </>
  );
}

Succeeded.propTypes = {
  action: ActionShape.isRequired,
  actionLogs: PropTypes.arrayOf(LogShape).isRequired,
  parentAction: ActionShape,
};

Succeeded.defaultProps = {
  parentAction: null,
};
