import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import MidDot from '../../../../components/MidDot';
import LinkButton from '../../../../components/buttons/LinkButton';
import _ from 'lodash';

function ActionEvalNode({ data: { actionEvalLogs } }) {
  const nonMatchingProcessLogs = actionEvalLogs.filter((l) => l.body.result === false);
  const matchingProcessLogs = actionEvalLogs.filter((l) => l.body.result !== false);
  const uniqEvals = _.uniqBy(actionEvalLogs, 'records.action');
  return (
    <div className="px-2 py-1 rounded-sm bg-white border border-gray-300" style={{ height: '84px', width: '540px' }}>
      <h3 className="text-base font-semibold leading-6 text-gray-700 border-b">
        Actions Evaluated
      </h3>
      <div className="ml-2 mt-2">
        <p className="text-sm text-gray-600 font-medium">
          {uniqEvals.length}
          {' '}
          unique evaluations
          <MidDot />
          {uniqEvals.length}
          {' '}
          total evaluations
        </p>
        <div className="text-gray-500 text-xs flex justify-between">
          {nonMatchingProcessLogs.length}
          {' '}
          non-matching actions
          <LinkButton buttonText="View Logs" onClick={() => console.log('boom')} />
        </div>
      </div>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}

export default memo(ActionEvalNode);
