import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TimeAgo from 'react-timeago';
import Modal from '../../../components/Modal';
import LinkButton from '../../../components/buttons/LinkButton';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import { logStatusColor } from '../../../helpers/LogHelpers';
import LogShape from '../../../shapes/LogShape';
import LogDetailModalBody from '../../Action/ShowPage/LogDetailModalBody';
import useMe from '../../../context/CurrentUserContext';

function Row({ log, setCurrentLog }) {
  const { connections } = useMe();
  const connection = connections.find((c) => c.id === log.records.destination_connection_id);
  const statusCode = log.body?.http_status;
  const method = log.body?.method?.toUpperCase();
  const statusColor = logStatusColor(log);
  const url = log.body?.url;
  const insertedAt = <TimeAgo date={`${log.inserted_at} GMT`} />;

  return (
    <tr key={log.id}>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        <div className="flex items-center">
          { connectionAvatar(connection, { size: 'xxs' })}
          <div className="ml-2">
            <div className="text-xs flex space-x-1">
              <p className="text-teal-600 font-semibold">
                {method}
              </p>
              <p className="text-gray-500">
                {url}
              </p>
            </div>
          </div>
        </div>
      </td>
      <td>
        <span className={`mx-2 px-0.5 text-sm rounded border border-${statusColor} text-${statusColor}`}>
          {statusCode}
        </span>
      </td>
      <td className="text-sm text-gray-500">
        {insertedAt}
      </td>
      <td className="hidden py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 sm:table-cell">
        <LinkButton buttonText="Details" onClick={() => setCurrentLog(log)} />
      </td>
    </tr>
  );
}

Row.propTypes = {
  log: LogShape.isRequired,
  setCurrentLog: PropTypes.func.isRequired,
};

export default function Body({ items }) {
  const [currentLog, setCurrentLog] = useState(null);

  return (
    <>
      <Modal maxWidth="sm:max-w-5xl sm:w-full" open={!!currentLog} onClose={() => setCurrentLog(null)}>
        <LogDetailModalBody
          backText="Back to Request List"
          log={currentLog}
          onClose={() => setCurrentLog(null)}
        />
      </Modal>
      <tbody className="divide-y divide-gray-200 bg-white">
        {items.map((item) => <Row log={item} setCurrentLog={setCurrentLog} />)}
      </tbody>
    </>
  );
}

Body.propTypes = {
  items: PropTypes.arrayOf(LogShape).isRequired,
};
