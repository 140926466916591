import PropTypes from 'prop-types';
import React from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export default function ComboBoxOption({
  labelKey,
  option,
  optionClass,
  imagePath,
}) {
  return (
    <Combobox.Option
      className={({ active }) => classNames(
        optionClass || 'relative cursor-default select-none py-2 pl-10 pr-4',
        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
      )}
      value={option}
    >
      {({ selected, active }) => (
        <>
          <div className="flex items-center">
            {
              imagePath
                ? (<img src={imagePath} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />)
                : null
            }
            <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{option[labelKey]}</span>
          </div>
          {selected && (
          <span
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pr-4',
              active ? 'text-white' : 'text-indigo-600',
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
          )}
        </>
      )}
    </Combobox.Option>
  );
}

ComboBoxOption.propTypes = {
  labelKey: PropTypes.string.isRequired,
  option: PropTypes.shape().isRequired,
  optionClass: PropTypes.string,
  imagePath: PropTypes.string,
};

ComboBoxOption.defaultProps = {
  optionClass: null,
  imagePath: null,
};
