import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import View from './View';
import Loading from '../../../components/Loading';
import { ErrorContext } from '../../../context/ErrorContext';
import { any } from '../../../helpers/ArrayHelpers';
import useClient from '../../../hooks/useClient';

export default function ShowPage() {
  const { setServerError } = useContext(ErrorContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const client = useClient('actions');

  const { data: action, isLoading: isLoadingAction } = client.get(id);

  const { data: sourceConnection, isLoading: isLoadingSourceConnection } = useClient('connections').get(
    action?.source_connection_id,
    { enabled: !!(action?.source_connection_id) },
  );

  const { data: destinationConnection, isLoading: isLoadingDestination } = useClient('connections').get(
    action?.destination_connection_id,
    { enabled: !!(action?.destination_connection_id) },
  );

  const { data: actionTemplate, isLoading: isLoadingActionTemplates } = useClient('action_templates').get(
    action?.action_template_id,
    { enabled: !!(action?.action_template_id) },
  );

  const { data: workflow, isFetching: isLoadingWorkflow } = useClient('workflows').get(
    action?.workflow_id,
    { enabled: !!(action?.workflow_id) },
  );

  const { isLoading: isDestroying, mutate: destroy } = client.destroy(
    id,
    {
      onSuccess: () => navigate('/actions'),
      onError: () => setServerError(true),
    },
  );

  const isLoading = any([
    isLoadingAction,
    isLoadingSourceConnection,
    isLoadingDestination,
    (action?.action_template_id && isLoadingActionTemplates),
    isLoadingWorkflow,
  ]);

  if (isLoading && !action) return <Loading />;

  return (
    <View
      action={{
        ...action,
        sourceConnection,
        destinationConnection,
        actionTemplate,
        workflow,
      }}
      onDestroy={destroy}
    />
  );
}
