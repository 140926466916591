import React, { useEffect, useState } from 'react';
import EventShape from '../../../shapes/EventShape';
import ShowPageSectionCard from '../../../components/layout/ShowPage/SectionCard';
import ActionTree from '../ActionTree';
import useClient from '../../../hooks/useClient';

export default function ActionTreeCard({ event }) {
  const { data: events, isLoading } = useClient('events').list({
    thread: event.thread,
    page_size: 50,
  });

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);

  const eventsIds = events?.map((e) => e.id) || [];
  const { data, meta } = useClient('logs').list(
    { records: { event_id: [...[event.id], ...eventsIds] }, page_size: 100 },
    { enabled: !!events?.length },
  );

  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    } else {
      setIsLoadingLogs(false);
    }
  }, [meta?.page]);

  return (
    <ShowPageSectionCard
      title="Event Tree"
      // subTitle="General details about this event"
    >
      <ActionTree
        event={event}
        threadEvents={events}
        threadLogs={logs}
        isLoadingLogs={isLoadingLogs}
      />
    </ShowPageSectionCard>
  );
}

ActionTreeCard.propTypes = {
  event: EventShape.isRequired,
};
