import React from 'react';
import PropTypes from 'prop-types';
import ReactDiffViewer from 'react-diff-viewer-continued';
import LogShape from '../../../../../../shapes/LogShape';
import { byInsertedAt } from '../../../../../../helpers/LogHelpers';

export default function ModalBody({ onClose, log }) {
  const { updateLogs } = log;
  const sortedUpdateLogs = updateLogs.sort(byInsertedAt);
  const isCurrentLog = (l) => l.id === log.id;
  const idx = sortedUpdateLogs.findIndex(isCurrentLog);

  const oldData = idx === 0 ? log.createLog.body : sortedUpdateLogs[idx - 1];
  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Log Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request body and response data for this action request.</p>
      </div>
      <div className="mx-4 mt-2 rounded border border-gray-300 text-xs">
        <ReactDiffViewer
          oldValue={JSON.stringify(oldData, null, ' ')}
          newValue={JSON.stringify(log.body, null, ' ')}
          showDiffOnly
          splitView
        />
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </div>
  );
}

ModalBody.propTypes = {
  log: LogShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
