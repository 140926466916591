import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function RadioButtonGroup({
  name, options, label, valueOverride, onChangeOverride,
}) {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <RadioGroup value={valueOverride} onChange={onChangeOverride} className="mt-2">
        <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
        <div className="flex">
          {options.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className={({ checked }) => classNames(
                'cursor-pointer focus:outline-none',
                checked
                  ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md px-2.5 py-1.5 text-sm mr-2',
              )}
              // disabled={!option.inStock}
            >
              <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  valueOverride: PropTypes.string,
  onChangeOverride: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

RadioButtonGroup.defaultProps = {
  valueOverride: null,
};
