import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import useMe from '../../../../context/CurrentUserContext';
import UrlPreview from '../../../../components/UrlPreview';
import LinkButton from '../../../../components/buttons/LinkButton';

function ActionProcessedNode({ data: { log, displayBottomHandle } }) {
  const { connections, actions } = useMe();
  // eslint-disable-next-line max-len
  const destinationConnection = connections.find(({ id }) => id === log.records.destination_connection_id);
  const action = actions.find(({ id }) => id === log.records.action_id);
  return (
    <div className="px-2 py-1 rounded-sm bg-white border border-gray-300" style={{ height: '110px', width: '540px' }}>
      <div className="flex justify-between  border-b">
        <h3 className="text-base font-semibold leading-6 text-gray-700">
          Action Processed
        </h3>
        <span className="text-gray-500">
          {action.topic}
          {' '}
          {}
        </span>
      </div>
      <div className="ml-2 mt-2 flex justify-between">
        <div className="text-sm flex space-x-1">
          <span className="mt-0.5">
            {connectionAvatar(destinationConnection, { size: 'xxs' })}
          </span>
          <p className="text-gray-600 font-medium ml-1">
            {destinationConnection?.name}
          </p>
        </div>
        <div className="text-gray-500 text-xs">
          <LinkButton
            buttonText={action.name}
            path={`/actions/${action.id}`}
          />
        </div>
      </div>
      <div className="ml-2">
        <UrlPreview
          method={action.configuration.method}
          url={action.configuration.url}
          destinationConnection={destinationConnection}
        />
      </div>
      <div className="border-t mt-2 pt-1 flex justify-between">
        <p className="text-gray-600 text-xs font-medium ml-2">
          Callback
        </p>
        <p className="text-gray-500 text-xs">
          {action.after_action?.topic || 'None'}
        </p>
      </div>
      <Handle type="target" position={Position.Top} />
      {displayBottomHandle ? <Handle type="source" position={Position.Bottom} /> : null }
    </div>
  );
}

export default memo(ActionProcessedNode);
