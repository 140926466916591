import React, { useState } from 'react';
import LogShape from '../../../../../../shapes/LogShape';
import ModalBody from './ModalBody';
import Modal from '../../../../../../components/Modal';
import LinkButton from '../../../../../../components/buttons/LinkButton';

export default function EventUpdatedSubLine({ log }) {
  const [displayModal, setDisplayModal] = useState(false);
  return (
    <>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={displayModal}
        onClose={() => setDisplayModal(false)}
      >
        <ModalBody
          log={log}
          onClose={() => setDisplayModal(false)}
        />
      </Modal>
      <span className="font-medium text-gray-500 ml-3">
        <span className="flex space-x-1">
          <p>Data Body:</p>
          <LinkButton className="text-xs" buttonText="Display Changes" onClick={() => setDisplayModal(true)} />
        </span>
      </span>

    </>
  );
}

EventUpdatedSubLine.propTypes = {
  log: LogShape.isRequired,
};
