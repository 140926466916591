// eslint-disable-next-line import/no-cycle
import useClient from './useClient';
// eslint-disable-next-line import/no-cycle
import withMany from './withMany';
import { unique } from '../helpers/ArrayHelpers';

function find(records, id) {
  return records?.find((record) => record.id === id);
}

function manyWithOne(response, relation, options) {
  const resource = `${options?.resource || relation}s`;
  const {
    // TODO: isLoadingBase could be used to ensure all relations calls go out at once when chaining.
    isLoading: isLoadingBase,
    data: records,
  } = response;

  const getKey = (record) => record[`${relation}_id`];
  const ids = unique((records || []).map(getKey));

  const { isLoadingRelations, data: relations } = useClient(resource).list({
    ids,
  }, {
    enabled: !isLoadingBase,
  });

  const isLoading = isLoadingBase || isLoadingRelations;
  if (isLoading) return response;

  const updatedRecords = (records || []).map((record) => ({
    ...record,
    [relation]: find(relations, getKey(record)),
  }));

  return {
    ...response,
    data: updatedRecords,
    isLoading,
    isLoadingBase,
  };
}

function oneWithOne(response, relation, options) {
  const resource = `${options?.resource || relation}s`;
  const {
    // TODO: isLoadingBase could be used to ensure all relations calls go out at once when chaining.
    isLoading: isLoadingBase,
    data: record,
  } = response;

  const id = record[`${relation}_id`];

  const { isLoading: isLoadingRelation, data: relations } = useClient(resource).get(
    id,
    { enabled: !isLoadingBase },
  );

  const isLoading = isLoadingBase || isLoadingRelation;
  if (isLoading) return response;

  const updatedRecord = {
    ...record,
    [relation]: relations,
  };

  return {
    ...response,
    data: updatedRecord,
    isLoading,
    isLoadingBase,
  };
}

export default function withOne(response, relation, options) {
  const { data } = response;

  const result = Array.isArray(data)
    ? manyWithOne(response, relation, options)
    : oneWithOne(response, relation, options);

  return {
    ...result,
    withMany: (nextRelation, nextOptions) => withMany(result, nextRelation, nextOptions),
    withOne: (nextRelation, nextOptions) => withOne(result, nextRelation, nextOptions),
  };
}
