import React from 'react';
import PropTypes from 'prop-types';
import { BoltIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import UseAnimations from 'react-useanimations';
import activity from 'react-useanimations/lib/activity';
import EventShape from '../../../shapes/EventShape';

export default function StatusIcon({ event, actionsAttempted, actionsResolved }) {
  if (event.status === 'pending') {
    return <UseAnimations animation={activity} strokeColor="#5F9EA0" />;
  }
  if (actionsAttempted > actionsResolved) {
    return <ExclamationTriangleIcon className="h-4 w-4 text-red-500 ml-5 mt-1" />;
  }

  if (actionsAttempted === actionsResolved) {
    return <BoltIcon className="h-4 w-4 text-indigo-700 ml-5 mt-1" />;
  }
  return null;
}

StatusIcon.propTypes = {
  event: EventShape.isRequired,
  actionsAttempted: PropTypes.number.isRequired,
  actionsResolved: PropTypes.number.isRequired,
};
