import React, { useEffect } from 'react';
import Input from '../../../../../components/forms/Input';
import FormShape from '../../../../../shapes/FormShape';

export default function ApiKeySubForm({ form }) {
  useEffect(() => {
    if (!form.watch('api.authentication.key') || !form.watch('api.authentication.value')) {
      form.setValue(
        'api.authentication',
        {
          record_type: 'api_key',
          key: '',
          value: '',
        },
      );
    }
  }, []);

  return (
    <div className="mt-2">
      <div className="mb-4">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Header Key"
          name="key"
          placeholder="Header Key..."
        />
      </div>
      <div className="mb-4">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Header Value"
          name="value"
          placeholder="Header Value..."
        />
      </div>
    </div>
  );
}

ApiKeySubForm.propTypes = {
  form: FormShape.isRequired,
};
