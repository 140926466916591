import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import Footer from '../../../../components/forms/Footer';
import useClient from '../../../../hooks/useClient';
import JSONSuperInput from '../../../../components/forms/JSONSuperInput';
import NameAndWorkflowSubForm from '../../../Action/Form/NameAndWorkflowSubForm';
import TriggerSubForm from '../../../Action/Form/TriggerSubForm';
import WebhookActionSubForm from '../../../Action/Form/WebhookActionSubForm';
import ActionShape from '../../../../shapes/ActionShape';
import FormTypeButtons from './FormTypeButtons';

function ActionFormWrapper({ currentActionTemplate, setCurrentActionTemplate, cloneId }) {
  if (!currentActionTemplate) return null;
  return (
    <ActionForm
      currentActionTemplate={currentActionTemplate}
      setCurrentActionTemplate={setCurrentActionTemplate}
      cloneId={cloneId}
    />
  );
}

ActionFormWrapper.propTypes = {
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
  cloneId: PropTypes.string.isRequired,
};

ActionFormWrapper.defaultProps = {
  currentActionTemplate: null,
};

function ActionForm({ currentActionTemplate, setCurrentActionTemplate, cloneId }) {
  const [mode, setMode] = useState('form');
  const queryClient = useQueryClient();
  const formDefault = _.omit(
    currentActionTemplate,
    [
      'id',
      'description',
      'record_type',
      'template',
      'inserted_at',
      'updated_at',
      'body',
      'alias',
      'alias_form',
    ],
  );

  const formDefaults = {
    ...formDefault,
    workflow_id: cloneId,
    parent_id: currentActionTemplate.id,
    destination_connection_id: null,
    source_connection_id: null,
  };

  const form = useForm({ defaultValues: formDefaults });
  const client = useClient('actions');
  const { mutate } = client.create({
    onSuccess: () => {
      setCurrentActionTemplate(null);
      queryClient.invalidateQueries('actions');
    },
    onError: (error) => console.log('error', error),
  });

  const onSubmit = form.handleSubmit((action) => mutate({ action }));

  return (
    <form onSubmit={onSubmit}>
      <FormTypeButtons onChange={setMode} currentValue={mode} />
      {
        mode === 'json'
          ? (<JSONSuperInput form={form} label="Action Object" />)
          : (
            <>
              <NameAndWorkflowSubForm form={form} hideWorkflowInput />
              <TriggerSubForm form={form} />
              <WebhookActionSubForm
                form={form}
                // exampleEvent={}
              />
            </>
          )
      }
      <Footer
        backButtonText="Cancel"
        onBackButtonClick={() => setCurrentActionTemplate(null)}
        submitButtonText="Clone Action"
        submitButtonDisabled={false}
        submitButtonType="submit"
      />
    </form>
  );
}

ActionForm.propTypes = {
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
  cloneId: PropTypes.string.isRequired,
};

ActionForm.defaultProps = {
  currentActionTemplate: null,
};

export default ActionFormWrapper;
