import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connectionName, connectionAvatar } from '../../../helpers/ConnectionHelpers';
import { findConnection } from '../../../helpers/EventHelpers';
import EventShape from '../../../shapes/EventShape';
import ThreadStatus from './StatusIcon';
import useMe from '../../../context/CurrentUserContext';
import MidDot from '../../../components/MidDot';
import LinkButton from '../../../components/buttons/LinkButton';
import ActionTree from '../ActionTree';
import useClient from '../../../hooks/useClient';
import LoadingSpinner from '../../../components/LoadingSpinner';

function EventIndexPageRow({ event, expandedRows, setExpandedRows }) {
  const expanded = expandedRows.includes(event.id);
  const setExpanded = (id) => setExpandedRows(_.xor(expandedRows, [id]));
  const { connections } = useMe();
  const connection = findConnection(event, connections);
  const actionProcessedLogs = event.logs.filter(({ topic }) => topic === 'action.processed');
  const actionIds = _.uniq(actionProcessedLogs.map((cl) => cl.records.action_id));

  const { data: events, isLoading } = useClient('events').list({
    thread: event.thread,
    page_size: 50,
  });

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);

  const eventsIds = events?.map((e) => e.id) || [];
  const { data, meta } = useClient('logs').list(
    { records: { event_id: [...[event.id], ...eventsIds] }, page_size: 100 },
    { enabled: !!events?.length },
  );
  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    } else {
      setIsLoadingLogs(false);
    }
  }, [meta?.page]);

  return (
    <Fragment key={event.id}>
      <tr key={event.status} className="px-3 mx-3">
        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
          <span className="flex space-x-2">
            <div className="mt-0.5">{connectionAvatar(connection, { size: 'xxs', containerSize: 'xxs', bgColor: 'bg-gray-100' })}</div>
            <div>{connectionName(connection)}</div>
          </span>
          <dl className="font-normal lg:hidden flex text-xs">
            <dt className="mr-1">Topic: </dt>
            <dd className="truncate text-gray-700">{event.topic}</dd>
          </dl>
          <dl className="font-normal lg:hidden flex text-xs">
            <dt className="mr-1 sm:hidden">Status: </dt>
            <dd className="truncate text-gray-500 sm:hidden">
              <span className="flex space-x-1">
                { isLoading ? <LoadingSpinner forIndex /> : null }
                {
                    events?.length
                      ? (<ThreadStatus event={event} events={events} threadLogs={logs} />)
                      : null
                  }
                {event.status}
              </span>
            </dd>
          </dl>
        </td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{event.topic}</td>
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          <span className="flex">
            { isLoading ? <LoadingSpinner forIndex /> : null }
            {
            events?.length
              ? (<ThreadStatus event={event} events={events} threadLogs={logs} />)
              : null
          }
            {event.status}
          </span>
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          <TimeAgo date={`${event.inserted_at} GMT`} />
        </td>
        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium">
          {
            actionIds.length
              ? (
                <>
                  <LinkButton buttonText="Expand" onClick={() => setExpanded(event.id)} className="text-indigo-600 hover:text-indigo-900" />
                  <MidDot />
                </>
              )
              : null
          }
          <Link to={event.id} className="text-indigo-600 hover:text-indigo-900">
            Details
          </Link>
        </td>
      </tr>
      {
        expanded
          ? (
            <tr className="w-full">
              <td colSpan={5}>
                <ActionTree
                  event={event}
                  threadEvents={events}
                  threadLogs={logs}
                  isLoadingLogs={isLoadingLogs}
                />
              </td>
            </tr>
          )
          : null
      }
    </Fragment>
  );
}

EventIndexPageRow.propTypes = {
  event: EventShape.isRequired,
  expandedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setExpandedRows: PropTypes.func.isRequired,
};

export default function EventIndexPageBody({ items, expandedRows, setExpandedRows }) {
  return (
    <tbody className="divide-y divide-gray-300 bg-white">
      {items.map((event) => (
        <EventIndexPageRow
          key={event.id}
          event={event}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
        />
      ))}
    </tbody>
  );
}

EventIndexPageBody.propTypes = {
  items: PropTypes.arrayOf(EventShape).isRequired,
  expandedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setExpandedRows: PropTypes.func.isRequired,
};
