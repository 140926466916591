/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import LogShape from '../../../../shapes/LogShape';
import LinkButton from '../../../../components/buttons/LinkButton';

export default function RequestResponse({ log }) {
  const [expanded, setExpanded] = useState(false);
  const bodyString = log.body.request;
  let bodyObject;
  try {
    bodyObject = JSON.parse(bodyString);
  } catch {
    // do nothing
  }

  return (
    <div className="sm:col-span-2">
      <dt className="text-sm font-medium text-gray-500">Request Body</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <div className="min-h-max p-1 border rounded-md bg-white">
          <ReactJson src={bodyObject} name={false} collapsed={1} />
        </div>
        <div className="mt-1 flex justify-end">
          <LinkButton buttonText={!expanded ? 'Expand' : 'Reduce'} onClick={() => setExpanded(!expanded)} />
        </div>
      </dd>
    </div>
  );
}

RequestResponse.propTypes = {
  log: LogShape.isRequired,
};
