import PropTypes from 'prop-types';
import React from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { startsWithVowel } from '../../helpers/ElementHelpers';

export default function EmptyState({ icon, name }) {
  const navigate = useNavigate();
  const usesAn = startsWithVowel(name);

  const IconToRender = icon || ExclamationCircleIcon;
  return (
    <div className="max-w-md mx-auto sm:max-w-3xl">
      <div>
        <div className="text-center">
          <IconToRender className="mx-auto h-12 w-12 text-gray-400" />
          <h2 className="mt-2 text-lg font-medium text-gray-900">
            {`Add ${usesAn ? 'an' : 'a'} ${name}`}
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            {`You haven’t added ${usesAn ? 'an' : 'a'} ${name} to your account yet.`}
          </p>
          <div className="mt-6">
            <button
              type="button"
              onClick={() => navigate('new')}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {`Add ${name}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.elementType,
  name: PropTypes.string.isRequired,
};

EmptyState.defaultProps = {
  icon: null,
};
