import React from 'react';
import PropTypes from 'prop-types';

export default function BodyWrapper({ children }) {
  return (
    <div className="mt-8 max-w-3xl lg:max-w-7xl">
      <div className="flex flex-col space-y-10">
        {children}
      </div>
    </div>
  );
}

BodyWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
