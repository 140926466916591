/* eslint-disable max-len */
import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import FormShape from '../../shapes/FormShape';
import { displayInputError } from '../../helpers/FormHelpers';

export default function Autocomplete({
  form, label, name, required, values, placeholder,
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const filteredValues = value === ''
    ? values
    : values.filter((candidateValue) => candidateValue.toLowerCase().includes(value?.toLowerCase()));

  return (
    <Combobox as="div" className="col-span-6 sm:col-span-3" value={value} onChange={onChange}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <Combobox.Input
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          onChange={onChange}
          autoComplete="off"
          placeholder={placeholder}
        />
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredValues.map((candidateValue) => (
            <Combobox.Option
              className={({ active }) => classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')}
              key={candidateValue}
              value={candidateValue}
            >
              {({ active, selected }) => (
                <>
                  <div className="flex">
                    <span className={classNames('truncate', selected && 'font-semibold')}>{candidateValue}</span>
                  </div>

                  {selected && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-indigo-600',
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
      <p className="mt-2 text-sm text-red-500">{displayInputError(errors, name)}</p>

    </Combobox>
  );
}

Autocomplete.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Autocomplete.defaultProps = {
  placeholder: null,
  required: false,
};
