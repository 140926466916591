import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import View from './View';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import useMe from '../../../context/CurrentUserContext';

export default function EventShowPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { connections } = useMe();
  const eventsClient = useClient('events');

  const { data: event, isLoading: isLoadingEvent } = eventsClient.get(id);
  const connection = connections?.find((c) => c.id === event?.connection_id);

  const { isLoading: isDestroying, mutate: destroy } = eventsClient.destroy(
    id,
    { onSuccess: () => navigate('/events') },
  );

  const { isLoading: isRetrying, mutate: retry } = eventsClient.post(
    id,
    'retry',
    { onSuccess: () => null },
  );

  const isLoading = [
    isLoadingEvent,
    isDestroying,
    isRetrying,
  ].includes(true);

  if (isLoading) return <Loading />;

  return (
    <View
      connection={connection}
      event={event}
      onDestroy={destroy}
      onRetry={retry}
    />
  );
}
