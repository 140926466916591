/* eslint-disable no-nested-ternary */
import React from 'react';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import SuccessPanel from '../../OAuthRedirect/SuccessPanel';

export default function OAuthSuccessPreviewCard({ connection }) {
  // if (!connection.template) return null;
  if (connection.api?.authentication?.record_type !== 'oauth2') return null;
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Connection OAuth Preview Screen
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">A preview of the screen in PartyBus where users will redirect after a successful OAuth2.0 install.</p>
          </div>
          <div className="m-4 rounded border-gray-300">
            <SuccessPanel connection={connection} preview />
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

OAuthSuccessPreviewCard.propTypes = {
  connection: ConnectionShape.isRequired,
};
