/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ConnectionTemplateShape from '../../../shapes/ConnectionTemplateShape';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';

export default function ConnectionTemplateSubForm({
  templates, connectionTemplate, setConnectionTemplate, skipUsingTemplate, setSkipUsingTemplate,
}) {
  return (
    <>
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Connection Type</h3>
            <p className="mt-1 text-sm text-gray-500">
              Let's start by specifying the App or Database that you would like to connect to.
            </p>
          </div>
          <div className="mt-4 flex flex-wrap">
            {templates?.map((t) => (
              <button
                key={t.id}
                disabled={skipUsingTemplate}
                type="button"
                onClick={() => setConnectionTemplate(t)}
                className={classNames(
                  skipUsingTemplate ? 'text-gray-500 bg-gray-50' : 'text-gray-700 bg-white hover:bg-gray-50',
                  'mt-1 flex min-w-content rounded-md mr-2 py-2 px-2.5 text-sm font-medium border',
                  connectionTemplate?.id === t.id ? 'border-2 border-indigo-600 bg-indigo-100 hover:bg-indigo-100' : null,
                )}
              >
                <span className="my-auto mr-1">
                  {connectionAvatar(t, { size: 'xs', container: false, displayFallback: false })}
                </span>
                {t.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-2 mt-6 mb-3">
        {
          connectionTemplate
            ? (
              <span className="text-sm text-gray-500">
                Let's create a connection with the
                {' '}
                {connectionTemplate?.name}
                {' '}
                Template!
                <button
                  type="button"
                  className="ml-1 text-indigo-700"
                  onClick={() => setConnectionTemplate(null)}
                >
                  Reset Form
                </button>
              </span>
            )
            : null
        }
        {
          !connectionTemplate && !skipUsingTemplate
            ? (
              <span className="text-sm text-gray-500">
                Don't see what you're looking for?
                <button
                  type="button"
                  className="ml-1 text-indigo-700"
                  onClick={() => setSkipUsingTemplate(true)}
                >
                  Create a Connection without a Template
                </button>
              </span>
            ) : null
        }
        {
          !connectionTemplate && skipUsingTemplate
            ? (
              <span className="text-sm text-gray-500">
                Let's create a connection from scratch!
                <button
                  type="button"
                  className="ml-1 text-indigo-700"
                  onClick={() => setSkipUsingTemplate(false)}
                >
                  Reset Form
                </button>
              </span>
            ) : null
        }
      </div>
    </>
  );
}

ConnectionTemplateSubForm.propTypes = {
  templates: PropTypes.arrayOf(ConnectionTemplateShape),
  connectionTemplate: ConnectionTemplateShape,
  setConnectionTemplate: PropTypes.func.isRequired,
  skipUsingTemplate: PropTypes.bool,
  setSkipUsingTemplate: PropTypes.func.isRequired,
};

ConnectionTemplateSubForm.defaultProps = {
  connectionTemplate: null,
  skipUsingTemplate: false,
  templates: [],
};
