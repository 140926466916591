/* eslint-disable jsx-a11y/label-has-associated-control */
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import React from 'react';
import PropTypes from 'prop-types';
import ConnectionShape from '../../../shapes/ConnectionShape';

export default function ConnectionCard({ destinationConnection, onClose }) {
  const formattedCxn = JSON.stringify(destinationConnection, null, '  ');

  return (
    <div>
      <div className="border-b">
        <h3 className="mt-2 ml-6 text-lg font-medium leading-6 text-gray-900">Connection</h3>
        <p className="ml-6 my-1 text-sm text-gray-500">
          A read-only view of your
          {' '}
          <span className="font-medium text-indigo-600">
            {destinationConnection.name}
          </span>
          {' '}
          connection.
        </p>
        <p className="ml-6 mb-4 text-sm text-gray-500">
          Access this info in your action bodies with
          {' '}
          <span className="font-medium text-yellow-600">
            {'{{destinationConnection.key_name}}'}
          </span>
          .
        </p>
      </div>
      <div className="my-1 flex-1 max-h-96 overflow-scroll p-2">
        <div className="min-h-max">
          <CodeMirror
            editable={false}
            extensions={[
              json(),
            ]}
            theme={githubLight}
            value={formattedCxn}
          />
        </div>
      </div>
      <div className="flex justify-end border-t">
        <button
          type="button"
          onClick={onClose}
          className="mx-3 mt-4 bg-white transition duration-150 ease-in-out focus:outline-none rounded text-indigo-700 border border-indigo-700 px-6 py-2 text-sm hover:bg-indigo-50"
        >
          Close
        </button>
      </div>
    </div>
  );
}

ConnectionCard.propTypes = {
  destinationConnection: ConnectionShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
