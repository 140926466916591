import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '../Modal';

export default function UrlVariable({
  variable: { value, keyPath, hasRecordToExtractFrom },
  config,
  index,
}) {
  const [isOpen, setIsOpen] = useState(false);

  let variableClassName;
  if (value === undefined || typeof value === 'object') variableClassName = 'text-red-700 bg-red-100';
  if (value !== undefined) variableClassName = 'text-yellow-600';
  if (!hasRecordToExtractFrom) variableClassName = 'text-gray-700 bg-gray-100';

  return (
    <span
      key={`${keyPath}para`}
      className={classNames(
        variableClassName,
        index === 0 ? config?.startClass : null,
      )}
    >
      <button type="button" onClick={() => setIsOpen(true)}>
        {(value && typeof value !== 'object') ? value : `{{${keyPath}}}`}
      </button>
      <Modal open={isOpen} onClose={setIsOpen}>
        <div className="p-3">
          <span className="flex border-b border-gray-300 p-2">
            <p className="w-48 text-sm font-medium text-gray-900">
              Scope
            </p>
            <p className="text-sm text-gray-500">
              {keyPath}
            </p>
          </span>
          <span className="flex border-b border-gray-300 p-2">
            <p className="w-48 text-sm font-medium text-gray-900">
              Extracted Value
            </p>
            <p className="text-sm text-gray-500">
              {
                hasRecordToExtractFrom
                  ? (
                    <>
                      {(value && typeof value !== 'object') ? value : null}
                      {keyPath.startsWith('event') ? '(from last event)' : null }
                    </>
                  )
                  : 'No Record To Extract From'
              }
            </p>
          </span>
        </div>
      </Modal>
    </span>
  );
}

UrlVariable.propTypes = {
  variable: PropTypes.shape({
    value: PropTypes.string,
    keyPath: PropTypes.string.isRequired,
    hasRecordToExtractFrom: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  config: PropTypes.shape(),
};

UrlVariable.defaultProps = {
  config: null,
};
