/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BiFilterAlt } from 'react-icons/bi';
import ActionShape from '../../../../../shapes/ActionShape';
import ActionNotAttempted from './NotAttempted';
import Succeeded from './Succeeded';
import LogShape from '../../../../../shapes/LogShape';
import YetToSucceed from './YetToSucceed';

export default function ActionsList({
  callbackActions, workflowActions, threadLogs, parentAction,
}) {
  return (
    <ul className="pb-1">
      {
        callbackActions.map((action, activityItemIdx) => {
          // eslint-disable-next-line max-len
          const currentCallbackOptions = workflowActions.filter((a) => a.topic === action.after_action?.topic);
          const actionLogs = threadLogs?.filter((l) => action.id === l.records.action_id && l.topic === 'action.processed');
          const mostRecentLog = actionLogs[0];
          const containsMatcher = action.matcher !== 'true';
          return (
            <li key={action.id} className="relative pt-3">
              <div className="flex gap-x-4">
                <div
                  className={classNames(
                    activityItemIdx === callbackActions.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-12 justify-center border-l ml-6 border-gray-300',
                  )}
                />
                <div className="flex">
                  <div
                    className={classNames(
                      containsMatcher ? 'w-4' : 'w-6',
                      'h-3.5 border-b border-l border-gray-300 ml-6',
                    )}
                  />
                  {
                    containsMatcher
                      ? (
                        <>
                          <div className="w-4 h-4 border mt-1.5 border-gray-300 rounded flex items-center justify-center">
                            <BiFilterAlt className={classNames(actionLogs?.length ? 'text-indigo-600' : 'text-gray-300', 'h-3 w-3')} />
                          </div>
                          <div className="w-4 h-3.5 border-b border-gray-300" />
                        </>
                      )
                      : null
                  }
                  {
                    actionLogs?.length
                      ? (
                        mostRecentLog.status === 'success'
                          ? <Succeeded action={action} actionLogs={actionLogs} parentAction={parentAction} />
                          : <YetToSucceed action={action} actionLogs={actionLogs} />
                      )
                      : (<ActionNotAttempted action={action} parentAction={parentAction} />)
                  }
                </div>
              </div>
              {
              currentCallbackOptions.length
                ? (
                  <div className={classNames(containsMatcher ? 'ml-16 mt-0.5' : 'ml-12')}>
                    <ActionsList
                      parentAction={action}
                      callbackActions={currentCallbackOptions}
                      workflowActions={workflowActions}
                      threadLogs={threadLogs}
                    />
                  </div>
                )
                : null
              }
            </li>
          );
        })
}
    </ul>
  );
}

ActionsList.propTypes = {
  parentAction: ActionShape,
  callbackActions: PropTypes.arrayOf(ActionShape).isRequired,
  workflowActions: PropTypes.arrayOf(ActionShape).isRequired,
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};

ActionsList.defaultProps = {
  parentAction: null,
};
