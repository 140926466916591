import PropTypes from 'prop-types';
import React from 'react';
import Input from '../../../components/forms/Input';
import ModalFormLayout from '../../../components/forms/ModalFormLayout';
import FormShape from '../../../shapes/FormShape';

export default function ChangePasswordForm({
  form, isSubmitting, onUpdate, onClose,
}) {
  return (
    <ModalFormLayout
      title="Change Password"
      description="Enter a new password and confirmation."
      onSubmit={form.handleSubmit(onUpdate)}
      navigate={onClose}
      isSubmitting={isSubmitting}
    >
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="my-4 px-4 grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-6">
          <div className="md:col-span-3">
            <Input
              inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              form={form}
              label="Password"
              name="password"
              required
              type="password"
            />
          </div>
          <div className="md:col-span-3">
            <Input
              inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              form={form}
              label="Confirm Password"
              name="password_confirmation"
              required
              type="password"
            />
          </div>
        </div>
      </div>
    </ModalFormLayout>
  );
}

ChangePasswordForm.propTypes = {
  form: FormShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
