import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import FeedLine from './FeedLine';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import { byInsertedAt, byTopic, byRecord } from '../../../../../helpers/LogHelpers';
import EventShape from '../../../../../shapes/EventShape';
import Loading from '../../../../../components/Loading';
import useClient from '../../../../../hooks/useClient';
import useMe from '../../../../../context/CurrentUserContext';

export default function Feed({ event, connection }) {
  const { workflows } = useMe();
  const { data: logs, isLoading: isLoadingLogs } = useClient('logs').list(
    { records: { event_id: event.id }, page_size: 100 },
    { enabled: !!event.id },
  );

  // /api/v1/jobs?records[event_id]=a3e6e029-f6c6-48c0-a18c-5bdf4085728b
  const { data: jobs, isLoading: isLoadingJobs } = useClient('jobs').list(
    { records: { event_id: event.id }, page_size: 100 },
    { enabled: !!event.id },
  );

  if (isLoadingLogs || isLoadingJobs) return <Loading />;

  const displayLogs = [];
  // Find our create log and processed logs
  const createLog = logs.find(byTopic('event.created'));
  displayLogs.push(createLog);
  const eventProcessedLogs = logs.filter(byTopic('event.processed')).sort(byInsertedAt);

  eventProcessedLogs.forEach((eventProcessedLog) => {
    // All uniq action.evaluated logs from this evaluation
    const evaluationLogs = logs.filter(byRecord(eventProcessedLog, 'evaluation'));
    const actionEvalLogs = evaluationLogs.filter(byTopic('action.evaluated'));
    const uniqActionEvalLogs = _.uniqBy(actionEvalLogs, (l) => l.records.action_id);

    // Get a list of all uniq workflows Ids
    const triggeredWorkflows = uniqActionEvalLogs.filter((aLog) => aLog.body.result);
    const workflowIds = triggeredWorkflows.map((a) => a.records.workflow_id);

    // Iterate that list and build a workflow log
    const workflowActionGroups = _.uniq(workflowIds).map((id) => {
      const times = uniqActionEvalLogs.map((l) => l.inserted_at).reduce((a, b) => (a < b ? a : b));
      const workflowActionLogs = uniqActionEvalLogs.filter((a) => a.records.workflow_id === id);
      const uniqWorkflowActionEvalLogs = _.uniqBy(workflowActionLogs, (l) => l.records.action_id);
      const workflowMatchingActions = [];
      const workflowNonMatchingActionLogs = [];
      uniqWorkflowActionEvalLogs.forEach((actionEvaluationLog) => {
        // Find the action.processed log if one exists for this action.evaluated log
        if (actionEvaluationLog.body.result) {
          const requestLogs = evaluationLogs
            .filter(byTopic('action.processed'))
            .filter(byRecord(actionEvaluationLog, 'action_id'));
          workflowMatchingActions.push({ ...actionEvaluationLog, requestLogs });
        } else {
          workflowNonMatchingActionLogs.push(actionEvaluationLog);
        }
      });
      const workflow = workflows.find((w) => w.id === id);

      return ({
        topic: 'workflow.evaluated',
        id: workflow.id,
        evaluation: eventProcessedLog.records.evaluation,
        workflow,
        inserted_at: times,
        matchedActions: workflowMatchingActions,
        nonMatchedActions: workflowNonMatchingActionLogs,
        body: {},
        status: 'success',
      });
    });
    const matchingActionLogs = [];
    const nonMatchingActionLogs = [];
    uniqActionEvalLogs.forEach((actionEvaluationLog) => {
      // Find the action.processed log if one exists for this action.evaluated log
      if (actionEvaluationLog.body.result) {
        const requestLogs = evaluationLogs
          .filter(byTopic('action.processed'))
          .filter(byRecord(actionEvaluationLog, 'action_id'));
        matchingActionLogs.push({ ...actionEvaluationLog, requestLogs });
      } else {
        nonMatchingActionLogs.push(actionEvaluationLog);
      }
    });

    displayLogs.push({ ...eventProcessedLog, matchingActionLogs, nonMatchingActionLogs });
    workflowActionGroups.map((l) => displayLogs.push(l));
  });

  const updateLogs = logs.filter(byTopic('event.updated'));
  updateLogs.map((l) => displayLogs.push({ ...l, createLog, updateLogs }));
  const sortedDisplayLogs = displayLogs.sort(byInsertedAt);

  return (
    <ul className="space-y-2">
      {sortedDisplayLogs.map((log, idx) => (
        <li key={log.id} className="relative flex gap-x-4">
          <div
            className={classNames(
              idx === displayLogs.length - 1 ? 'h-6' : '-bottom-6',
              'absolute left-0 top-0 flex w-6 justify-center',
            )}
          >
            <div className="w-px bg-gray-200" />
          </div>
          <FeedLine log={log} connection={connection} />
        </li>
      ))}
    </ul>
  );
}

Feed.propTypes = {
  event: EventShape.isRequired,
  connection: ConnectionShape,
};

Feed.defaultProps = {
  connection: null,
};
