/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

export default function StatusTabs({ indexClient, config }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValues = {};
  for (const key of searchParams.keys()) {
    defaultValues[key] = searchParams.get(key);
  }
  const [value, setValue] = useState(config.defaultValue);
  const { handleSubmit, watch } = useForm({ defaultValues });
  const onChangeFilter = () => {
    // eslint-disable-next-line max-len, no-return-assign
    const scrubbedFilter = Object.entries(defaultValues).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    if (indexClient.initialLatentSearchParams[config.filterKey] === value) {
      const newParams = _.omit({ ...scrubbedFilter }, [config.filterKey]);

      setSearchParams(newParams);
    } else {
      setSearchParams({ ...scrubbedFilter, [config.filterKey]: value });
    }
  };

  useEffect(() => {
    onChangeFilter(watch());
    handleSubmit();
  }, [value]);

  return (
    <form className="w-full my-3">
      {/* onSubmit={handleSubmit(onChangeFilter)}> */}
      <div className="border-b border-gray-200 pb-5 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <label htmlFor="Status Filter" className="block text-sm font-medium text-gray-700">
              Filter by Status
            </label>
            <select
              id="current-tab"
              name="current-tab"
              onChange={(e) => setValue(e.target.value)}
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={value}
            >
              {config.tabs.map((tab) => (
                <option key={tab.value} value={tab.value}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {config.tabs.map((tab) => (
                <button
                  type="button"
                  key={tab.name}
                  onClick={() => setValue(tab.value)}
                  className={classNames(
                    tab.value === value
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                  )}
                  aria-current={tab.value === value ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </form>
  );
}

StatusTabs.propTypes = {
  indexClient: PropTypes.shape().isRequired,
  config: PropTypes.shape().isRequired,
};
