import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import ConnectionShape from '../../../shapes/ConnectionShape';
import { connectionType, connectionAvatar } from '../../../helpers/ConnectionHelpers';
import { formatDateTime } from '../../../helpers/DateHelpers';

function MobileTableRow({ item }) {
  return (
    <li key={item.id}>
      <Link to={item.id} className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
        <span className="flex items-center truncate space-x-3">
          <span className="w-2.5 h-2.5 flex-shrink-0 rounded-full" aria-hidden="true" />
          <span className="font-medium truncate text-sm leading-6">
            <div className="flex items-center">
              {connectionAvatar(item)}
              <div className="ml-4">
                <div className="font-medium text-gray-900 flex">
                  {connectionType(item)}
                  <div className="text-gray-500 ml-1">
                    {item.name}
                  </div>
                </div>
                <div className="text-gray-500 text-xs">
                  {formatDateTime(item.inserted_at)}
                </div>
              </div>
            </div>
          </span>
        </span>
        <ChevronRightIcon
          className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </Link>
    </li>
  );
}

export default function MobileBody({ items, mobileTitle }) {
  return (
    <div className="mt-10 sm:hidden border bg-gray-50 rounded-sm">
      <div className="px-4 sm:px-6 pt-3">
        <h2 className="text-left text-sm font-semibold text-gray-900 tracking-wide">{mobileTitle}</h2>
      </div>
      <ul className="mt-3 divide-y divide-gray-100 border-t border-gray-200 bg-white">
        {items.map((item) => <MobileTableRow key={`mobile${item.id}`} item={item} />)}
      </ul>
    </div>
  );
}

MobileTableRow.propTypes = {
  item: ConnectionShape.isRequired,
};

MobileBody.propTypes = {
  items: PropTypes.arrayOf(ConnectionShape).isRequired,
  mobileTitle: PropTypes.string.isRequired,
};
