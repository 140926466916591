import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../ErrorBoundary';

export default function ShowPageSectionCard({ title, subTitle, children }) {
  return (
    <ErrorBoundary>
      <div className="space-y-6 lg:col-span-2 lg:col-start-1">
        <section aria-labelledby="connection-information-title">
          <div className="bg-white border border-gray-300 rounded">
            {
              title
                ? (
                  <div className="px-4 py-2 sm:px-6 border-b border-gray-300">
                    <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                      { title }
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{subTitle}</p>
                  </div>
                )
                : null
            }
            <div className="container p-6 mx-auto">
              {children}
            </div>
          </div>
        </section>
      </div>
    </ErrorBoundary>
  );
}

ShowPageSectionCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ShowPageSectionCard.defaultProps = {
  title: null,
  subTitle: null,
};
