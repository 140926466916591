import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames/bind';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

export default function FilterSelect({
  control, label, name, options, required, valueKey, sortByKey,
}) {
  const {
    field: {
      onChange, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const selectedOption = options.find((o) => o[valueKey || 'value'] === value);
  // eslint-disable-next-line
  const sortedOptions = sortByKey ? options.sort((a, b) => (a[sortByKey] > b[sortByKey]) ? 1 : -1) : options;

  return (
    <Listbox value={selectedOption} onChange={(v) => onChange(v[valueKey || 'value'])}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
          <div className="mt-1 relative space-y-0">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{selectedOption?.name || 'Select an Option...'}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {sortedOptions.map((option) => (
                  <Listbox.Option
                    key={option[valueKey || 'value']}
                    className={({ active }) => classNames(
                      active ? 'text-white bg-indigo-600' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-8 pr-4',
                    )}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

FilterSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  required: PropTypes.bool,
  valueKey: PropTypes.string,
  sortByKey: PropTypes.string,
};

FilterSelect.defaultProps = {
  required: false,
  options: [],
  valueKey: null,
  sortByKey: null,
};
