import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import ActionsList from './ActionsLayout';
import ActionForm from './ActionForm';
import ActionShape from '../../../shapes/ActionShape';

export default function SliderForm({
  templateWorkflowActions,
  clonedWorkflowActions,
  setCurrentActionTemplate,
  currentActionTemplate,
  cloneId,
}) {
  const wrapper = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(1);

  useEffect(() => {
    function handleResize() {
      if (wrapper.current !== null) {
        setWrapperWidth(wrapper.current.offsetWidth);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex-1 flex flex-col items-center">
      <div ref={wrapper} className="flex items-start overflow-hidden w-96 sm:w-full">
        <div className="flex flex-nowrap w-full">
          <Transition
            appear={false}
            unmount={false}
            show={!currentActionTemplate}
            enter="transform transition ease-in-out duration-500"
            enterFrom="-translate-x-96 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transform transition ease-in-out duration-500 "
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-0"
            className="w-0 bg-green-200 overflow-visible"
            as="div"
          >
            <div style={{ width: `${wrapperWidth}px` }}>
              <ActionsList
                templateWorkflowActions={templateWorkflowActions}
                clonedWorkflowActions={clonedWorkflowActions}
                setCurrentActionTemplate={setCurrentActionTemplate}
                currentActionTemplate={currentActionTemplate}
              />
            </div>
          </Transition>
          <Transition
            appear={false}
            unmount={false}
            show={!!currentActionTemplate}
            enter="transform transition ease-in-out duration-500"
            enterFrom="translate-x-96 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transform transition ease-in-out duration-500 "
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-96 opacity-0"
            className="overflow-visible w-full"
            as="div"
          >
            <ActionForm
              currentActionTemplate={currentActionTemplate}
              setCurrentActionTemplate={setCurrentActionTemplate}
              cloneId={cloneId}
            />
          </Transition>
        </div>
      </div>
    </div>
  );
}

SliderForm.propTypes = {
  templateWorkflowActions: PropTypes.arrayOf(ActionShape).isRequired,
  clonedWorkflowActions: PropTypes.arrayOf(ActionShape).isRequired,
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
  cloneId: PropTypes.string.isRequired,
};

SliderForm.defaultProps = {
  currentActionTemplate: null,
};
