import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import FormShape from '../../shapes/FormShape';

export default function TextArea({
  form, label, name, required, helperText, placeholder, rows,
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, onBlur, ref, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <textarea
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          rows={rows}
          placeholder={placeholder}
          value={value || ''}
        />
      </div>
      <span className="mt-2 text-sm text-gray-500">{helperText}</span>
      <p>{errors[name]?.message}</p>
    </>
  );
}

TextArea.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

TextArea.defaultProps = {
  required: false,
  helperText: '',
  placeholder: '',
  rows: 3,
};
