import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import useClient from '../../../hooks/useClient';
import Form from './Form';
import ConnectionShape from '../../../shapes/ConnectionShape';

export default function SuperEditPageWrapper() {
  const { id } = useParams();
  const { data, isLoading } = useClient('connections').get(id);
  if (isLoading) return <Loading />;
  return (<SuperEditPage connection={data} />);
}

function SuperEditPage({ connection }) {
  const navigate = useNavigate();
  const form = useForm({ defaultValues: connection });

  const { isLoading: isSubmitting, mutate } = useClient('connections').update(connection.id, {
    onSuccess: () => navigate(`/connections/${connection.id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((c) => mutate({ connection: c }));

  return (
    <Form
      form={form}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    />
  );
}

SuperEditPage.propTypes = {
  connection: ConnectionShape.isRequired,
};
