/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLongRightIcon, ArrowLongDownIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import cronstrue from 'cronstrue';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import ActionShape from '../../../../shapes/ActionShape';

export default function Preview({
  actionDestination, sourceConnection, action, onReset, isEditMode,
}) {
  return (
    <div className="my-2">
      <div className="sm:flex">
        {
          sourceConnection
            ? (
              <div className="relative flex items-center space-x-3 rounded border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
                <div className="flex-shrink-0">
                  {connectionAvatar(sourceConnection, { size: 'sm' })}
                </div>
                <div className="min-w-0 flex-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">{sourceConnection.name}</p>
                  <p className="truncate text-sm text-gray-500">{action.topic}</p>
                </div>
              </div>
            )
            : (
              <div className="relative flex items-center space-x-3 rounded border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
                <div className="flex-shrink-0">
                  <div className="h-8 w-8 rounded bg-white border border-gray-300">
                    <CalendarDaysIcon className="h-7 w-7 m-0.5 text-indigo-700" />
                  </div>
                </div>
                <div className="min-w-0 flex-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">{action.name}</p>
                  <p className="truncate text-sm text-gray-500">{action.schedule ? cronstrue.toString(action.schedule) : null}</p>
                </div>
              </div>
            )
        }
        <ArrowLongRightIcon className="hidden sm:block mr-3 ml-3 w-8 text-gray-500" />
        <div className="sm:hidden my-2">
          <ArrowLongDownIcon className="mx-auto w-8 text-gray-500" />
        </div>
        <div className="relative flex items-center space-x-3 rounded border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
          <div className="flex-shrink-0">
            {connectionAvatar(actionDestination, { size: 'sm' })}
          </div>
          <div className="min-w-0 flex-1">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">{actionDestination?.name}</p>
            <p className="truncate text-sm text-gray-500">Send an API Request</p>
          </div>
        </div>
      </div>
      {
        !isEditMode
          ? (
            <div className="mt-4">
              <span className="text-sm text-gray-500">
                Let's create an action with the
                {' '}
                {actionDestination?.name}
                {' '}
                Connection!
                <button
                  type="button"
                  className="ml-2 text-indigo-700"
                  onClick={onReset}
                >
                  Reset Form
                </button>
              </span>
            </div>
          )
          : null
      }
    </div>
  );
}

Preview.propTypes = {
  actionDestination: ConnectionShape.isRequired,
  sourceConnection: ConnectionShape,
  onReset: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  action: ActionShape.isRequired,
};

Preview.defaultProps = {
  isEditMode: false,
  sourceConnection: null,
};
