import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { EditorView } from 'codemirror';
import ErrorBoundary from './ErrorBoundary';

export default function JsonDisplay({ wrapperClass, valueObject }) {
  const ref = useRef(0);

  if (!valueObject) return <p className="text-sm text-gray-900">N/A</p>;
  return (
    <ErrorBoundary>
      <div className="overflow-scroll border border-gray-300 rounded">
        <div className={wrapperClass} ref={ref}>
          <CodeMirror
            editable={false}
            extensions={[
              json(),
              EditorView.lineWrapping,
            ]}
            theme={githubLight}
            value={JSON.stringify(valueObject, null, ' ')}
            minHeight="60px"
            basicSetup={{
              highlightActiveLine: false,
              bracketMatching: true,
            }}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

JsonDisplay.propTypes = {
  wrapperClass: PropTypes.string,
  valueObject: PropTypes.shape(),
};

JsonDisplay.defaultProps = {
  wrapperClass: null,
  valueObject: null,
};
