import { SignalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Header from '../../../components/layout/PageHeader';
import Footer from '../../../components/forms/Footer';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import useClient from '../../../hooks/useClient';
import FormBody from './FormBody';

export default function EventForm() {
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      topic: 'event.test',
      payload: { foo: 'bar' },
    },
  });

  const client = useClient('events');
  const { isLoading: isSubmitting, mutate } = client.create({
    onSuccess: ({ id }) => navigate(`/events/${id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit(
    (event) => mutate({ event }),
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="px-4 sm:px-6 lg:px-8">
        <Header
          name="New Event Form"
          description="Create a new event from the dashboard."
          icon={<SignalIcon className="h-8 w-8 m-1 text-indigo-700" />}
        />
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="bg-white border rounded-sm mt-10 p-6">
            <FormBody form={form} />
            <Footer
              backButtonText="Back to Events"
              onBackButtonClick={() => navigate(-1)}
              submitButtonText="Submit"
              submitButtonDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
