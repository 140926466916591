/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import ComboBox from '../../../components/forms/ComboBox';
import { scrub, toSearchParams } from '../../../helpers/ObjectHelpers';
import useClient from '../../../hooks/useClient';

const statuses = [
  { id: null, name: 'All' },
  { id: 'success', name: 'Success' },
  { id: 'error', name: 'Error' },
];

export default function FilterForm({
  onCloseModal,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const scrubbedParams = scrub(searchParams);
  const form = useForm({ defaultValues: scrubbedParams });

  const onChangeFilter = (filter) => {
    onCloseModal();
    setSearchParams(toSearchParams(filter));
  };

  const { data: actions, isLoading: isLoadingActions } = useClient('actions').list();
  const { data: connections, isLoading: isLoadingConnections } = useClient('connections').list();

  if (isLoadingActions || isLoadingConnections) return (<Loading />);

  return (
    <form className="" onSubmit={form.handleSubmit(onChangeFilter)}>
      <ComboBox
        label="Status"
        options={statuses}
        form={form}
        name="status"
        labelKey="name"
        valueKey="id"
      />
      <ComboBox
        label="Connection"
        options={connections}
        preventSort
        form={form}
        name="records.destination_connection_id"
        labelKey="name"
        valueKey="id"
        imageKey="icon_url"
      />
      <ComboBox
        label="Action"
        options={actions}
        preventSort
        form={form}
        name="records.action"
        labelKey="name"
        valueKey="id"
        imageKey="icon_url"
      />
      <div className="mt-6 flex justify-space-between">
        <button
          onClick={onCloseModal}
          type="button"
          className="mr-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={isSubmitting}
          className="ml-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Filter
        </button>
      </div>
    </form>
  );
}

FilterForm.propTypes = {
  // isSubmitting: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
