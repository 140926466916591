/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import useClient from '../../../../hooks/useClient';
import FormShape from '../../../../shapes/FormShape';
import FormBody from './FormBody';
import useMe from '../../../../context/CurrentUserContext';
import EventShape from '../../../../shapes/EventShape';

export default function WebhookActionSubForm({
  form,
  exampleEvent,
  children,
}) {
  const eventClient = useClient('events');
  const { actionTemplates, connections } = useMe();
  const actionTemplate = actionTemplates?.find(({ id }) => id === form.watch('action_template_id'));
  const destinationConnection = connections.find((c) => c.id === form.watch('destination_connection_id'));
  const sourceConnection = connections.find((c) => c.id === form.watch('source_connection_id'));

  // Fetch events for form body preview
  const { data: events, isLoading: isLoadingEvents } = eventClient.list({
    connection_id: form.watch('source_connection_id'),
    matcher: form.watch('matcher'),
    page_size: 1,
    topic: form.watch('topic'),
  }, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !exampleEvent && (!!form.watch('topic') && !!form.watch('source_connection_id')),
  });

  // const { data: connectionsFromSameTemplate } = cxnClient.list(
  //   { connection_template_id: sourceConnection?.connection_template_id },
  //   { enabled: !!sourceConnection?.connection_template_id && !(events?.length) && !isLoadingEvents },
  // );

  // const { data: templateEvents } = eventClient.list({
  //   id: connectionsFromSameTemplate?.map((c) => c.id),
  //   matcher: form.watch('matcher'),
  //   page_size: 1,
  //   topic: form.watch('topic'),
  // }, {
  //   staleTime: Infinity,
  //   cacheTime: Infinity,
  //   enabled: !!connectionsFromSameTemplate?.length,
  // });

  const [currentEventFromConnection] = events || [];

  // const [currentTemplateEvent] = templateEvents || [];
  const currentEvent = exampleEvent || currentEventFromConnection; // || currentTemplateEvent;

  const { data: previousEvent } = eventClient.get(
    currentEvent?.previous_event_id,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!currentEvent?.previous_event_id,
    },
  );

  const event = {
    ...currentEvent,
    previous_event: previousEvent,
  };

  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="bg-white border border-gray-300 rounded py-6 px-10">
        <FormBody
          actionTemplate={actionTemplate}
          event={event}
          sourceConnection={sourceConnection}
          form={form}
          destinationConnection={destinationConnection}
        />
        {children}
      </div>
    </div>
  );
}

WebhookActionSubForm.propTypes = {
  form: FormShape.isRequired,
  exampleEvent: EventShape,
  children: PropTypes.node,
};

WebhookActionSubForm.defaultProps = {
  exampleEvent: null,
  children: null,
};
