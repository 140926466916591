import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useClient from '../../../hooks/useClient';
import Form from '../Form';
import mapErrorsToForm from '../../../helpers/FormHelpers';

export default function CreatePage() {
  const form = useForm();
  const client = useClient('workflows');
  const navigate = useNavigate();

  const { isLoading: isSubmitting, mutate } = client.create({
    onSuccess: (workflow) => navigate(`/workflows/${workflow.id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });
  const onSubmit = form.handleSubmit((workflow) => mutate({ workflow }));

  return (
    <Form
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      form={form}
      formTitle="New Workflow Form"
      formSubTitle="Create a new workflow. This is just a convient way to group two or more related actions."
      submitButtonText="Create Workflow"
      displayLayoutInput={false}
    />
  );
}
