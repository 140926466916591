import React from 'react';
import YAML from 'json-to-pretty-yaml';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ConnectionShape from '../../../shapes/ConnectionShape';
import YamlDisplay from '../../../components/YamlDisplay';

export default function YamlCard({ connection }) {
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Yaml Config
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about this connection.</p>
          </div>
          <YamlDisplay valueStr={YAML.stringify(connection)} />
        </div>
      </ErrorBoundary>
    </section>
  );
}

YamlCard.propTypes = {
  connection: ConnectionShape.isRequired,
};
