export function logIsError(log) {
  return log?.status === 'error';
}

export function logIsSuccess(log) {
  return log?.status === 'success';
}

export function byInsertedAt(a, b) {
  return a.inserted_at.localeCompare(b.inserted_at);
}

export function byTopic(topic) {
  return (log) => log.topic === topic;
}

export function excludeByTopic(topic) {
  return (log) => log.topic !== topic;
}

export function byRecord(parentLog, type) {
  return (log) => log.records[type] === parentLog.records[type];
}

export function logStatusColor(log) {
  const statusCode = log.body?.http_status;
  if (statusCode >= 200 && statusCode < 299) return 'bg-teal-50 text-teal-500 border border-teal-500';
  if (statusCode >= 400 && statusCode < 499) return 'bg-yellow-50 text-yellow-500 border border-yellow-500';
  if (statusCode >= 500) return 'bg-red-50 text-red-500 border border-red-500';
  return 'bg-gray-50 text-gray-500 border border-gray-500';
}
