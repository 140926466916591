/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function ErrorPanel({ error }) {
  return (
    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Authentication Error
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              An error occurred while attempting to complete OAuth2 authentication.
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Status Code:
              {' '}
              {error.status}
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Details:
              {' '}
              {error.data.errors.detail || error.data.errors.error_description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorPanel.propTypes = {
  error: PropTypes.shape({
    data: PropTypes.shape(),
    status: PropTypes.number,
  }).isRequired,
};
