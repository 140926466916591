/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import ActionShape from '../../../../shapes/ActionShape';
import useClient from '../../../../hooks/useClient';
import Modal from '../../../../components/Modal';
import LogDetailModalBody from '../LogDetailModalBody';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import LogsTable from './LogsTable';

export default function Logs({ action }) {
  const [currentLog, setCurrentLog] = useState(null);

  const { data: logs, isLoading: isLoadingLogs } = useClient('logs').list({
    records: { action_id: action.id },
    topic: 'action.processed',
    page_size: 10,
  });

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Latest API Requests
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">A list of all API Requests made using this action.</p>
          </div>
          <Modal maxWidth="sm:max-w-5xl sm:w-full" open={!!currentLog} onClose={() => setCurrentLog(null)}>
            <LogDetailModalBody
              backText="Back to Detail Page"
              log={currentLog}
              onClose={() => setCurrentLog(null)}
            />
          </Modal>
          {
          isLoadingLogs
            ? (<p>Loading...</p>)
            : (
              logs.length
                ? (<LogsTable logs={logs} setCurrentLog={setCurrentLog} />)
                : (
                  <div className="m-4">
                    <span className="col-span-2 text-sm text-gray-500 w-full">
                      You do not have any API Requests for this Action.
                    </span>
                  </div>
                )
            )
        }
        </div>
      </ErrorBoundary>
    </section>
  );
}

Logs.propTypes = {
  action: ActionShape.isRequired,
};
