/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../index';
import LinkButton from '../../buttons/LinkButton';
import Tabs from '../Tabs';
import LastEventCard from './LastEventCard';
import EventShape from '../../../shapes/EventShape';
import ConnectionCard from './ConnectionCard';
import ConnectionShape from '../../../shapes/ConnectionShape';
import AliasCard from './AliasCard';

export default function AvailableDataModal({
  display, onOpen, onClose, lastEvent, destinationConnection, form,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['Last Event', 'Connection', 'Alias Data'];
  return (
    <>
      <Modal open={display} onClose={onClose}>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        { activeTab === 0 ? <LastEventCard event={lastEvent} onClose={onClose} /> : null }
        { activeTab === 1 ? <ConnectionCard destinationConnection={destinationConnection} onClose={onClose} /> : null }
        { activeTab === 2 ? <AliasCard form={form} onClose={onClose} destinationConnection={destinationConnection} /> : null }
      </Modal>
      <LinkButton
        onClick={onOpen}
        buttonText="View Available Data"
      />
    </>
  );
}

AvailableDataModal.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  lastEvent: EventShape,
  destinationConnection: ConnectionShape,
  form: PropTypes.shape().isRequired,
};

AvailableDataModal.defaultProps = {
  lastEvent: null,
  destinationConnection: null,
};
