import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import mapErrorsToForm from '../helpers/FormHelpers';

export default function Signup() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { persistAccessToken } = useAuth();
  const { publicAxios } = useContext(AxiosContext);

  const signup = async (user) => {
    const response = await publicAxios.post('users/register', { user });
    return response.data;
  };

  const defaultValues = { email: '', password: '', name: '' };
  const { register, handleSubmit, setError } = useForm({ defaultValues });

  const redirectCallback = () => {
    if (state?.path) navigate(state?.path);
  };

  const { mutate } = useMutation({
    mutationFn: signup,
    onError: (error) => mapErrorsToForm(error.response, setError),
    onSuccess: (r) => persistAccessToken(r, redirectCallback),
  });

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Register for an account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or
          {' '}
          <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
            log in to your account
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(mutate)}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('name')}
                  autoComplete="name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('email')}
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('password')}
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
