import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useState, useEffect,
} from 'react';

const AuthContext = createContext();
const { Provider } = AuthContext;

function useAuth() {
  const [accessToken, setAccessToken] = useState();
  const [initialLoadPending, setInitialLoadPending] = useState(true);

  useEffect(() => {
    const locallyStoredToken = localStorage.getItem('accessToken');
    if (locallyStoredToken) setAccessToken(locallyStoredToken);
    setInitialLoadPending(false);
  }, []);

  const persistAccessToken = ({ token }, callback = () => null) => {
    localStorage.setItem('accessToken', token);
    setAccessToken(token);
    callback();
  };

  const clearTokens = () => {
    setAccessToken(null);
    localStorage.removeItem('accessToken');
  };

  return {
    persistAccessToken,
    clearTokens,
    accessToken,
    setAccessToken,
    initialLoadPending,
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();
  if (auth.initialLoadPending) return null;
  return (
    <Provider value={auth}>
      {children}
    </Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function AuthConsumer() {
  return useContext(AuthContext);
}
