import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cron } from 'react-js-cron';
import { useController } from 'react-hook-form';
import FormShape from '../../shapes/FormShape';
import { displayInputError } from '../../helpers/FormHelpers';
import 'react-js-cron/dist/styles.css';
import Input from './InputBase';
import LinkButton from '../buttons/LinkButton';

export default function CronInput({
  label,
  name,
  form,
  required,
  disabled,
}) {
  const [showBaseInput, setShowBaseInput] = useState(false);
  const {
    control,
    formState: { errors },
  } = form;

  const { field: { onChange, value } } = useController({
    name,
    control,
    rules: { required },
  });

  useEffect(() => {
    if (!form.watch(name)) {
      form.setValue(name, '0 * * * *');
    }
  }, []);

  if (showBaseInput) {
    return (
      <Input
        form={form}
        disabled={disabled}
        label={label}
        name={name}
        required
        placeholder={`${label}...`}
        helperText={(
          <div className="flex justify-end mt-2">
            <LinkButton
              buttonText="Display Cron Input"
              onClick={() => setShowBaseInput(false)}
            />
          </div>
        )}
        inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />
    );
  }

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="pt-2 px-3 border border-gray-300 rounded-md">
        <Cron
          disabled={disabled}
          clearButtonProps={{ type: 'default' }}
          value={value}
          setValue={onChange}
        />
      </div>
      <p className="mt-2 text-sm text-gray-500 flex justify-end">
        <LinkButton buttonText="Display Text Input" onClick={() => setShowBaseInput(true)} />
      </p>
      <p className="mt-2 text-sm text-red-500">{displayInputError(errors, name)}</p>
    </>
  );
}

CronInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  form: FormShape.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CronInput.defaultProps = {
  disabled: false,
};
