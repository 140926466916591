import React from 'react';
import WorkflowShape from '../../../../shapes/WorkflowShape';
import { formatDateTime } from '../../../../helpers/DateHelpers';
import ShowPageSectionCard from '../../../../components/layout/ShowPage/SectionCard';

export default function General({ workflow }) {
  return (
    <ShowPageSectionCard
      title="General Information"
      subTitle="General details about this workflow"
    >
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 m-4">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Name</dt>
          <dd className="mt-1 text-sm text-gray-900">{workflow.name}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Created On</dt>
          <dd className="mt-1 text-sm text-gray-900">{formatDateTime(workflow.inserted_at)}</dd>
        </div>
      </dl>
    </ShowPageSectionCard>

  );
}

General.propTypes = {
  workflow: WorkflowShape.isRequired,
};
