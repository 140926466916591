/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import useMe from '../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import ActionsList from './ActionsList';
import ActionShape from '../../../../shapes/ActionShape';

export default function EventWorkflowTrigger({
  trigger,
  templateWorkflowActions,
  clonedWorkflowActions,
  setCurrentActionTemplate,
  currentActionTemplate,
}) {
  const { connections } = useMe();
  const sourceCxn = connections.find(({ id }) => id === trigger.source_connection_id);
  const templateCallbackActions = templateWorkflowActions.filter((a) => a.topic === trigger.topic);
  return (
    <li key={`trigger-${trigger.topic}-${trigger.source_connection_id}`} className="w-fit">
      <div className="border border-gray-300 rounded bg-50 p-2 text-xs">
        <span className="flex space-x-1">
          <span>
            {connectionAvatar(sourceCxn, { size: 'xxs' })}
          </span>
          <p className="font-normal text-gray-500 mr-1">When an</p>
          <p>{trigger.topic}</p>
          <p className="font-normal text-gray-500 mr-1">event occurs in</p>
          <LinkButton className="text-xs" buttonText={sourceCxn.name} path={`/connections/${sourceCxn.id}`} />
        </span>
      </div>
      {
        templateCallbackActions.length
          ? (
            <ActionsList
              templateCallbackActions={templateCallbackActions}
              templateWorkflowActions={templateWorkflowActions}
              clonedWorkflowActions={clonedWorkflowActions}
              setCurrentActionTemplate={setCurrentActionTemplate}
              currentActionTemplate={currentActionTemplate}
            />
          )
          : null
      }
    </li>
  );
}

EventWorkflowTrigger.propTypes = {
  trigger: PropTypes.shape({
    topic: PropTypes.string.isRequired,
    source_connection_id: PropTypes.string.isRequired,
  }).isRequired,
  templateWorkflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  clonedWorkflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
};

EventWorkflowTrigger.defaultProps = {
  currentActionTemplate: null,
};
