/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { GoDatabase } from 'react-icons/go';
import { PiPlugsConnectedThin, PiPlugsThin, PiCheckCircleThin } from 'react-icons/pi';
import { Tooltip } from 'react-tooltip';
import {
  connectionAvatar,
  apiConfigStatus,
  configurationStatus,
  authType,
} from '../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../shapes/ConnectionShape';
import { formatDateTime } from '../../../helpers/DateHelpers';
import MidDot from '../../../components/MidDot';
import useClient from '../../../hooks/useClient';

export default function Body({ items }) {
  return (
    <tbody className="divide-y divide-gray-300 bg-white">
      {items.map((item) => <Row key={item.id} item={item} />)}
    </tbody>
  );
}

Body.propTypes = {
  items: PropTypes.arrayOf(ConnectionShape).isRequired,
};

function Row({ item }) {
  const [startDate] = useState(DateTime.now().minus({ days: 6 }).toISO());
  const { data: requestLogs, isLoading: isLoadingRequestLogs } = useClient('logs').list(
    {
      records: { destination_connection_id: item.id },
      topic: 'action.processed',
      start_date: startDate,
      page_size: 100,
    },
  );
  const { data: webhooksLogs, isLoading: isLoadingWebhooksLogs } = useClient('logs').list(
    {
      records: { connection_id: item.id },
      topic: 'webhook.received',
      start_date: startDate,
      page_size: 100,
    },
  );
  const { data: webhooks, isLoading: isLoadingWebhooks } = useClient('events').list(
    {
      connection_id: item.id,
      previous_event_id: 'none',
      start_date: startDate,
      page_size: 100,
    },
  );

  return (
    <tr>
      <td className="w-full max-w-0 py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        <div className="flex items-center">
          {connectionAvatar(item, { bgColor: 'bg-gray-100', size: 'sm' })}
          <div className="ml-3">
            <div className="font-medium text-gray-900 flex">
              <span>{item.name}</span>
              <span>
                {/* { item.webhook || item.api ? <GoBrowser size={10} className="text-gray-400" /> : null} */}
                {
                  item.database
                    ? (
                      <>
                        <Tooltip id={item.id} content="Database Connection" />
                        <span data-tooltip-id={item.id}>
                          <GoDatabase size={14} className="text-gray-400 my-1 mx-2" />
                        </span>
                      </>
                    )
                    : null
                }
              </span>
            </div>
            <div className="text-gray-500 text-xs">
              {item.api?.url || item.database?.url}
            </div>
          </div>
        </div>
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Configuration</dt>
          <dd className="mt-1 truncate text-gray-500 text-xs">{configurationStatus(item)}</dd>
        </dl>
      </td>
      <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">
        <div className="ml-3">
          <div className="font-medium text-gray-700 flex">
            {authType(item)}
            {
              apiConfigStatus(item) === 'oauth2_installed'
                ? (
                  <>
                    <Tooltip id={`${item.id}-installed`} content="App Installed" />
                    <span data-tooltip-id={`${item.id}-installed`}>
                      <PiPlugsConnectedThin size={14} className="text-teal-700 my-0.5 mx-1" />
                    </span>
                  </>
                )
                : null
            }
            {
              apiConfigStatus(item) === 'oauth2_pending'
                ? (
                  <>
                    <Tooltip id={`${item.id}-pending`} content="Pending Install" />
                    <span data-tooltip-id={`${item.id}-pending`}>
                      <PiPlugsThin size={14} className="text-yellow-700 my-0.5 mx-1" />
                    </span>
                  </>
                )
                : null
            }
            {
              apiConfigStatus(item) === 'has_creds'
                ? (
                  <>
                    <Tooltip id={`${item.id}-creds`} content="Connection Has Valid API Creds" />
                    <span data-tooltip-id={`${item.id}-creds`}>
                      <PiCheckCircleThin size={14} className="text-green-700 mt-1 mx-1" />
                    </span>
                  </>
                )
                : null
            }
          </div>
          <div className="text-gray-500 text-xs">
            {
              !item.template
                ? isLoadingRequestLogs ? 'Loading...' : `${requestLogs?.length}${requestLogs?.length === 100 ? '+' : ''} API requests`
                : null
            }
          </div>
        </div>
      </td>
      <td className="hidden px-3 py-2 text-sm text-gray-500 lg:table-cell">
        <div className="ml-3">
          <div className="font-medium text-gray-700">
            {item.webhook ? 'Configured' : 'Not Configured'}
          </div>
          <div className="text-gray-500 text-xs flex">
            {
              isLoadingWebhooks || isLoadingWebhooksLogs
                ? 'Loading...'
                : (
                  <>
                    {
                      !item.template
                        ? `${webhooks?.length}${webhooks?.length === 100 ? '+' : ''} Webhooks`
                        : null
                    }
                    <span className="mx-0.5" aria-hidden="true">&middot;</span>
                    {
                      !item.template
                        ? `${webhooksLogs?.length}${webhooksLogs?.length === 100 ? '+' : ''} Logs`
                        : null
                    }
                  </>
                )
            }
          </div>
        </div>
      </td>
      <td className="hidden px-3 py-2 text-sm text-gray-500 xl:table-cell">
        {formatDateTime(item.inserted_at)}
      </td>
      <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {
      item.template
        ? (
          <>
            <Link to={`${item.id}/clone`} className="text-indigo-600 hover:text-indigo-900">
              Clone
            </Link>
            <MidDot />
          </>
        )
        : null
    }
        <Link to={item.id} className="text-indigo-600 hover:text-indigo-900">
          Details
        </Link>
      </td>
    </tr>
  );
}

Row.propTypes = {
  item: ConnectionShape.isRequired,
};
