/* eslint-disable jsx-a11y/label-has-associated-control */
import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';
import { StreamLanguage } from '@codemirror/language';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import React from 'react';
import { EditorView } from 'codemirror';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import FormBody from '../../../../components/forms/CodeInput/FormBody';
import { renderTemplate } from '../../../../helpers/TemplateHelpers';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import EventShape from '../../../../shapes/EventShape';
import FormShape from '../../../../shapes/FormShape';
import LinkButton from '../../../../components/buttons/LinkButton';

function languageExtension(contentType) {
  switch (contentType) {
    case 'form': return StreamLanguage.define(FormBody);
    case 'xml': return xml();
    default: return json();
  }
}

export default function BodyPreviewCard({
  sourceConnection, destinationConnection, event, form,
}) {
  const { watch } = form;
  const rendered = renderTemplate(
    watch('configuration.body') || '',
    {
      action: watch(),
      destination_connection: destinationConnection,
      event,
      source_connection: sourceConnection,
    },
  );

  return (
    <div className="w-full bg-gray-200 p-4 rounded border border-gray-300 mt-6">
      <label htmlFor="Request Body Preview" className="block text-sm font-medium text-gray-700 flex-0">
        Request Body Preview
      </label>
      <div className="mt-1 flex-1">
        <div className="min-h-max p-1 border border-gray-300 rounded-md bg-white">
          <CodeMirror
            editable={false}
            extensions={[
              languageExtension(watch('configuration.content_type')),
              EditorView.lineWrapping,
            ]}
            theme={githubLight}
            value={rendered}
            minHeight="60px"
            basicSetup={{
              highlightActiveLine: false,
              bracketMatching: true,
            }}
          />
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-500">Read-only view of the JSON request body.</p>
      {
        event?.id ? (
          <div className="mt-2 text-sm text-gray-500 rounded border border-indigo-500 py-2">
            <div className="flex">
              <InformationCircleIcon className="text-indigo-500 h-5 w-5 ml-2 mr-1 mt-1" />
              <p className="mt-1">
                This preview was generated using event
              </p>
              {' '}
              <LinkButton className="ml-1 mt-1" buttonText={event?.id} path={`/events/${event?.id}`} />
              <p className="mt-1">
                .
              </p>
            </div>
            {
            sourceConnection?.id !== event?.connection_id
              ? (
                <span className="flex ml-8">
                  This event came from the
                  <p className="font-medium mx-1 text-gray-700">{sourceConnection?.name}</p>
                  {' '}
                  connection, which is a similar connection to
                  <p className="font-medium ml-1 text-gray-700">{destinationConnection?.name}</p>
                  .
                </span>
              ) : null
          }
          </div>
        ) : null
      }
      {
        !event?.id && !!form.watch('topic') ? (
          <div className="mt-2 bg-gray-100 text-sm text-gray-500 rounded border border-yellow-500 py-2">
            <div className="flex">
              <ExclamationTriangleIcon className="text-yellow-500 h-5 w-5 ml-2 mr-1 mt-1" />
              <p className="mt-1">
                We do not have an event to generate a preview with extrapolated event values.
              </p>
            </div>
          </div>
        ) : null
      }
    </div>
  );
}

BodyPreviewCard.propTypes = {
  destinationConnection: ConnectionShape,
  event: EventShape,
  form: FormShape.isRequired,
  sourceConnection: ConnectionShape,
};

BodyPreviewCard.defaultProps = {
  event: null,
  destinationConnection: null,
  sourceConnection: null,
};
