import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import useClient from '../../../../../hooks/useClient';
import Loading from '../../../../../components/Loading';

export default function EventBodyModalBody({ eventId, onClose }) {
  const { data: event, isLoading } = useClient('events').get(eventId);

  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Event Payload</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">The JSON view of the event that triggered this action.</p>
      </div>
      <div className="mx-4 mt-2 rounded border border-gray-300">
        {
          isLoading
            ? <Loading />
            : <ReactJson src={event.payload} name={false} collapsed={1} />
        }
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </div>
  );
}

EventBodyModalBody.propTypes = {
  eventId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
