import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import Form from './Form';
import ActionShape from '../../../shapes/ActionShape';
import EventShape from '../../../shapes/EventShape';

export default function ActionTestPageActionFetchWrapper() {
  const { id } = useParams();
  const { data: action } = useClient('actions').get(id);
  if (!action) return <Loading />;

  return (<ActionTestPageEventFetchWrapper action={action} />);
}

function ActionTestPageEventFetchWrapper({ action }) {
  const urlUsesEvent = action?.configuration?.url?.includes('{{event.');
  const bodyUsesEvent = action?.configuration?.body?.includes('{{event.');
  const headerUsesEvent = action?.configuration?.header?.includes('{{event.');

  // eslint-disable-next-line max-len
  const isEnabled = (!!action?.topic && !!action.source_connection_id) && (urlUsesEvent || headerUsesEvent || bodyUsesEvent);
  const { data: events, isLoading } = useClient('events').list(
    {
      topic: action?.topic,
      connection_id: action?.source_connection_id,
      page_size: 1,
    },
    {
      // eslint-disable-next-line max-len
      enabled: isEnabled,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  const [event] = events || [];
  if (isEnabled && isLoading) return <Loading />;
  return (<ActionTestPageParentEventFetchWrapper action={action} event={event} />);
}

function ActionTestPageParentEventFetchWrapper({ action, event }) {
  const { data: previousEvents, isLoading } = useClient('events').list(
    {
      id: [event?.previous_event_id],
      connection_id: action?.source_connection_id,
      page_size: 1,
    },
    {
      // eslint-disable-next-line max-len
      enabled: !!event?.previous_event_id,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const [previousEvent] = previousEvents || [];

  if (!!event?.previous_event_id && isLoading) return <Loading />;
  const eventProp = event
    ? { ...event, previous_event: previousEvent }
    : null;
  return (<Form action={action} event={eventProp} />);
}

ActionTestPageParentEventFetchWrapper.propTypes = {
  action: ActionShape.isRequired,
  event: EventShape,
};

ActionTestPageParentEventFetchWrapper.defaultProps = {
  event: null,
};
