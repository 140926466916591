/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import FormShape from '../../../../shapes/FormShape';
import JSONInput from '../../../../components/forms/JSONInput';

export default function MetadataSubForm({ form }) {
  const { getValues } = form;
  const [display, setDisplay] = useState(getValues('after_action.topic'));

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!form.watch('metadata')) {
      setDisplay('true');
    }
  }, []);

  if (display) {
    return (
      <JSONInput form={form} label="Metadata" name="metadata" />
    );
  }

  return (
    <div className="pb-6 mt-2">
      <label htmlFor="matcher" className="block text-sm font-medium text-gray-700 mb-2">
        Add Optional Metadata?
      </label>
      <div className="mt-2 border border-gray-300 rounded-md p-2">
        <span className="mt-2 text-sm text-gray-500">
          Add optional metadata that can be accessed in the request URL or request body.
          <button type="button" className="ml-1 text-indigo-700" onClick={() => setDisplay(true)}>
            Add Metadata
          </button>
        </span>
      </div>
    </div>
  );
}

MetadataSubForm.propTypes = {
  form: FormShape.isRequired,
};
