import React from 'react';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import ConnectionTemplateShape from '../../../../../shapes/ConnectionTemplateShape';
import { lastFour } from '../../../../../helpers/ConnectionHelpers';

export default function Basic({ connection, connectionTemplate }) {
  const { api: { authentication } } = connection;
  return (
    <>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Username</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {authentication.username}
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Password</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {lastFour(authentication.password)}
          <p className="text-xs text-gray-400">
            {lastFour(authentication.password).length}
            {' '}
            characters
          </p>
        </dd>
      </div>
    </>
  );
}

Basic.propTypes = {
  connection: ConnectionShape.isRequired,
  connectionTemplate: ConnectionTemplateShape,
};

Basic.defaultProps = {
  connectionTemplate: null,
};
