import React from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import ConnectionTemplateShape from '../../../../shapes/ConnectionTemplateShape';
import CopyButton from '../../../../components/buttons/CopyButton';

export default function WebhookTopicCard({ connection, connectionTemplate }) {
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Webhook URL
            </h2>
          </div>
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 mb-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Webhook URL</dt>
                <dd className="mt-1 text-xs text-gray-900">
                  <span className="text-indigo-600 font-medium mr-1">POST</span>
                  {connection.event_publish_url}
                  <span className="ml-0.5">
                    <CopyButton value={connection.event_publish_url} />
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

WebhookTopicCard.propTypes = {
  connection: ConnectionShape.isRequired,
  connectionTemplate: ConnectionTemplateShape,
};

WebhookTopicCard.defaultProps = {
  connectionTemplate: null,
};
