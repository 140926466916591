import React from 'react';

export default function Header() {
  return (
    <thead className="bg-gray-50 border-b border-gray-300">
      <tr>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          Endpoint
        </th>
        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
          Status
        </th>
        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
          Time
        </th>
        <th scope="col" className="hidden relative py-3.5 pl-3 pr-4 sm:pr-0 sm:table-cell">
          <span className="sr-only">Details</span>
        </th>
      </tr>
    </thead>
  );
}
