import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import LogShape from '../../../../../shapes/LogShape';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import { formatDateTime } from '../../../../../helpers/DateHelpers';
import CreatedSubLine from './CreatedSubLine';
import EventEvaluatedSubLine from './EventEvaluatedSubLine';
import WorkflowSubline from './WorkflowMatchedSubLine';
import UpdatedSubLine from './UpdatedSubLine';

export default function FeedLine({ log, connection }) {
  return (
    <>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        {log.status === 'success' ? (
          <CheckCircleIcon className="h-4 w-4 text-teal-600" aria-hidden="true" />
        ) : (
          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
        )}
      </div>
      <span className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className="font-medium text-gray-900">
          { log.topic === 'event.created' ? 'Event Created' : null }
          { log.topic === 'event.processed' ? 'Event Submitted & Evaluated' : null }
          { log.topic === 'event.updated' ? 'Event Updated' : null }
          { (log.topic === 'workflow.evaluated' && !!log.matchedActions) ? 'Workflow Triggered' : null }
        </span>
        { log.topic === 'event.created' ? <CreatedSubLine log={log} connection={connection} /> : null }
        { log.topic === 'event.processed' ? <EventEvaluatedSubLine log={log} /> : null }
        { log.topic === 'event.updated' ? <UpdatedSubLine log={log} /> : null }
        { (log.topic === 'workflow.evaluated' && !!log.matchedActions) ? <WorkflowSubline log={log} /> : null }
      </span>
      <time dateTime={log.dateTime} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
        {formatDateTime(log.inserted_at)}
      </time>
    </>
  );
}

FeedLine.propTypes = {
  log: LogShape.isRequired,
  connection: ConnectionShape,
};

FeedLine.defaultProps = {
  connection: null,
};
