/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import TriggerTypeInput from './TriggerTypeInput';
import CronInput from '../../../../components/forms/CronInput';
import FormShape from '../../../../shapes/FormShape';
import MatcherSubForm from './MatcherSubForm';

export default function TriggerSubForm({ form }) {
  useEffect(() => {
    if (!!form.watch('topic')) {
      form.setValue('trigger_type', 'event');
    }
    if (!!form.watch('schedule')) {
      form.setValue('trigger_type', 'schedule');
    }
  }, []);

  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
        <div className="space-y-6">
          <div className="pt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Trigger Type</h3>
            <p className="mt-1 text-sm text-gray-500">
              Let's start by specifying what causes this action to trigger.
            </p>
            <TriggerTypeInput form={form} />
          </div>
          {
            form.watch('trigger_type') === 'schedule'
              ? (
                <div>
                  <CronInput
                    className="text-red-400"
                    form={form}
                    label="Schedule"
                    name="schedule"
                    required
                  />
                </div>
              )
              : null
          }
        </div>
        {
          form.watch('trigger_type') === 'event'
            ? (<MatcherSubForm form={form} />)
            : null
        }
      </div>
    </div>
  );
}

TriggerSubForm.propTypes = {
  form: FormShape.isRequired,
};
