import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import Header from '../layout/PageHeader';
import Footer from './Footer';

export default function ModalFormLayout({
  children,
  onSubmit,
  navigate,
  isSubmitting,
  title,
  description,
  hideSubmitButtons,
  submitButtonText,
  isSubmittingText,
}) {
  return (
    <>
      <div className="mx-8">
        <Header
          name={title}
          description={description}
          icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
        />
      </div>
      <form className="flex flex-col" onSubmit={onSubmit}>
        <div>
          {children}
          {
            !hideSubmitButtons
              ? (
                <div className="mx-8">
                  <Footer
                    backButtonText="Back"
                    onBackButtonClick={() => navigate(-1)}
                    submitButtonText={submitButtonText}
                    submitButtonDisabled={isSubmitting}
                    submitButtonType="submit"
                    isSubmitting={isSubmitting}
                    isSubmittingText={isSubmittingText}
                  />
                </div>
              )
              : null
          }
        </div>
      </form>
    </>
  );
}

ModalFormLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
  description: PropTypes.string.isRequired,
  hideSubmitButtons: PropTypes.bool,
  isSubmittingText: PropTypes.string,
};

ModalFormLayout.defaultProps = {
  hideSubmitButtons: false,
  submitButtonText: 'Submit',
  isSubmittingText: null,
};
