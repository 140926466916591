import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import useClient from '../../../hooks/useClient';
import Form from './Form';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Loading from '../../../components/Loading';
import ActionShape from '../../../shapes/ActionShape';

export default function ActionSuperEditPage() {
  const { id } = useParams();
  const { data: action } = useClient('actions').get(id);
  if (!action) return <Loading />;
  return (<ActionSuperEditPageForm action={action} />);
}

function ActionSuperEditPageForm({ action }) {
  const defaultValues = _.omit(action, ['id', 'inserted_at', 'updated_at', 'record_type']);
  const form = useForm({ defaultValues });
  const client = useClient('actions');
  const navigate = useNavigate();
  const { isLoading: isSubmitting, mutate } = client.update(action.id, {
    onSuccess: () => navigate(`/actions/${action.id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });
  const onSubmit = form.handleSubmit((a) => mutate({ action: a }));

  return (
    <Form
      onSubmit={onSubmit}
      form={form}
      isSubmitting={isSubmitting}
    />
  );
}

ActionSuperEditPageForm.propTypes = {
  action: ActionShape,
};

ActionSuperEditPageForm.defaultProps = {
  action: null,
};
