/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ActionShape from '../../../../shapes/ActionShape';
import EventWorkflowTrigger from './EventTriggeredFlow';
import ScheduledWorkflowTrigger from './SheduleTriggeredFlow';

export default function CloneWorkflowLayout({ templateWorkflowActions, clonedWorkflowActions, setCurrentActionTemplate, currentActionTemplate }) {
  const callbackTopics = _.compact(templateWorkflowActions.map((a) => a.after_action?.topic));
  const entryLevelActions = templateWorkflowActions.filter((a) => !!a.schedule || !callbackTopics.includes(a.topic));
  const eventTriggers = entryLevelActions.filter((a) => !!a.topic);
  const topicSourceCombos = eventTriggers.map((c) => ({ topic: c.topic, source_connection_id: c.source_connection_id }));
  const uniqEventTriggers = _.uniqWith(topicSourceCombos, _.isEqual);
  const scheduledTriggers = entryLevelActions.filter((a) => !!a.schedule);

  return (
    <div className="border-none">
      <div colSpan={5} className="w-full max-w-0 whitespace-nowrap px-6 pb-3 text-sm font-medium text-gray-900">
        <ul className="space-y-6 w-full p-3 pt-0">
          {
            scheduledTriggers.map((a) => (
              <ScheduledWorkflowTrigger
                key={`trigger-${a.id}`}
                action={a}
                templateWorkflowActions={templateWorkflowActions}
                clonedWorkflowActions={clonedWorkflowActions}
                setCurrentActionTemplate={setCurrentActionTemplate}
                currentActionTemplate={currentActionTemplate}
              />
            ))
          }
          {
            uniqEventTriggers.map((t) => (
              <EventWorkflowTrigger
                key={`trigger-${t.topic}-${t.source_connection_id}`}
                trigger={t}
                templateWorkflowActions={templateWorkflowActions}
                clonedWorkflowActions={clonedWorkflowActions}
                setCurrentActionTemplate={setCurrentActionTemplate}
                currentActionTemplate={currentActionTemplate}
              />
            ))
          }
        </ul>
      </div>
    </div>
  );
}

CloneWorkflowLayout.propTypes = {
  templateWorkflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  clonedWorkflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
};

CloneWorkflowLayout.defaultProps = {
  currentActionTemplate: null,
};
