import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TrashIcon, SignalIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../shapes/ConnectionShape';
import EventShape from '../../../shapes/EventShape';
import DeleteConfirm from '../../../components/DeleteConfirm';
import RetryConfirm from './RetryConfirm';
import InfoCard from './InfoCard';
import BodyWrapper from '../../../components/layout/ShowPage/BodyWrapper';
import PageWrapper from '../../../components/layout/PageWrapper';
import Payload from './Payload';
import ActionTreeCard from './ActionTreeCard';

export default function EventView({
  connection,
  event,
  onDestroy,
  onRetry,
}) {
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();

  const headerButtons = [
    { label: 'Retry', icon: ArrowPathIcon, onClick: () => setModal('retry') },
    {
      label: 'Create Action',
      icon: SignalIcon,
      onClick: () => {
        const search = createSearchParams({
          event_id: event.id,
          topic: event.topic,
          source_connection_id: event.connection_id,
        }).toString();
        navigate({
          pathname: '/actions/new',
          search,
        });
      },
    },
    { label: 'Delete', icon: TrashIcon, onClick: () => setModal('delete') },
  ];

  return (
    <PageWrapper>
      <DeleteConfirm
        title="Delete Event"
        description="Are you sure you want to permanently delete this event? All of your log records will remain, but no event payload data will persist."
        open={modal === 'delete'}
        onClose={() => setModal(null)}
        onConfirm={onDestroy}
      />
      <RetryConfirm
        title="Retry Event"
        description="Are you sure you want to retry this event?"
        open={modal === 'retry'}
        onClose={() => setModal(null)}
        onConfirm={onRetry}
      />
      <Header
        description={`${event.topic} event ${connection ? ` from ${connection.name}` : ''}`}
        name="Event Detail Page"
        buttons={headerButtons}
        avatar={connection ? connectionAvatar(connection, { size: 'lg' }) : null}
        icon={connection ? null : <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <BodyWrapper>
        <InfoCard event={event} connection={connection} />
        <Payload event={event} />
        <ActionTreeCard event={event} />
      </BodyWrapper>
    </PageWrapper>
  );
}

EventView.propTypes = {
  connection: ConnectionShape,
  event: EventShape.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

EventView.defaultProps = {
  connection: null,
};
