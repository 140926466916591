import React from 'react';
import Input from '../../../components/forms/Input';
import JSONInput from '../../../components/forms/JSONInput';
import FormShape from '../../../shapes/FormShape';
import ComboBox from '../../../components/forms/ComboBox';
import useMe from '../../../context/CurrentUserContext';

export default function FormBody({ form }) {
  const { connections } = useMe();
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="pt-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Event Details</h3>
          <p className="mt-1 text-sm text-gray-500">
            Specify an Event Topic, followed by a payload.
          </p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-2">
          <div className="col-span-2">
            <ComboBox
              label="Connection"
              options={connections}
              preventSort
              form={form}
              name="connection_id"
              labelKey="name"
              valueKey="id"
              imageKey="icon_url"
            />
          </div>
          <div className="col-span-2">
            <Input form={form} label="Topic" name="topic" required />
          </div>
          <div className="col-span-2">
            <Input form={form} label="Previous Event ID" name="previous_event_id" />
          </div>
          <div className="col-span-2">
            <JSONInput form={form} label="Payload" name="payload" />
          </div>
        </div>
      </div>
    </div>
  );
}

FormBody.propTypes = {
  form: FormShape.isRequired,
};
