import axios from 'axios';
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import useAuth from './AuthContext';
import { apiURL } from '../config';

export const AxiosContext = createContext();
const { Provider } = AxiosContext;

export function AxiosProvider({ children }) {
  const [currentEntity, setCurrentEntity] = useState();
  const { accessToken } = useAuth();

  const headers = { Authorization: `Bearer ${accessToken}` };

  const privateAxios = axios.create({
    baseURL: apiURL,
    headers,
  });

  const publicAxios = axios.create({
    baseURL: apiURL,
  });

  return (
    <Provider
      value={{
        privateAxios,
        publicAxios,
        currentEntity,
        setCurrentEntity,
      }}
    >
      {children}
    </Provider>
  );
}

AxiosProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
