import React, { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { useForm } from 'react-hook-form';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ConnectionShape from '../../../shapes/ConnectionShape';
import useClient from '../../../hooks/useClient';
import LinkButton from '../../../components/buttons/LinkButton';

export default function ReadMeCard({ connection }) {
  const defaultValue = connection.readme || '';
  const [editMode, setEditMode] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const form = useForm({ defaultValues: { readme: defaultValue } });

  const { mutate } = useClient('connections').update(connection.id, {
    onSuccess: () => {
      setDisplaySuccess(true);
      setEditMode(false);
    },
    // onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((c) => mutate({ connection: c }));

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Read Me
            </h2>
            { !editMode ? <LinkButton buttonText="Edit" onClick={() => setEditMode(true)} /> : null }
          </div>
          {
            displaySuccess
              ? (
                <div className="rounded-md bg-teal-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-teal-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-teal-800">Successfully updated</p>
                    </div>
                    <div className="ml-auto pl-3">
                      <div className="-mx-1.5 -my-1.5">
                        <button
                          type="button"
                          onClick={() => setDisplaySuccess(false)}
                          className="inline-flex rounded-md bg-teal-50 p-1.5 text-teal-500 hover:bg-teal-100 focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2 focus:ring-offset-teal-50"
                        >
                          <span className="sr-only">Dismiss</span>
                          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null
          }
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 mb-6">
            <form onSubmit={onSubmit}>
              <MDEditor
                value={form.watch('readme')}
                onChange={(val) => form.setValue('readme', val || '')}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
              <div className="mt-8">
                <div className="md:block hidden">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3 pt-4">
                      <button type="button" onClick={() => setEditMode(false)} className="text-indigo-700">
                        Cancel
                      </button>
                    </div>
                    <div className="flex gap-5 pt-4">
                      <button
                        type="submit"
                        className="bg-indigo-700 border border-indigo-700 hover:bg-indigo-600' text-white mx-1 my-2 transition duration-150 ease-in-out focus:outline-none rounded px-6 py-2 text-sm focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div className="md:hidden block pt-4">
                  <button
                    type="submit"
                    className="text-white border border-indigo-700 bg-indigo-700 px-6 py-3 rounded font-medium w-full hover:bg-indigo-600"
                  >
                    Submit
                  </button>
                  <div className="flex justify-center items-center gap-3 pt-4">
                    <button type="button" onClick={() => setEditMode(false)} className="text-indigo-700 font-medium">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

ReadMeCard.propTypes = {
  connection: ConnectionShape.isRequired,
};
