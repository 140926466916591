import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import ErrorFallback from './ErrorFallback';

export default function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary FallbackComponent={ErrorFallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
