import PropTypes from 'prop-types';

export default PropTypes.shape({
  record_type: PropTypes.oneOf(['template_field']),
  default: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  helper_text: PropTypes.string,
  input_type: PropTypes.oneOf(['email', 'hidden', 'integer', 'phone_number', 'text', 'textarea']),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['integer', 'string']),
});
