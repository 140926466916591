import { unique } from '../helpers/ArrayHelpers';
// eslint-disable-next-line import/no-cycle
import useClient from './useClient';
// eslint-disable-next-line import/no-cycle
import withOne from './withOne';

function manyWithMany(response, relation, options) {
  const resource = options?.resource || relation;
  const {
    // TODO: isLoadingBase could be used to ensure all relations calls go out at once when chaining.
    data: records,
    isLoading: isLoadingBase,
  } = response;

  const foreignKey = `${records[0]?.record_type}_id`;
  const { data: relations, isLoading: isLoadingRelations } = useClient(resource).list({
    [foreignKey]: unique(records.map(({ id }) => id)),
  }, {
    enabled: !isLoadingBase,
  });

  const isLoading = isLoadingBase || isLoadingRelations;
  if (isLoading) return response;

  const updatedRecords = records.map((record) => {
    const filteredRelations = relations.filter(({ id }) => record[relation].includes(id));

    return {
      ...record,
      [relation]: filteredRelations,
    };
  });

  return {
    ...response,
    data: updatedRecords,
    isLoading,
    isLoadingBase,
  };
}

function oneWithMany(response, relation, options) {
  const resource = options?.resource || relation;

  const {
    // TODO: isLoadingBase could be used to ensure all relations calls go out at once when chaining.
    isLoading: isLoadingBase,
    data: record,
  } = response;

  const foreignKey = options?.foreignKey || `${record?.record_type}`;

  const { data: relations, isLoading: isLoadingRelations } = useClient(resource).list({
    [foreignKey]: record?.id,
  }, {
    enabled: !isLoadingBase,
  });

  const isLoading = isLoadingBase || isLoadingRelations;
  if (isLoading) return response;

  const updatedRecord = {
    ...record,
    [relation]: relations,
  };

  return {
    ...response,
    data: updatedRecord,
    isLoading,
    isLoadingBase,
  };
}

export default function withMany(response, relation, options) {
  const { data } = response;

  const result = Array.isArray(data)
    ? manyWithMany(response, relation, options)
    : oneWithMany(response, relation, options);

  return {
    ...result,
    withMany: (nextRelation, nextOptions) => withMany(result, nextRelation, nextOptions),
    withOne: (nextRelation, nextOptions) => withOne(result, nextRelation, nextOptions),
  };
}
