export default {
  name: 'mustache',
  token(stream) {
    let ch;
    if (stream.match('{{')) {
      // eslint-disable-next-line no-cond-assign
      while ((ch = stream.next()) != null) {
        if (ch === '}' && stream.next() === '}') {
          stream.eat('}');
          return 'variable';
        }
      }
    }
    // eslint-disable-next-line no-empty
    while (stream.next() != null && !stream.match('{{', false)) {}
    return null;
  },
};
