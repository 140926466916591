import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { EditorView } from 'codemirror';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import FormShape from '../../shapes/FormShape';

export default function JSONInput({
  form, label, name, required,
}) {
  const {
    clearErrors,
    control,
    formState: { errors },
    setError,
  } = form;

  const {
    field: {
      onChange, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const format = (string) => JSON.stringify(string, null, '  ');
  const [localValue, setLocalValue] = useState(value ? format(value) : '');
  const onChangeValue = (formValue) => {
    setLocalValue(formValue);
  };

  const onBlur = () => {
    if (localValue) {
      try {
        const updatedValue = JSON.parse(localValue);
        setLocalValue(format(updatedValue));
        onChange(updatedValue);
        clearErrors(name);
      } catch {
        setLocalValue(localValue);
        onChange('Invalid JSON');
        setError(name, { type: 'custom', message: 'Invalid JSON' });
      }
    } else {
      onChange(undefined);
    }
  };

  return (
    <div className="sm:col-span-6">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 min-h-max p-1 border border-gray-300 rounded-md bg-white">
        <CodeMirror
          extensions={[
            json(),
            EditorView.lineWrapping,
          ]}
          name={name}
          onBlur={onBlur}
          onChange={onChangeValue}
          theme={githubLight}
          value={localValue}
          minHeight="60px"
          basicSetup={{
            highlightActiveLine: false,
            bracketMatching: true,
          }}
        />
      </div>
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {errors[name]?.message}
        {localValue.error ? `Line ${localValue.error.line} - ${localValue.error.reason}.` : null}
      </p>
    </div>
  );
}

JSONInput.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

JSONInput.defaultProps = {
  required: false,
};
