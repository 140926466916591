import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../../context/AuthContext';
import { AxiosContext } from '../../../context/AxiosContext';
import Form from './Form';

export default function Login() {
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { persistAccessToken } = useAuth();
  const { publicAxios } = useContext(AxiosContext);

  const login = async (user) => {
    const response = await publicAxios.post('users/log_in', { user });
    return response.data;
  };

  const defaultValues = { email: '', password: '' };
  const form = useForm({ defaultValues });

  const redirectCallback = () => {
    if (state?.path) navigate(state?.path);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: login,
    onError: () => setLoginError(true),
    onSuccess: (r) => persistAccessToken(r, redirectCallback),
  });

  return (
    <Form
      form={form}
      mutate={mutate}
      loginError={loginError}
      isLoading={isLoading}
    />
  );
}
