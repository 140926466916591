/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import { XMarkIcon, PlusCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import FormShape from '../../../../../shapes/FormShape';
import LinkButton from '../../../../../components/buttons/LinkButton';

export default function ScopeTags({ form, editMode }) {
  const [display, setDisplay] = useState(false);
  const [displayNewTagInput, setDisplayNewTagInput] = useState(true);

  const currentVal = form.watch('authentication.scope') || null;
  const currentValArray = currentVal?.split(' ') || [];

  const onChangeTags = (arr) => {
    const newVal = arr.join(' ');
    form.setValue('authentication.scope', newVal);
    setDisplayNewTagInput(false);
  };

  const removeScope = (key) => {
    const newArr = _.xor(currentValArray, [key]);
    form.setValue('authentication.scope', newArr.join(' '));
    setDisplayNewTagInput(false);
  };

  const defaultScope = (form.formState.defaultValues.authentication?.scope || '').split(' ').sort().join('');
  const sortedCurrentVal = currentValArray.sort().join('');
  const hasChanged = defaultScope !== sortedCurrentVal;

  function defaultRenderInput({
    onChange, value, onBlur, onFocus, onKeyDown, onPaste, ref,
  }) {
    return (
      <div className="w-full xl:w-3/5">
        <label htmlFor="new scope" className="block text-sm font-medium text-gray-700 mb-2">
          New Scope
        </label>
        <div className="flex">
          <input
            type="text"
            onBlur={onBlur}
            onFocus={onFocus}
            onPaste={onPaste}
            placeholder="New Scope..."
            ref={ref}
            onKeyDown={onKeyDown}
            onChange={onChange}
            value={value}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <button
            type="button"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusCircleIcon className="h-5 w-4 mr-1 " aria-hidden="true" />
            Add
          </button>
        </div>
      </div>
    );
  }

  function defaultRenderTag({ tag, key, getTagDisplayValue }) {
    return (
      <span key={key} className="ml-1 mb-4">
        <span className="mt-2 mx-1 inline-flex items-center rounded bg-indigo-100 border border-indigo-400 px-1 text-sm font-medium text-indigo-700">
          {getTagDisplayValue(tag)}
          <LinkButton
            buttonText={<XMarkIcon className="text-indigo-700 h-3 w-3" />}
            className="ml-2"
            onClick={() => removeScope(tag)}
          />
        </span>
      </span>
    );
  }

  function defaultRenderLayout(tagElements, inputElement) {
    return (
      <span>
        <label htmlFor="response_key" className="block text-sm font-medium text-gray-700 mb-1">
          Access Scope
        </label>
        <div style={{ minHeight: '38px' }} className="border border-gray-300 rounded-md p-2">
          { currentValArray.length ? <div className="mb-8">{tagElements}</div> : null }
          {
            displayNewTagInput ? (
              <div className="m-1 mt-2">
                {inputElement}
              </div>
            ) : null
          }
        </div>
        <div className="flex justify-end mt-2">
          {
            !displayNewTagInput
              ? (
                <LinkButton buttonText="Add Additional Scope" onClick={() => setDisplayNewTagInput(true)} />
              )
              : null
          }
        </div>
      </span>
    );
  }

  if (display) {
    return (
      <div className="pt-6">
        <TagsInput
          addOnBlur
          form={form}
          name="authentication.scope"
          value={currentValArray}
          onChange={onChangeTags}
          renderInput={defaultRenderInput}
          renderLayout={defaultRenderLayout}
          renderTag={defaultRenderTag}
        />
        {
          hasChanged && editMode ? (
            <div className="mt-2 text-sm text-gray-500 rounded border border-yellow-500 py-2">
              <div className="flex">
                <InformationCircleIcon className="text-yellow-500 h-5 w-5 ml-2 mr-1 mt-1" />
                <span className="mt-1">
                  Updating scopes will require re-authing this connection using the
                  {' '}
                  <span className="text-gray-700 font-medium">
                    Auth Url.
                  </span>
                </span>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }

  return (
    <div className="pt-6">
      <label htmlFor="matcher" className="block text-sm font-medium text-gray-700">
        Access Scope
      </label>
      <p className="mt-2 text-sm text-gray-500">
        You can add access scopes to these API Creds. Most OAuth 2.0 APIs will require access scopes.
        <button type="button" className="ml-1 text-indigo-700" onClick={() => setDisplay(true)}>
          Add Scope
        </button>
      </p>
    </div>
  );
}

ScopeTags.propTypes = {
  form: FormShape.isRequired,
  editMode: PropTypes.bool.isRequired,
};
