import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Form from '../Form';
import WorkflowShape from '../../../shapes/WorkflowShape';

export default function EditPageWrapper() {
  const { id } = useParams();
  const client = useClient('workflows');
  const { data: workflow, isLoading } = client.get(id);
  if (isLoading) return <Loading />;
  return (<EditPage workflow={workflow} />);
}

function EditPage({ workflow }) {
  const navigate = useNavigate();
  const form = useForm({ defaultValues: workflow });

  const { isLoading: isSubmitting, mutate } = useClient('workflows').update(workflow.id, {
    onSuccess: () => navigate(`/workflows/${workflow.id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((record) => mutate({ workflow: record }));

  return (
    <Form
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      form={form}
      submitButtonText="Update Workflow"
      formTitle="Update Workflow Form"
      formSubTitle="Update an existing workflow's name or layout."
      displayLayoutInput
    />
  );
}

EditPage.propTypes = {
  workflow: WorkflowShape.isRequired,
};
