import React, { useState, useEffect } from 'react';
import EventShape from '../../../../shapes/EventShape';
import useClient from '../../../../hooks/useClient';
import ViewBuilder from './ViewBuilder';
import Loading from '../../../../components/Loading';
import ShowPageSectionCard from '../../../../components/layout/ShowPage/SectionCard';

export default function ThreadTree({ event }) {
  const { data: events, isLoading } = useClient('events').list({
    thread: event.thread,
    page_size: 50,
  });

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);

  const eventsIds = events?.map((e) => e.id) || [];
  const { data, meta } = useClient('logs').list(
    { records: { event_id: [...[event.id], ...eventsIds] }, page_size: 100 },
    { enabled: !!events?.length },
  );
  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    } else {
      setIsLoadingLogs(false);
    }
  }, [meta?.page]);

  const displayLogs = logs.filter((l) => l.records.event_id === event.id);
  const displayEvents = events?.map((e) => (
    {
      ...e,
      logs: logs.filter((l) => l.records.event_id === e.id),
    }
  )) || [];

  const displayEvent = { ...event, logs: displayLogs };
  if (isLoading || isLoadingLogs || !displayEvent.logs.length) return <Loading />;
  return (
    <ShowPageSectionCard
      title="Thread Layout"
      subTitle="Thread history for this event"
    >
      <ViewBuilder event={displayEvent} events={displayEvents} />
    </ShowPageSectionCard>

  );
}

ThreadTree.propTypes = {
  event: EventShape.isRequired,
};
