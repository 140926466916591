import React, { useEffect } from 'react';
import Input from '../../../../../components/forms/Input';
import FormShape from '../../../../../shapes/FormShape';

export default function BasicSubForm({ form }) {
  useEffect(() => {
    if (!form.watch('api.authentication.username') || !form.watch('api.authentication.password')) {
      form.setValue(
        'api.authentication',
        {
          record_type: 'basic',
          username: '',
          password: '',
        },
      );
    }
  }, []);

  return (
    <div className="mt-2">
      <div className="mb-4">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Username"
          name="username"
          placeholder="Username..."
        />
      </div>
      <div className="mb-4">
        <Input
          // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Password"
          name="password"
          placeholder="Password..."
        />
      </div>
    </div>
  );
}

BasicSubForm.propTypes = {
  form: FormShape.isRequired,
};
