import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import useMe from '../../../../context/CurrentUserContext';
import LinkButton from '../../../../components/buttons/LinkButton';
import Modal from '../../../../components/Modal';
import EvaluationsListBody from './EvaluationsListBody';

function EventNode({ data }) {
  const [displayEvalList, setDisplayEvalList] = useState(false);
  const { connections } = useMe();
  const connection = connections.find(({ id }) => id === data.event.connection_id);
  const attemptsCount = data.evaluationLogs.length;
  return (
    <div
      className="px-2 py-1 rounded-sm bg-white border border-gray-300"
      style={{ height: '84px', width: '540px' }}
    >
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={displayEvalList}
        onClose={() => setDisplayEvalList(false)}
      >
        <EvaluationsListBody
          currentLog={data.log}
          logs={data.evaluationLogs}
          onClose={() => setDisplayEvalList(false)}
          setEvaluationDisplay={data.setEvaluationDisplay}
          evaluationDisplay={data.evaluationDisplay}
        />
      </Modal>
      <div className="flex justify-between border-b">
        <h3 className="text-base font-semibold leading-6 text-gray-700">
          { data.fromAction ? 'Event' : 'Entry Event' }
        </h3>
        {
          data.evaluationLogs.length > 1
            ? (
              <LinkButton
                buttonText={`${attemptsCount - 1} Retr${attemptsCount === 2 ? 'y' : 'ies'}`}
                onClick={() => setDisplayEvalList(true)}
              />
            )
            : null
        }
      </div>
      <div className="ml-2 mt-2">
        <div className="text-sm flex space-x-1">
          <span className="mt-0.5">
            {connectionAvatar(connection, { size: 'xxs' })}
          </span>
          <p className="text-gray-600 font-medium ml-1">
            {connection?.name}
            {' '}
            {data.log.records.evaluation}
          </p>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-500 text-xs">{data.event.topic}</div>
          <div className="text-gray-500 text-xs">{data.log.inserted_at}</div>
        </div>
      </div>
      {data.fromAction > 0 ? <Handle type="target" position={Position.Top} /> : null }
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}

export default memo(EventNode);
