import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import FormShape from '../../../../shapes/FormShape';

const options = [
  {
    name: 'By Event',
    value: 'event',
    description: 'Trigger this action when an event with a specific topic happens in a Connection.',
  },
  {
    name: 'On a Schedule',
    value: 'schedule',
    description: 'Trigger this event on a set Cron Schedule.',
  },
];

export default function TriggerTypeInput({ form }) {
  const value = form.watch('trigger_type');
  return (
    <div className="my-4">
      <RadioGroup value={value} onChange={(v) => form.setValue('trigger_type', v.value)}>
        <RadioGroup.Label className="sr-only">Trigger Type</RadioGroup.Label>
        <div className="-space-y-px rounded-md bg-white">
          {options.map((option, optionIdx) => (
            <RadioGroup.Option
              key={option.name}
              value={option}
              className={classNames(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                value === option.value ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none',
              )}
            >
              <span
                className={classNames(
                  value === option.value ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                  'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                )}
                aria-hidden="true"
              >
                <span className="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <span className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames(value === option.value ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                >
                  {option.name}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames(value === option.value ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                >
                  {option.description}
                </RadioGroup.Description>
              </span>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}

TriggerTypeInput.propTypes = {
  form: FormShape.isRequired,
};
