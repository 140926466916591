/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ActionShape from '../../../../shapes/ActionShape';

export default function Preview({
  action,
}) {
  return (
    <span className="flex">
      When a(n)
      {' '}
      {action.topic}
      {' '}
      event happens in
      {' '}
      {action.sourceConnection?.name}
      ,
      send a
      {' '}
      {action.configuration.method}
      {' '}
      API Request to
      {' '}
      {action.destinationConnection?.name}
      .
    </span>
  );
}

Preview.propTypes = {
  action: ActionShape.isRequired,
};
