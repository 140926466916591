import { SignalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import Header from '../../../components/layout/PageHeader';
import Footer from '../../../components/forms/Footer';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import useClient from '../../../hooks/useClient';
import FormBody from '../CreatePage/FormBody';

export default function EventForm({ event, connection }) {
  const navigate = useNavigate();
  const form = useForm({ defaultValues: event });

  const client = useClient('events');
  const { isLoading: isSubmitting, mutate } = client.update(
    event.id,
    {
      onSuccess: () => navigate(`/events/${event.id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit((e) => mutate({ event: e }));

  return (
    <form onSubmit={onSubmit}>
      <div className="px-4 sm:px-6 lg:px-8">
        <Header
          name="Edit Event Form"
          description={`Edit an existing ${connection?.name} event.`}
          icon={<SignalIcon className="h-8 w-8 m-1 text-indigo-700" />}
        />
        <div className="mx-auto max-w-3xl lg:max-w-7xl">
          <div className="bg-white border rounded-sm mt-10 p-6">
            <FormBody form={form} />
            <Footer
              backButtonText="Back to Events"
              onBackButtonClick={() => navigate(-1)}
              submitButtonText="Submit"
              submitButtonDisabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
