import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import View from './View';
import { byInsertedAt, byTopic, byRecord } from '../../../../helpers/LogHelpers';
import useMe from '../../../../context/CurrentUserContext';
import EventShape from '../../../../shapes/EventShape';

export default function ViewBuilderWrapper({ event, events }) {
  const [evaluationDisplay, setEvaluationDisplay] = useState({});
  const key = Object.values(evaluationDisplay).map((e) => e.id).join('.');
  return (
    <ViewBuilder
      key={key}
      event={event}
      events={events}
      evaluationDisplay={evaluationDisplay}
      setEvaluationDisplay={setEvaluationDisplay}
    />
  );
}

ViewBuilderWrapper.propTypes = {
  event: EventShape.isRequired,
  events: PropTypes.arrayOf(EventShape).isRequired,
};

function ViewBuilder({ event, events, evaluationDisplay, setEvaluationDisplay }) {
  const { actions } = useMe();
  const nodes = [];
  const edges = [];

  function generateTreeForEvent(e, fromActionProcessLogId) {
    const processLogs = e.logs.filter(byTopic('event.processed'));
    const sortedProcessLogs = processLogs.sort(byInsertedAt);
    const lastProcessLog = sortedProcessLogs[sortedProcessLogs.length - 1];
    const processLog = evaluationDisplay[e.id] || lastProcessLog;

    // Iterate through each process evaluation of the logs
    const eventNodeId = `${e.id}-processed-${processLog.id}`;
    nodes.push({
      id: eventNodeId,
      data: {
        event: e,
        log: processLog,
        evaluationLogs: sortedProcessLogs,
        fromAction: !!fromActionProcessLogId,
        evaluationDisplay,
        setEvaluationDisplay,
      },
      type: 'event',
      position: { x: 0, y: 0 },
    });
    if (fromActionProcessLogId) {
      edges.push({
        id: `${fromActionProcessLogId}-${eventNodeId}`,
        source: fromActionProcessLogId,
        target: eventNodeId,
        type: 'smoothstep',
        animated: true,
      });
    }

    const actionEvalLogs = e.logs
      .filter(byRecord(processLog, 'evaluation'))
      .filter(byTopic('action.evaluated'));

    const eventNonMatchid = `${e.id}-non-match-${processLog.id}`;
    nodes.push({
      id: eventNonMatchid,
      data: { actionEvalLogs },
      type: 'action_eval',
      position: { x: 0, y: 0 },
    });
    edges.push({
      id: `${eventNodeId}-${eventNonMatchid}`,
      source: eventNodeId,
      target: eventNonMatchid,
      type: 'smoothstep',
      animated: true,
    });

    const actionProcessLogs = e.logs
      .filter(byRecord(processLog, 'evaluation'))
      .filter(byTopic('action.processed'));
    const uniqActionProcessLogs = _.uniqBy(actionProcessLogs, 'records.action_id');

    uniqActionProcessLogs.forEach((actionProcessLog, actionProcessLogIdx) => {
      const action = actions.find((a) => a.id === actionProcessLog.records.action_id);
      const actionProcessLogId = actionProcessLog.id;
      let displayBottomHandle;

      if (action.after_action?.topic) {
        // eslint-disable-next-line max-len
        const cbEv = events.filter((ev) => ev.previous_event_id === e.id && ev.topic === action.after_action?.topic);
        if (cbEv) displayBottomHandle = true;
        cbEv.forEach((e) => generateTreeForEvent(e, actionProcessLogId));
      }
      nodes.push({
        id: actionProcessLogId,
        data: {
          displayBottomHandle,
          log: actionProcessLog,
          allLogs: actionProcessLogs,
          index: actionProcessLogIdx,
        },
        type: 'action_processed',
        position: { x: 0, y: 0 },
      });
      edges.push({
        id: `${eventNodeId}-${actionProcessLogId}`,
        source: eventNonMatchid,
        target: actionProcessLogId,
        type: 'smoothstep',
        animated: true,
      });

      if (action.after_action?.topic) {
        // eslint-disable-next-line max-len
        const cbEv = events.filter((ev) => ev.previous_event_id === e.id && ev.topic === action.after_action?.topic);
        cbEv.forEach((e) => generateTreeForEvent(e, actionProcessLogId));
      }
    });

    // nodes.push({
    //   id: eventNodeId,
    //   // eslint-disable-next-line max-len
    //   data: { event: e, depth: 0 },
    //   type: 'event',
    //   position: { x: 0, y: 0 },
    // });
  }
  generateTreeForEvent(event, 0);

  return (<View initialEdges={edges} initialNodes={nodes} />);
}
