export function abbreviateUuid(uuid) {
  return uuid?.substring(uuid.length - 12);
}

export function startsWithVowel(str) {
  const vowelRegex = '^[aieouAIEOU].*';
  return str.match(vowelRegex);
}

export default {
  abbreviateUuid,
  startsWithVowel,
};
