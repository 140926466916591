/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ComboBox from '../../../components/forms/ComboBox';
import useMe from '../../../context/CurrentUserContext';

export default function FilterForm({ onCloseModal }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const scrubbedParams = Object.entries(searchParams).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});

  const form = useForm({ defaultValues: scrubbedParams });

  const onChangeFilter = (filter) => {
    const scrubbedFilter = Object.entries(filter).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    onCloseModal();
    setSearchParams(scrubbedFilter);
  };

  const { connections } = useMe();
  return (
    <form className="" onSubmit={form.handleSubmit(onChangeFilter)}>
      <ComboBox
        label="Source Connection"
        options={connections}
        form={form}
        name="source_connection_id"
        labelKey="name"
        valueKey="id"
        imageKey="icon_url"
      />
      <ComboBox
        label="Destination Connection"
        options={connections}
        form={form}
        name="destination_connection_id"
        labelKey="name"
        valueKey="id"
        imageKey="icon_url"
      />
      <div className="mt-6 flex justify-space-between">
        <button
          onClick={onCloseModal}
          type="button"
          className="mr-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={isSubmitting}
          className="ml-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Filter
        </button>
      </div>
    </form>
  );
}

FilterForm.propTypes = {
  // isSubmitting: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
