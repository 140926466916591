import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { StreamLanguage } from '@codemirror/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import { EditorView } from 'codemirror';
import { displayInputError } from '../../../helpers/FormHelpers';
import FormShape from '../../../shapes/FormShape';
import Mustache from './Mustache';

export default function CodeInput({
  form, label, name, required, helperText, disabled,
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, ref, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <>
      <label htmlFor={name} className="mb-1 block text-sm font-medium text-gray-700 flex-0">
        {label}
      </label>
      <div className="mt-1 flex-1">
        <div className="min-h-max p-1 border border-gray-300 bg-white rounded-md">
          <CodeMirror
            extensions={[
              StreamLanguage.define(Mustache),
              EditorView.lineWrapping,
            ]}
            name={name}
            onChange={onChange}
            ref={ref}
            theme={githubLight}
            value={value || ''}
            editable={!disabled}
            minHeight="60px"
            basicSetup={{
              highlightActiveLine: false,
              bracketMatching: true,
            }}
          />
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-500">{helperText}</p>
      <p>{displayInputError(errors, name)}</p>
    </>
  );
}

CodeInput.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

CodeInput.defaultProps = {
  required: false,
  helperText: null,
  disabled: false,
};
