import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import TagsInput from 'react-tagsinput';
import { displayInputError } from '../../../helpers/FormHelpers';
import FormShape from '../../../shapes/FormShape';
import './style.css';

export default function InputBase({
  children,
  // disabled,
  // disabledBgColor,
  form,
  helperText,
  // inputClass,
  label,
  name,
  placeholder,
  required,
  // type,
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, onBlur, ref, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex">
        <TagsInput
          className="w-full"
          inputProps={{
            className: 'w-full border border-gray-300 rounded-md p-2 h-9 placeholder:text-sm',
            placeholder: placeholder || `${label}...`,
          }}
          addOnBlur
          value={value || []}
          onChange={onChange}
          
        />

        {/* <input
          type={type}
          disabled={disabled}
          className={classNames(
            inputClass,
            disabled ? (disabledBgColor || 'bg-gray-100') : null,
          )}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          value={value || ''}
          placeholder={placeholder}
        /> */}
        {children}
      </div>
      <span className="mt-2 text-sm text-gray-500">{helperText}</span>
      <p className="mt-2 text-sm text-red-500">{displayInputError(errors, name)}</p>
    </>
  );
}

InputBase.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  disabledBgColor: PropTypes.string,
};

InputBase.defaultProps = {
  inputClass: 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
  required: false,
  type: 'text',
  helperText: null,
  placeholder: null,
  disabled: false,
  children: null,
  disabledBgColor: null,
};
