/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import MetaShape from '../shapes/MetaShape';
import Pagination, { ReadableMetaDescription } from './Pagination';
import Loading from './Loading';
import RefetchingDescription from './RefetchingDescription';

export default function ItemsList({
  body,
  meta,
  header,
  mobileBody,
  isLoading,
  isRefetching,
}) {
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl">

      {/* Index page list (only on smallest breakpoint) */}
      {mobileBody}

      {/* Index page table (small breakpoint and up) */}
      {
          isLoading
            ? (<Loading forIndex />)
            : (
              <div className={classNames(mobileBody ? 'hidden sm:block' : null, 'mt-8')}>
                <div className="my-2">
                  {
                    isRefetching
                      ? (<RefetchingDescription />)
                      : (meta ? <ReadableMetaDescription meta={meta} /> : null)
                  }
                </div>
                {
                  meta
                    ? (
                      <div className="overflow-hidden rounded border border-gray-300">
                        <table className="min-w-full rounded-sm">
                          {header}
                          {body}
                        </table>
                        <Pagination meta={meta} />
                      </div>
                    )
                    : (
                      <section className="bg-white dark:bg-gray-900 border border-gray-300 rounded">
                        <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
                          <div className="mx-auto max-w-screen-sm text-center">
                            <h3 className="dark:text-primary-500 mb-4 text-5xl font-extrabold tracking-tight text-indigo-600 lg:text-9xl">500</h3>
                            <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl dark:text-white">Internal Server Error.</p>
                            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">What'd you do? Sorry something went wrong.</p>
                          </div>
                        </div>
                      </section>
                    )
                }
              </div>
            )
        }
    </div>
  );
}

ItemsList.propTypes = {
  body: PropTypes.element.isRequired,
  meta: MetaShape,
  header: PropTypes.element.isRequired,
  isLoading: PropTypes.bool.isRequired,
  mobileBody: PropTypes.element,
  isRefetching: PropTypes.bool,
};

ItemsList.defaultProps = {
  mobileBody: null,
  meta: null,
  isRefetching: null,
};
