import React from 'react';
import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip as Toooltip } from 'react-tooltip';
import classNames from 'classnames';

export default function Tooltip({
  className,
  content,
  id,
  tooltipPlace,
}) {
  return (
    <span className={classNames(className, 'flex')}>
      <Toooltip id={id} content={content} place={tooltipPlace} />
      <span data-tooltip-id={id}>
        <InformationCircleIcon className="text-indigo-600 h-4 w-4" />
      </span>
    </span>
  );
}

Tooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  id: PropTypes.string.isRequired,
  tooltipPlace: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  className: '',
};
