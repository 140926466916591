import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { EditorView } from 'codemirror';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FormShape from '../../shapes/FormShape';

export default function JSONSuperInput({ form, label }) {
  const {
    clearErrors,
    formState: { errors },
    setError,
  } = form;

  const format = (string) => JSON.stringify(string, null, '  ');

  const [localValue, setLocalValue] = useState(format(form.watch()));
  const onChangeValue = (formValue) => {
    setLocalValue(formValue);
  };

  const onBlur = () => {
    try {
      const updatedValue = JSON.parse(localValue);
      setLocalValue(format(updatedValue));
      form.reset(updatedValue);
      clearErrors('form');
    } catch {
      setLocalValue(localValue);
      form.reset('Invalid JSON');
      setError('form', { type: 'custom', message: 'Invalid JSON' });
    }
  };

  return (
    <div className="sm:col-span-6">
      <label htmlFor="form" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 min-h-max p-1 border rounded-md bg-white">
        <CodeMirror
          extensions={[
            json(),
            EditorView.lineWrapping,
          ]}
          name="form"
          onBlur={onBlur}
          onChange={onChangeValue}
          theme={githubLight}
          value={localValue}
          minHeight="60px"
          basicSetup={{
            highlightActiveLine: false,
            bracketMatching: true,
          }}
        />
      </div>
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {errors.form?.message}
        {localValue.error ? `Line ${localValue.error.line} - ${localValue.error.reason}.` : null}
      </p>
    </div>
  );
}

JSONSuperInput.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
};
