import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const getRandomValue = () => crypto.getRandomValues(new Uint32Array(2)).toString();

const FlashContext = createContext();
const { Provider } = FlashContext;

function FlashProvider({ children }) {
  const [flashes, setFlashes] = useState([]);

  const pushFlash = ({ primary, secondary, type }) => setFlashes([...flashes, {
    key: getRandomValue(), primary, secondary, type,
  }]);

  const removeFlash = (flash) => setFlashes(flashes.filter((toRemove) => toRemove !== flash));

  return (
    <Provider
      value={{
        pushFlash,
        flashes,
        removeFlash,
      }}
    >
      {children}
    </Provider>
  );
}

FlashProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { FlashContext, FlashProvider };
