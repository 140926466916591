export function scrub(parameters) {
  if (parameters === null) return null;
  const map = Object.entries(parameters)
    .map(([key, value]) => {
      if (Array.isArray(value)) return [key, value.map(scrub)];
      if (typeof value === 'object') return [key, scrub(value)];
      return [key, value];
    })
    .filter(([, value]) => value || value === false);

  return Object.fromEntries(map);
}

function makePath(key) {
  return key
    .split('[')
    .map((string) => {
      if (string.endsWith(']')) {
        return string.slice(0, -1);
      }
      return string;
    });
}

export function putIn(object, path, value) {
  if (path.length === 1) {
    if (Array.isArray(object)) {
      return [...object, value];
    }

    const [key] = path;
    return {
      ...object,
      [key]: value,
    };
  }

  const [key, ...rest] = path;

  // TODO: does not currently handle objects in arrays

  return {
    ...object,
    [key]: putIn(object[key] || {}, rest, value),
  };
}

export function fromSearchParams(parameters) {
  return Array.from(parameters).reduce((map, [key, value]) => {
    const path = makePath(key);

    return putIn(map, path, value);
  }, {});
}

export function toSearchParams(parameters, prefix = '', nested = false) {
  const preKey = nested ? '[' : '';
  const postKey = nested ? ']' : '';

  const map = Object.entries(scrub(parameters))
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) return value.map((arrayValue) => [`${prefix}${preKey}${key}${postKey}[]`, arrayValue]);
      if (typeof value === 'object') return Object.entries(toSearchParams(value, key, true));
      return [[`${prefix}${preKey}${key}${postKey}`, value]];
    });

  return Object.fromEntries(map);
}

export default {
  scrub,
};
