import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function NoInternetConnection({ children }) {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) return (children);
  return (<h1>No Internet Connection....</h1>);
}

export default NoInternetConnection;

NoInternetConnection.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
