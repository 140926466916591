import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '../../../../components/buttons/LinkButton';
import { formatDateTime } from '../../../../helpers/DateHelpers';
import LogShape from '../../../../shapes/LogShape';

export default function EvaluationsListBody({
  currentLog, logs, onClose, setEvaluationDisplay,
}) {
  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Log Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request body and response data for this action request.</p>
      </div>
      <div className="mx-4 mt-2 rounded border border-gray-300">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Evaluation ID
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Attempt Number
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Timestamp
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300 bg-white">
            {logs.map((log, idx) => (
              <tr key={log.id}>
                <td className="px-3 py-4 text-sm text-gray-500 flex">
                  {log.records.evaluation}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {idx + 1}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {formatDateTime(log.inserted_at)}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6">
                  {
                    log.id === currentLog.id
                      ? <p>Currently Displaying</p>
                      : (
                        <LinkButton
                          buttonText="Show This Tree"
                          onClick={() => setEvaluationDisplay({ [log.records.event_id]: log })}
                        />
                      )
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </div>
  );
}

EvaluationsListBody.propTypes = {
  logs: PropTypes.arrayOf(LogShape).isRequired,
  setEvaluationDisplay: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentLog: LogShape.isRequired,
};
