import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDateTime } from '../../../../helpers/DateHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import LogShape from '../../../../shapes/LogShape';

const responseColor = ({ http_status: statusCode, record_type: recordType }) => {
  let statusColor;
  if (statusCode >= 200 && statusCode < 299) statusColor = { bg: 'bg-teal-50', border: 'border-teal-500', text: 'text-teal-500' };
  if (statusCode >= 400 && statusCode < 499) statusColor = { bg: 'bg-yellow-50', border: 'border-yellow-500', text: 'text-yellow-500' };
  if (statusCode >= 500 || recordType === 'exception') statusColor = { bg: 'bg-red-50', border: 'border-red-500', text: 'text-red-500' };
  return statusColor;
};

export default function LogsTable({ logs, setCurrentLog }) {
  return (
    <div className="px-4">
      <div className="-mx-4 mt-2 lg:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-0">
                URL
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Response
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Timestamp
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 lg:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {logs.map((log) => (
              <tr key={log.id}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm text-gray-700 lg:w-auto lg:max-w-none lg:pl-0">
                  <span className="mr-1 font-semibold text-teal-600">{log.body.method}</span>
                  {log.body.url}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Response</dt>
                    <dd className="mt-1 truncate text-gray-700 pt-2 pb-1">
                      Response:
                      <span className={classNames(
                        `text-${responseColor(log.body)}-600`,
                        'whitespace-nowrap px-3 py-4 text-sm font-medium rounded-sm',
                      )}
                      >
                        <span className={`px-1 py-0.5 rounded border ${responseColor(log.body)?.border} ${responseColor(log.body)?.text}`}>
                          {log.body.http_status || log.body.record_type}
                        </span>
                      </span>
                    </dd>
                    <dt className="sr-only lg:hidden">Timestamp</dt>
                    <dd className="truncate text-gray-500 lg:hidden">
                      Timestamp:
                      {formatDateTime(log.inserted_at)}
                    </dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                  <span className={classNames(
                    `text-${responseColor(log.body)}-600`,
                    'whitespace-nowrap px-3 py-4 text-sm font-medium rounded-sm',
                  )}
                  >
                    <span className={`px-1 py-0.5 rounded border ${responseColor(log.body)?.border} ${responseColor(log.body)?.text}`}>
                      {log.body.http_status || log.body.record_type}
                    </span>
                  </span>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{formatDateTime(log.inserted_at)}</td>
                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium lg:pr-0">
                  <LinkButton buttonText="Details" onClick={() => setCurrentLog(log)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

LogsTable.propTypes = {
  logs: PropTypes.arrayOf(LogShape).isRequired,
  setCurrentLog: PropTypes.func.isRequired,
};
