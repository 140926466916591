/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function FormTypeButtons({ currentValue, onChange }) {
  const tabs = [
    { value: 'form', name: 'Form View' },
    { value: 'json', name: 'JSON View' },
  ];
  return (
    <div className="border-b mb-4 border-gray-200">
      <div className="sm:hidden">
        <label htmlFor="Status Filter" className="block text-sm font-medium text-gray-700">
          Filter by Status
        </label>
        <select
          id="current-tab"
          name="current-tab"
          onChange={(e) => onChange(e.target.value)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={currentValue}
        >
          {tabs.map((tab) => (
            <option key={tab.value} value={tab.value}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <button
              type="button"
              key={tab.name}
              onClick={() => onChange(tab.value)}
              className={classNames(
                tab.value === currentValue
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
              )}
              aria-current={tab.value === currentValue ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

FormTypeButtons.propTypes = {
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
