/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import LogShape from '../../../../../../shapes/LogShape';
import useMe from '../../../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../../../helpers/ConnectionHelpers';
import LinkButton from '../../../../../../components/buttons/LinkButton';

export default function ModalBody({ log, onClose }) {
  const { matchingActionLogs } = log;

  const { actions, workflows, connections } = useMe();
  const logActions = actions.filter((a) => log.body.candidate_ids.includes(a.id));
  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg leading-6 text-gray-900">Action Candidates</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          A list of all actions that would be evaluated to fire based on event topic.
        </p>
      </div>
      <div className="px-6">
        <ul className="divide-y divide-gray/5 px-4 border border-gray-300 rounded">
          {logActions.map((action) => {
            const sourceConnection = connections.find((c) => c.id === action.source_connection_id);
            const destinationConnection = connections.find((c) => c.id === action.destination_connection_id);
            const workflow = workflows.find((w) => w.id === action.workflow_id);
            const triggerLog = matchingActionLogs.find((l) => l.records.action_id === action.id);
            return (
              <li key={action.id} className="relative flex items-center space-x-4 py-2">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-center gap-x-3">
                    {/* <div className="text-rose-400 bg-rose-400/10 flex-none rounded-full p-1">
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div> */}
                    <span className="min-w-0 text-sm leading-6 text-gray-700 ml-16 ">
                      <span className="flex gap-x-2">
                        <span className="truncate">{workflow.name}</span>
                        <span className="text-gray-500">/</span>
                        <span className="whitespace-nowrap">{action.name || 'UNNAMED ACTION'}</span>
                        <span className="absolute inset-0" />
                      </span>
                    </span>
                  </div>
                  <div className="mt-1 flex items-center text-xs leading-5 text-gray-500">
                    <div className="w-20">
                      {
                        triggerLog && triggerLog
                          ? <span className="px-1 border border-teal-500 rounded-sm bg-teal-100">Triggered</span>
                          // : <span className="px-1 border border-gray-500 rounded-sm bg-gray-50">Filtered</span>
                          : null
                      }
                    </div>
                    <div className="mr-6">
                      <span className="truncate flex  text-gray-700">
                        <span className="mr-1 mt-0.5">
                          {connectionAvatar(sourceConnection, { size: 'xxs' })}
                        </span>
                        {sourceConnection.name}
                      </span>
                      <p className="truncate">{action.topic}</p>
                    </div>
                    <div className="border border-gray-300 rounded mr-6">
                      <ArrowRightIcon className="w-6 h-6" />
                    </div>
                    <div className="mr-6">
                      <span className="truncate flex  text-gray-700">
                        <span className="mr-1 mt-0.5">
                          {connectionAvatar(destinationConnection, { size: 'xxs' })}
                        </span>
                        {destinationConnection.name}
                      </span>
                      <span className="truncate flex">
                        <p className="text-indigo-700 mr-1">
                          {action.configuration.method.toUpperCase()}
                        </p>
                        {action.configuration.url.replace('{{destination_connection.configuration.url}}', '')}
                        {log.requestLogs ? log.requestLogs[0].body.http_status : null}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
                className={classNames(
                  environments[deployment.environment],
                  'rounded flex-none py-1 px-2 text-xs ring-1 ring-inset',
                )}
              >
                {deployment.environment}
              </div> */}
                <LinkButton buttonText="Details" path={`/actions/${action.id}`} />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </div>
  );
}

ModalBody.propTypes = {
  log: LogShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
