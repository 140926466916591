import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useClient from '../../../hooks/useClient';
import Form from '../Form';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Loading from '../../../components/Loading';
import ActionShape from '../../../shapes/ActionShape';

export default function ActionEditPage() {
  const { id } = useParams();
  const { data: action } = useClient('actions').get(id);

  if (!action) return <Loading />;
  return (<ActionEditPageForm action={action} />);
}

function ActionEditPageForm({ action }) {
  const form = useForm({ defaultValues: action });
  const client = useClient('actions');
  const navigate = useNavigate();

  useEffect(() => {
    if (form.watch('trigger_type') === 'event') {
      form.setValue('schedule', null);
    } else {
      form.setValue('topic', null);
    }
  }, [form.watch('trigger_type')]);

  const { isLoading: isSubmitting, mutate } = client.update(action.id, {
    onSuccess: () => navigate(`/actions/${action.id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });
  const onSubmit = form.handleSubmit((a) => mutate({ action: a }));

  return (
    <Form
      description="Edit an existing action."
      form={form}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      title="Edit Action Form"
    />
  );
}

ActionEditPageForm.propTypes = {
  action: ActionShape.isRequired,
};
