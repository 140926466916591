/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import LogShape from '../../../../shapes/LogShape';
import JsonDisplay from '../../../../components/JsonDisplay';

export default function LogBody({ log, onClose }) {
  if (!log) return null;
  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Log Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request body and response data for this action request.</p>
      </div>
      <JsonDisplay valueObject={log} />
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </div>
  );
}

LogBody.propTypes = {
  onClose: PropTypes.func.isRequired,
  log: LogShape,
};

LogBody.defaultProps = {
  log: null,
};
