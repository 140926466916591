/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import useMe from '../../../../context/CurrentUserContext';
import WorkflowShape from '../../../../shapes/WorkflowShape';
import ScheduledWorkflowTrigger from './SheduleWorkflowTrigger';
import EventWorkflowTrigger from './EventWorkflowTrigger';
import LogShape from '../../../../shapes/LogShape';
import ErrorBoundary from '../../../../components/ErrorBoundary';

export default function Workflow({ workflow, threadLogs }) {
  const { actions } = useMe();
  const workflowActions = actions.filter((a) => workflow.id === a.workflow_id)
  const callbackTopics = _.compact(workflowActions.map((a) => a.after_action?.topic));
  const entryLevelActions = workflowActions.filter((a) => !!a.schedule || !callbackTopics.includes(a.topic));
  const eventTriggers = entryLevelActions.filter((a) => !!a.topic);
  const topicSourceCombos = eventTriggers.map((c) => ({ topic: c.topic, source_connection_id: c.source_connection_id }));
  const uniqEventTriggers = _.uniqWith(topicSourceCombos, _.isEqual);
  const scheduledTriggers = entryLevelActions.filter((a) => !!a.schedule);

  return (
    <ErrorBoundary>
      <ul className="space-y-6 w-full p-3 pt-0 rounded">
        {
          scheduledTriggers.map((a) => (
            <ScheduledWorkflowTrigger
              key={`trigger-${a.id}`}
              action={a}
              workflowActions={workflowActions}
              threadLogs={threadLogs}
            />
          ))
        }
        {
          uniqEventTriggers.map((t) => (
            <EventWorkflowTrigger
              key={`trigger-${t.topic}-${t.source_connection_id}`}
              trigger={t}
              workflowActions={workflowActions}
              threadLogs={threadLogs}
            />
          ))
        }
      </ul>
    </ErrorBoundary>
  );
}

Workflow.propTypes = {
  workflow: WorkflowShape.isRequired,
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};
