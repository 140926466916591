import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import LinkButton from '../../../../../components/buttons/LinkButton';
import WorkflowShape from '../../../../../shapes/WorkflowShape';

export default function EmptyState({ workflow }) {
  return (
    <div className="text-center">
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        No actions
      </h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new Action.</p>
      <div className="mt-6">
        <LinkButton
          path={`/actions/new?workflow_id=${workflow.id}`}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          buttonText={(
            <span className="flex">
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              <p>New Action</p>
            </span>
        )}
        />
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  workflow: WorkflowShape.isRequired,
};
