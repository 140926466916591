export function formatId(uuid) {
  return uuid.split('-').slice(-1);
}

// this is a reimplimentation of the server side method for action evaluation.
export function isTruthy(value) {
  // booleans
  if (value === true) return true;
  // numbers
  if (value === 0) return false;
  if (typeof value === 'number') return true;
  // strings
  if (value === '') return false;
  if (typeof value === 'string') return true;

  return false;
}

export function latest(records) {
  const reducer = (currentLatest, record) => {
    if (!currentLatest) return record;
    return currentLatest.inserted_at > record.inserted_at ? currentLatest : record;
  };

  return records.reduce(reducer, null);
}

export default {
  formatId,
  isTruthy,
  latest,
};
