import React, { useState } from 'react';
import LogShape from '../../../../../../shapes/LogShape';
import LinkButton from '../../../../../../components/buttons/LinkButton';
import Modal from '../../../../../../components/Modal';
import ModalBody from './ModalBody';
import { formatId } from '../../../../../../helpers/RecordHelpers';

export default function EventEvaluatedSubLine({ log }) {
  const { matchingActionLogs } = log;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={open}
        onClose={() => setOpen(false)}
      >
        <ModalBody log={log} onClose={() => setOpen(false)} />
      </Modal>
      <span className="font-medium text-gray-500 ml-3">
        <span className="flex space-x-1 md:flex">
          <p>Evaluation: </p>
          <p className="bg-gray-100 border border-gray-300 rounded-sm text-xs p-0 m-0.5 px-1">
            {formatId(log.records.evaluation)}
          </p>
        </span>
        <span className="flex space-x-1 md:flex">
          <p>Actions Triggered:</p>
          {
            log.body.candidate_ids?.length
              ? (
                <LinkButton
                  className="font-medium text-xs"
                  buttonText={`${matchingActionLogs.length}/${log.body.candidate_ids?.length} Action${log.body.candidate_ids?.length !== 1 ? 's' : ''}`}
                  onClick={() => setOpen(true)}
                />
              )
              : (
                <p>
                  {log.body.candidates || 0}
                  {' '}
                  Action
                  {log.body.candidates !== 1 ? 's' : ''}
                </p>
              )
          }
        </span>
      </span>
    </>
  );
}

EventEvaluatedSubLine.propTypes = {
  log: LogShape.isRequired,
};
