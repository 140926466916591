import React from 'react';
import PropTypes from 'prop-types';
import { BoltIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import activity from 'react-useanimations/lib/activity';
import _ from 'lodash';
import EventShape from '../../../shapes/EventShape';
import LoadingSpinner from '../../../components/LoadingSpinner';
import LogShape from '../../../shapes/LogShape';

export default function StatusIcon({ event, events, threadLogs }) {
  const eventStatusList = events?.map((e) => e.status) || [];
  const hasEventPending = eventStatusList.includes('pending');
  const actionProcessedLogs = threadLogs.filter((l) => l.topic === 'action.processed');
  const failures = actionProcessedLogs.filter((l) => l.status === 'error');
  const failuresWithoutEventualSuccess = [];

  failures.forEach((l) => {
    const successResult = actionProcessedLogs.find((pl) => pl.records.action_id === l.records.action_id && pl.status === 'success');
    if (!successResult) failuresWithoutEventualSuccess.push(l.records.action_id);
    return null;
  });
  const uniqFailuresWithoutEventualSuccess = _.uniq(failuresWithoutEventualSuccess);

  if (uniqFailuresWithoutEventualSuccess.length) {
    return <ExclamationTriangleIcon className="h-4 w-4 text-red-500 mt-1" />;
  }

  if (hasEventPending) {
    return <LoadingSpinner animation={activity} strokeColor="#5F9EA0" />;
  }

  if (eventStatusList.length === 1 && eventStatusList[0] === 'resolved') {
    return <BoltIcon className="h-4 w-4 text-indigo-700 mt-1" />;
  }

  return null;
}

StatusIcon.propTypes = {
  events: PropTypes.arrayOf(EventShape).isRequired,
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};
