/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import useClient from '../hooks/useClient';
import useAuth from './AuthContext';

const CurrentUserContext = createContext();
const { Provider } = CurrentUserContext;

export function CurrentUserProvider({ children }) {
  const { accessToken } = useAuth();
  const config = {
    retry: false,
    enabled: !!accessToken,
  };

  const { data: currentUser, isLoading } = useClient('users').fetchMe(config);
  const { data: connections = [], isLoading: isLoadingConnections } = useClient('connections').list({ template: false, page_size: 100 }, config);
  const { data: connectionTemplates = [], isLoading: isLoadingConnectionTemplatess } = useClient('connections').list({ template: true, page_size: 100 }, config);
  const { data: actions = [], isFetching: isLoadingActions } = useClient('actions').list({ page_size: 100, template: false }, config);
  const { data: workflows = [], isFetching: isLoadingWorkflows } = useClient('workflows').list({ page_size: 100, template: false }, config);

  if (
    !!accessToken
    && isLoading
    && isLoadingActions
    && isLoadingConnections
    && isLoadingWorkflows
  ) return null;

  return (
    <Provider
      value={{
        allConnections: [...connections, ...connectionTemplates],
        currentUser: currentUser?.user,
        currentEntity: currentUser?.entity,
        connections,
        actionTemplates: [],
        connectionTemplates,
        actions,
        workflows,
      }}
    >
      {children}
    </Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function CurrentUserConsumer() {
  return useContext(CurrentUserContext);
}
