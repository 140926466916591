import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ConnectionShape from '../../../shapes/ConnectionShape';
import ConnectionTemplateShape from '../../../shapes/ConnectionTemplateShape';
import JsonDisplay from '../../../components/JsonDisplay';
import { formatDateTime } from '../../../helpers/DateHelpers';

export default function InfoCard({ connection, connectionTemplate }) {
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              General Information
            </h2>
          </div>
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 mb-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{connection.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Connection Template</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {connectionTemplate?.name || 'N/A'}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Connection Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {connection.api ? 'Web App' : null}
                  {connection.database ? 'Database' : null}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Last Update</dt>
                <dd className="mt-1 text-sm text-gray-900">{formatDateTime(connection.updated_at)}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Description</dt>
                <dd className="mt-1 text-sm text-gray-900">{connection.description || 'N/A'}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {connection.api?.url ? 'API URL' : null }
                  {connection.database?.url ? 'DB URL' : null }
                  {!connection.database?.url && connection.api?.url ? 'Base URL' : null }
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{connection.api?.url || connection.database?.url}</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Docs URL</dt>
                <dd className="mt-1 text-sm text-gray-900">{connection.documentation_url || 'N/A'}</dd>
              </div>
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">Metadata</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <JsonDisplay valueObject={connection.metadata} />
                </dd>
              </div>
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">Variables</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <JsonDisplay valueObject={connection.variables} />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

InfoCard.propTypes = {
  connection: ConnectionShape.isRequired,
  connectionTemplate: ConnectionTemplateShape,
};

InfoCard.defaultProps = {
  connectionTemplate: null,
};
