import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import EditPageForm from './Form';

export default function EditPage() {
  const { id } = useParams();
  const client = useClient('events');
  const { data: event, isLoading: isLoadingEvent } = client.get(id);
  const { data: connection, isLoadingConnection } = useClient('connections').get(
    event?.connection_id,
    { enabled: !!event?.connection_id },
  );
  if ((isLoadingEvent || isLoadingConnection) && !event) return <Loading />;

  return (
    <EditPageForm event={event} connection={connection} />
  );
}
