import PropTypes from 'prop-types';

export const QueryShape = PropTypes.shape({
  record_type: PropTypes.oneOf(['query']),
  body: PropTypes.string,
});

export const RequestShape = PropTypes.shape({
  record_type: PropTypes.oneOf(['request']),
  body: PropTypes.string,
  content_type: PropTypes.string,
  method: PropTypes.oneOf(['post', 'put', 'patch', 'get']).isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.shape(),
});

export default PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  alias: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.object,
  configuration: PropTypes.oneOfType([QueryShape, RequestShape]),
  action_template_id: PropTypes.string,
  destination_connection_id: PropTypes.string,
  id: PropTypes.string.isRequired,
  inserted_at: PropTypes.string.isRequired,
  matcher: PropTypes.string.isRequired,
  record_type: PropTypes.oneOf(['action']),
  source_connection_id: PropTypes.string,
  task_id: PropTypes.string,
  topic: PropTypes.string,
  workflow_id: PropTypes.string,
  updated_at: PropTypes.string.isRequired,
});
