import PropTypes from 'prop-types';
import ActionShape from './ActionShape';

export default PropTypes.shape({
  actions: PropTypes.arrayOf(ActionShape),
  id: PropTypes.string.isRequired,
  inserted_at: PropTypes.string.isRequired,
  record_type: PropTypes.oneOf(['workflow']),
  updated_at: PropTypes.string.isRequired,
});
