export const apiURL = import.meta.env.VITE_API_URL;
export const websocketURL = import.meta.env.VITE_WEBSOCKET_URL;
export const enableLogRocket = import.meta.env.VITE_ENABLE_LOG_ROCKET;
export const enableSentry = import.meta.env.VITE_ENABLE_SENTRY;

export default {
  apiURL,
  enableLogRocket,
  enableSentry,
  websocketURL,
};
