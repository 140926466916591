import classNames from 'classnames/bind';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import MetaShape from '../shapes/MetaShape';

export function ReadableMetaDescription({ meta }) {
  const { page, page_size: pageSize, total_entries: totalEntries } = meta;
  const firstEntry = ((page - 1) * pageSize) + 1;
  const lastEntry = totalEntries < pageSize ? totalEntries : pageSize * page;
  if (totalEntries === 0) {
    return (
      <p className="text-sm text-gray-700">
        There are no results that match this filter.
      </p>
    );
  }
  return (
    <div className="hidden sm:block">
      <p className="text-sm text-gray-700">
        Showing
        {' '}
        <span className="font-medium">{firstEntry.toLocaleString()}</span>
        {' '}
        to
        {' '}
        <span className="font-medium">{lastEntry < totalEntries ? lastEntry.toLocaleString() : totalEntries.toLocaleString()}</span>
        {' '}
        of
        {' '}
        <span className="font-medium">{totalEntries.toLocaleString()}</span>
        {' '}
        results.
      </p>
    </div>
  );
}

ReadableMetaDescription.propTypes = {
  meta: MetaShape.isRequired,
};

export default function Pagination({ meta }) {
  const {
    page,
    total_pages: totalPages,
    total_entries: totalEntries,
    page_size: pageSize,
  } = meta;
  const [searchParams, setSearchParams] = useSearchParams();
  const setPage = (number) => {
    const urlParamsObj = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
      urlParamsObj[key] = searchParams.get(key);
    }
    setSearchParams({
      ...urlParamsObj,
      page: number,
    });
  };
  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);
  const isNextPage = page < totalPages;
  const isPreviousPage = page > 1;

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-300 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <ReadableMetaDescription meta={meta} />
      {
        totalEntries > pageSize
          ? (
            <div className="flex flex-1 justify-between sm:justify-end">
              <button
                type="button"
                disabled={!isPreviousPage}
                className={classNames('relative inline-flex items-center rounded border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700', isPreviousPage ? 'hover:bg-gray-50' : '')}
                onClick={previousPage}
              >
                Previous
              </button>
              <button
                type="button"
                disabled={!isNextPage}
                className={classNames('relative ml-3 inline-flex items-center rounded border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700', isNextPage ? 'hover:bg-gray-50' : '')}
                onClick={nextPage}
              >
                Next
              </button>
            </div>
          )
          : null
      }
    </nav>
  );
}

Pagination.propTypes = {
  meta: MetaShape.isRequired,
};
