import React from 'react';
import _ from 'lodash';
import renderHandlebars from './renderHandlebars';

export function renderTemplate(template, records) {
  try {
    const [rendered] = renderHandlebars({ templates: [template], records });

    return rendered;
  } catch (error) {
    return template;
  }
}

export function listTemplateVariables(template) {
  // eslint-disable-next-line no-useless-escape
  return template?.match(/(?<=\{\{).*?(?=\}\})/g);// ?.map((x) => x.match(/[\w\.]+/)[0]);
}

export function extractValue(keyPath, event, connection, extractConfig) {
  const isConnectionValue = keyPath.startsWith('destination_connection.');
  const isEventValue = keyPath.startsWith('event.');
  // const firstWord = keyPath.match(/(?:^|(?:[.!?]\s))(\w+)/g);
  // const isFunction = firstWord === keyPath;
  let record;
  if (isConnectionValue && extractConfig?.connection !== false) {
    record = connection;
  } else if (isEventValue && extractConfig?.event !== false) {
    record = event;
  } else if (extractConfig?.function !== false) {
    try {
      const [rendered] = renderHandlebars({ templates: [`{{${keyPath}}}`], records: {} });
      record = rendered;
    } catch {
      // do nothing
    }
  }

  const hasRecordToExtractFrom = !!Object.keys(record || {}).length;
  try {
    const value = keyPath.split('.').slice(1).reduce((o, i) => o[i], record);
    const returnedVal = hasRecordToExtractFrom ? value : undefined;
    return { keyPath, value: returnedVal, hasRecordToExtractFrom };
  } catch (err) {
    return { keyPath, value: undefined };
  }
}

// This is a "Temp" solution until we can get time to make the path input a Codemirror input
export function fieldPreview(form, templateField, event, connection) {
  const preview = form.watch(`alias.${templateField.name}`);

  // Generate a list of all template variables
  const list = event && listTemplateVariables(form.watch(`alias.${templateField.name}`));
  if (!list) return preview;

  // Take our list of template variables and fetch the value
  const valueList = list?.map((v) => extractValue(v, event, connection) || []);

  const previewArr = [];
  valueList.forEach(({ keyPath, value }) => {
    const arr = preview.split((`{{${keyPath}}}`));

    if (arr[0]) previewArr.push(<span key={`${keyPath}.start`}>{arr[0]}</span>);
    const className = value === undefined ? 'text-red-700 bg-red-50' : 'text-yellow-600';
    if (typeof value === 'string' || value === undefined) {
      previewArr.push(<span key={`${keyPath}.value`} className={className}>{value === undefined ? 'undefined' : value}</span>);
    } else {
      previewArr.push(<span key={`${keyPath}.isObj`}>{`{{${keyPath}.___}}`}</span>);
    }

    if (arr[1]) previewArr.push(<span key={`${keyPath}.end`}>{arr[1]}</span>);
  });

  return previewArr;
}

const labelDefaults = {
  url: 'URL',
};

export const labelCase = (labelKey) => {
  if (labelDefaults[labelKey]) return labelDefaults[labelKey];
  return _.startCase(labelKey);
};
