import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import LinkButton from './LinkButton';

export default function CopyButton({ value, copiedText, onCopyCallback }) {
  return (
    <CopyToClipboard text={value} onCopy={onCopyCallback}>
      <LinkButton
        buttonText={
          value === copiedText
            ? <ClipboardDocumentCheckIcon className="h-3 w-3" />
            : <ClipboardDocumentIcon className="h-3 w-3" />
        }
      />
    </CopyToClipboard>
  );
}

CopyButton.propTypes = {
  copiedText: PropTypes.string,
  onCopyCallback: PropTypes.func,
  value: PropTypes.string.isRequired,
};

CopyButton.defaultProps = {
  copiedText: null,
  onCopyCallback: null,
};
