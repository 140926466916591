import PropTypes from 'prop-types';
import React from 'react';
import EmptyState from './EmptyState';
import ActionShape from '../../../../../shapes/ActionShape';
import ShowPageSectionCard from '../../../../../components/layout/ShowPage/SectionCard';
import Workflow from '../../../IndexPage/Workflow';
import WorkflowShape from '../../../../../shapes/WorkflowShape';

export default function ActionList({ actions, workflow }) {
  return (
    <ShowPageSectionCard
      title="Actions List"
      subTitle="A list of all actions for this workflow"
    >
      {
        actions.length
          ? <Workflow workflowActions={actions} />
          : <EmptyState workflow={workflow} />
      }
    </ShowPageSectionCard>
  );
}

ActionList.propTypes = {
  actions: PropTypes.arrayOf(ActionShape).isRequired,
  workflow: WorkflowShape.isRequired,
};
