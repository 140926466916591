import React from 'react';
import ReactJson from 'react-json-view';
import EventShape from '../../../shapes/EventShape';
import ShowPageSectionCard from '../../../components/layout/ShowPage/SectionCard';

export default function InfoCard({ event }) {
  return (
    <ShowPageSectionCard
      title="Event Payload"
      // subTitle="General details about this event"
    >
      <ReactJson src={event.payload} name={false} collapsed={1} />
    </ShowPageSectionCard>
  );
}

InfoCard.propTypes = {
  event: EventShape.isRequired,
};
