import React from 'react';
import JsonDisplay from '../../../../components/JsonDisplay';
import ActionShape from '../../../../shapes/ActionShape';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { formatDateTime } from '../../../../helpers/DateHelpers';

export default function General({ action }) {
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              General Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">General details about this action.</p>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 m-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{action.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Created On</dt>
              <dd className="mt-1 text-sm text-gray-900">{formatDateTime(action.inserted_at)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Action Template</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {
                  action.actionTemplate?.name
                    ? `${action.actionTemplate?.name}`
                    : 'N/A'
                }
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Workflow</dt>
              <dd className="mt-1 text-sm text-gray-900">{action.workflow?.name}</dd>
            </div>
            <div className="col-span-2">
              <dt className="text-sm font-medium text-gray-500">Metadata</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <JsonDisplay valueObject={action.metadata} />
              </dd>
            </div>
          </dl>
        </div>
      </ErrorBoundary>
    </section>
  );
}

General.propTypes = {
  action: ActionShape.isRequired,
};
