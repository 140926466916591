/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Input from '../../../../components/forms/InputBase';
import FormShape from '../../../../shapes/FormShape';
import RadioInput from './ExtractionTypeInput';

export default function FormBody({ form }) {
  const displayName = () => {
    if (form.watch('webhook.topic.type') === 'header') return 'Header Key Name';
    if (form.watch('webhook.topic.type') === 'parameter') return 'Body Key Name';
    return 'Static Topic';
  };

  const displayHelperText = () => {
    if (form.watch('webhook.topic.type') === 'header') {
      return 'Enter the name of the Header key where this topic would be found.';
    }
    if (form.watch('webhook.topic.type') === 'parameter') {
      return 'Enter the name of the Body key where this topic would be found.';
    }
    return 'This topic will be assigned to every Event generated by this Connection.';
  };

  return (
    <div className="flex flex-col space-y-6">
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-2">
        <div className="col-span-2">
          <RadioInput
            form={form}
            label="Extraction Type"
            name="webhook.topic.type"
            options={[
              { value: 'header', title: 'Header Key + Value', description: 'Topic will be dynamic and extracted from webhook header.' },
              { value: 'parameter', title: 'Body Key + Value', description: 'Topic will be dynamic and extracted from webhook body.' },
              { value: 'constant', title: 'Constant', description: 'Topic will be the same for each event from this connection.' },
            ]}
          />
        </div>
      </div>
      {
        form.watch('webhook.topic.type')
          ? (
            <>
              {' '}
              <div className="w-full md:w-3/4 lg:1/2">
                <Input
                  label={displayName()}
                  placeholder={`${displayName()}...`}
                  name="webhook.topic.name"
                  form={form}
                  helperText={displayHelperText()}
                />
              </div>
              {
                form.watch('webhook.topic.type') !== 'constant'
                  ? (
                    <div className="w-full md:w-3/4 lg:1/2">
                      <Input
                        label="Extraction Regex"
                        placeholder="Value Regex..."
                        name="webhook.topic.pattern"
                        form={form}
                        helperText="An optional regex to extract this topic if combined with other data."
                      />
                    </div>
                  ) : null
              }

            </>
          )
          : null
      }

    </div>
  );
}

FormBody.propTypes = {
  form: FormShape.isRequired,
};
