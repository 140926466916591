import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import WebhookActionSubForm from './WebhookActionSubForm';
import Header from '../../../components/layout/PageHeader';
import FormShape from '../../../shapes/FormShape';
import TriggerSubForm from './TriggerSubForm';
import NameAndWorkflowSubForm from './NameAndWorkflowSubForm';
import EventShape from '../../../shapes/EventShape';
import Footer from '../../../components/forms/Footer';

export default function Form({
  title,
  description,
  form,
  isSubmitting,
  onSubmit,
  exampleEvent,
}) {
  const navigate = useNavigate();
  return (
    <form onSubmit={onSubmit}>
      <Header
        name={title}
        description={description}
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <NameAndWorkflowSubForm form={form} />
      <TriggerSubForm form={form} />
      <WebhookActionSubForm
        form={form}
        exampleEvent={exampleEvent}
      >
        <Footer
          form={form}
          backButtonText="Back"
          onBackButtonClick={() => navigate(-1)}
          submitButtonText="Submit"
          submitButtonDisabled={isSubmitting}
          submitButtonType="submit"
        />
      </WebhookActionSubForm>
    </form>
  );
}

Form.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  form: FormShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  exampleEvent: EventShape,
};

Form.defaultProps = {
  exampleEvent: null,
};
