/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import ActionShape from '../../../../shapes/ActionShape';
import ActionTemplateShape from '../../../../shapes/ActionTemplateShape';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import EventShape from '../../../../shapes/EventShape';
import FormShape from '../../../../shapes/FormShape';
import AliasInputCard from './AliasInputCard';
import BodyPreviewCard from './BodyPreviewCard';
import BodyInputCard from './BodyInputCard';
import MethodPathCard from './MethodPathCard';
import CallbackEvent from './CallbackEvent';
import ComboBox from '../../../../components/forms/ComboBox';
import useMe from '../../../../context/CurrentUserContext';

export default function FormBody({
  action,
  actionTemplate,
  event,
  form,
  destinationConnection,
  sourceConnection,
}) {
  const [displayAliasInput, setDisplayAliasInput] = useState(true);
  const body = form.watch('configuration')?.body || '';
  const displayPreview = body.includes('{{');
  const { connections } = useMe();

  const bodyInput = (action || actionTemplate)?.alias_form?.length && displayAliasInput
    ? (
      <AliasInputCard
        action={action}
        actionTemplate={actionTemplate}
        event={event}
        destinationConnection={destinationConnection}
        form={form}
        onChangeToJsonInput={() => setDisplayAliasInput(false)}
      />
    )
    : (
      <BodyInputCard
        event={event}
        destinationConnection={destinationConnection}
        form={form}
        onChangeToAliasInput={() => setDisplayAliasInput(true)}
        displayToggleAlias={!!(action || actionTemplate)?.alias_form?.length}
      />
    );

  return (
    <>
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Webhook Information</h3>
        <p className="mt-1 text-sm text-gray-500">Add URL and JSON Body for this Action.</p>
      </div>
      <div className="items-center justify-between w-full pt-4">
        <ComboBox
          form={form}
          label="Request Destination"
          name="destination_connection_id"
          options={connections}
          valueKey="id"
          labelKey="name"
          imageKey="icon_url"
          required
        />
        <MethodPathCard event={event} form={form} destinationConnection={destinationConnection} />
        {
          form.watch('configuration')?.method && form.watch('configuration').method !== 'get'
            ? (bodyInput)
            : null
        }
        {
          displayPreview
            ? (
              <BodyPreviewCard
                destinationConnection={destinationConnection}
                event={event}
                form={form}
                sourceConnection={sourceConnection}
              />
            )
            : null
        }
      </div>
      <div className="items-center justify-between w-full">
        <CallbackEvent form={form} />
      </div>
    </>
  );
}

FormBody.propTypes = {
  action: ActionShape,
  actionTemplate: ActionTemplateShape,
  event: EventShape,
  form: FormShape.isRequired,
  destinationConnection: ConnectionShape,
  sourceConnection: ConnectionShape,
};

FormBody.defaultProps = {
  action: null,
  actionTemplate: null,
  event: null,
  destinationConnection: null,
  sourceConnection: null,
};
