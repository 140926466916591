/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import cronstrue from 'cronstrue';
import Preview from './Preview';
import FormShape from '../../../../shapes/FormShape';
import LinkButton from '../../../../components/buttons/LinkButton';
import useMe from '../../../../context/CurrentUserContext';

export default function PreviewCard({ form }) {
  const { connections } = useMe();
  const sourceConnection = connections?.find((c) => c.id === form.watch('source_connection_id'));
  const destinationConnection = connections?.find((c) => c.id === form.watch('destination_connection_id'));
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
        <div className="space-y-6 divide-y divide-gray-200">
          <div className="py-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Data Flow Preview</h3>
              <span className="mt-1 text-sm text-gray-500">
                {
                  form.watch('topic')
                    ? (
                      <>
                        When a(n)
                        {' '}
                        <span className="font-medium text-gray-600">
                          {form.watch('topic')}
                        </span>
                        {' '}
                        Event happens in
                        {' '}
                        <LinkButton
                          buttonText={sourceConnection?.name}
                          path={`/connections/${sourceConnection?.id}`}
                        />
                      </>
                    )
                    : form.watch('schedule') ? cronstrue.toString(form.watch('schedule')) : null
                }
                , send an
                {' '}
                <span className="font-medium text-gray-600">
                  API Request
                </span>
                {' '}
                to
                {' '}
                <LinkButton buttonText={destinationConnection?.name} path={`/connections/${destinationConnection?.id}`} />
                .
              </span>
            </div>
            <Preview
              action={form.watch()}
              isEditMode
              actionDestination={destinationConnection}
              sourceConnection={sourceConnection}
              onReset={() => null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

PreviewCard.propTypes = {
  form: FormShape.isRequired,
};
