/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue';
import _ from 'lodash';
import { ClockIcon } from '@heroicons/react/24/outline';
import useMe from '../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import ActionsList from './ActionsList';
import UrlPreview from '../../../../components/UrlPreview';
import ActionShape from '../../../../shapes/ActionShape';

function ScheduledWorkflowTrigger({ action, workflowActions }) {
  const { connections } = useMe();
  const destinationCxn = connections.find(({ id }) => id === action.destination_connection_id);
  const callbackActions = workflowActions.filter((a) => action.after_action?.topic === a.topic);

  return (
    <li key={action.id} className="w-fit">
      <div className="border border-gray-300 rounded bg-50 p-2 text-xs">
        <span className="flex space-x-1 text-sm">
          <span className="text-gray-700 font-medium flex">
            <ClockIcon className="m-0.5 w-4 h-4 border border-gray-300 rounded text-indigo-700" />
          </span>
          <p className="font-normal text-gray-500 mr-1">
            Poll
            {' '}
            <LinkButton className="text-sm" buttonText={destinationCxn.name} path={`/connections/${destinationCxn.id}`} />
          </p>
          <p>{cronstrue.toString(action.schedule)}</p>
          <p className="font-normal text-gray-500 mr-1">for data.</p>
        </span>
        <div className="w-full mt-1 px-2">
          <div className="flex">
            <p className="mr-1">Action:</p>
            <LinkButton buttonText={action.name} className="text-xs mb-1" path={`/actions/${action.id}`} />
          </div>
          <div className="text-xs flex">
            <span className="mr-1">
              {connectionAvatar(destinationCxn, { size: 'xxs' })}
            </span>
            <UrlPreview
              method={action.configuration.method}
              url={action.configuration.url}
              connection={destinationCxn}
              extractConfig={{ event: false, function: false }}
            />
          </div>
        </div>
      </div>
      {
        callbackActions.length
          ? <ActionsList callbackActions={callbackActions} workflowActions={workflowActions} />
          : null
      }
    </li>
  );
}

ScheduledWorkflowTrigger.propTypes = {
  action: ActionShape.isRequired,
  workflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
};

function EventWorkflowTrigger({ trigger, workflowActions }) {
  const { connections } = useMe();
  const sourceCxn = connections.find(({ id }) => id === trigger.source_connection_id);
  const callbackActions = workflowActions.filter((a) => a.topic === trigger.topic);

  return (
    <li key={`trigger-${trigger.topic}-${trigger.source_connection_id}`} className="w-fit">
      <div className="border border-gray-300 rounded bg-50 p-2 text-xs">
        <span className="flex space-x-1">
          <span>
            {connectionAvatar(sourceCxn, { size: 'xxs' })}
          </span>
          <p className="font-normal text-gray-500 mr-1">When an</p>
          <p>{trigger.topic}</p>
          <p className="font-normal text-gray-500 mr-1">event occurs in</p>
          <LinkButton className="text-xs" buttonText={sourceCxn.name} path={`/connections/${sourceCxn.id}`} />
        </span>
      </div>
      {
        callbackActions.length
          ? <ActionsList callbackActions={callbackActions} workflowActions={workflowActions} />
          : null
      }
    </li>
  );
}

EventWorkflowTrigger.propTypes = {
  trigger: PropTypes.shape({
    topic: PropTypes.string.isRequired,
    source_connection_id: PropTypes.string.isRequired,
  }).isRequired,
  workflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
};

export default function Workflow({ workflowActions }) {
  const callbackTopics = _.compact(workflowActions.map((a) => a.after_action?.topic));
  const entryLevelActions = workflowActions.filter((a) => !!a.schedule || !callbackTopics.includes(a.topic));
  const eventTriggers = entryLevelActions.filter((a) => !!a.topic);
  const topicSourceCombos = eventTriggers.map((c) => ({ topic: c.topic, source_connection_id: c.source_connection_id }));
  const uniqEventTriggers = _.uniqWith(topicSourceCombos, _.isEqual);
  const scheduledTriggers = entryLevelActions.filter((a) => !!a.schedule);

  return (
    <tr className="border-none">
      <td colSpan={5} className="w-full max-w-0 whitespace-nowrap px-6 pb-3 text-sm font-medium text-gray-900">
        <ul className="space-y-6 w-full p-3 pt-0">
          {
            scheduledTriggers.map((a) => (
              <ScheduledWorkflowTrigger
                key={`trigger-${a.id}`}
                action={a}
                workflowActions={workflowActions}
              />
            ))
          }
          {
            uniqEventTriggers.map((t) => (
              <EventWorkflowTrigger
                key={`trigger-${t.topic}-${t.source_connection_id}`}
                trigger={t}
                workflowActions={workflowActions}
              />
            ))
          }
        </ul>
      </td>
    </tr>
  );
}

Workflow.propTypes = {
  workflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
};
