import React from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import AlertBanner from './components/layout/FlashBanner';
import SideBarLayout from './components/layout/SideBar';
import NoInternetConnection from './components/NoInternetConnection';

export default function App() {
  return (
    <SideBarLayout>
      <NoInternetConnection>
        <AlertBanner />
        <Outlet />
      </NoInternetConnection>
    </SideBarLayout>
  );
}
