/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/forms/InputBase';
import FormShape from '../../../../shapes/FormShape';
import ComboBox from '../../../../components/forms/ComboBox';
import useMe from '../../../../context/CurrentUserContext';
import MetadataSubForm from './MetadataSubForm';
import LinkButton from '../../../../components/buttons/LinkButton';

export default function NameAndWorkflowSubForm({ form, hideWorkflowInput }) {
  const { workflows } = useMe();
  const [isUsingNewWorkflow, setIsUsingNewWorkflow] = useState(false);
  const workflowButtonText = isUsingNewWorkflow ? 'Use Existing Workflow' : 'Create New Workflow';
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="w-full bg-white px-10 border border-gray-300 rounded">
        <div className="pt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Name and Template</h3>
          <p className="mt-1 text-sm text-gray-500">
            Let's start by giving this action a name and selecting an optional Action Template.
          </p>
        </div>
        <div className="xl:flex xl:space-x-4 mt-2">
          {
              hideWorkflowInput
                ? null
                : (
                  <div className="pt-2 xl:w-1/2">
                    {
                      isUsingNewWorkflow
                        ? (
                          <div>
                            <Input
                              className="xl:w-1/2"
                              form={form}
                              label="New Workflow Name"
                              name="workflow.name"
                              placeholder="New Workflow Name..."
                              required
                            />
                          </div>
                        )
                        : (
                          <div>
                            <ComboBox
                              form={form}
                              label="Workflow"
                              name="workflow_id"
                              options={workflows}
                              valueKey="id"
                              labelKey="name"
                              required
                            />
                          </div>
                        )
                    }
                    <span className="flex justify-end -mb-2">
                      <LinkButton
                        buttonText={workflowButtonText}
                        onClick={() => setIsUsingNewWorkflow(!isUsingNewWorkflow)}
                      />
                    </span>
                  </div>
                )
            }

          <div className="pt-2 xl:w-1/2">
            <Input
              className="xl:w-1/2"
              form={form}
              label="Name"
              name="name"
              placeholder="Name..."
              required
            />
          </div>
        </div>
        {/* <div className="pt-2">
          <ActionTemplateSelect form={form} />
        </div> */}
        <div className="py-2">
          <MetadataSubForm form={form} />
        </div>
      </div>
    </div>
  );
}

NameAndWorkflowSubForm.propTypes = {
  form: FormShape.isRequired,
  hideWorkflowInput: PropTypes.bool,
};

NameAndWorkflowSubForm.defaultProps = {
  hideWorkflowInput: false,
};
