import React from 'react';
import EventShape from '../../../../shapes/EventShape';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import Feed from './Feed';
import ShowPageSectionCard from '../../../../components/layout/ShowPage/SectionCard';

export default function EventTimeline({ event, connection }) {
  return (
    <ShowPageSectionCard title="Event Timeline" subTitle="Log history for this event">
      <Feed event={event} connection={connection} />
    </ShowPageSectionCard>
  );
}

EventTimeline.propTypes = {
  event: EventShape.isRequired,
  connection: ConnectionShape,
};

EventTimeline.defaultProps = {
  connection: null,
};
