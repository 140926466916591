/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue';
import { ClockIcon } from '@heroicons/react/24/outline';
import ActionsList from './ActionsList';
import ActionShape from '../../../../shapes/ActionShape';

export default function ScheduledWorkflowTrigger({
  action,
  templateWorkflowActions,
  clonedWorkflowActions,
  setCurrentActionTemplate,
  currentActionTemplate,
}) {
  const clonedAction = clonedWorkflowActions.find((a) => a.parent_id === action.id);
  return (
    <li key={action.id} className="w-fit">
      <div className="border border-gray-300 rounded bg-50 p-2 text-xs">
        <span className="flex space-x-1 text-sm">
          <span className="text-gray-700 font-medium flex">
            <ClockIcon className="m-0.5 w-4 h-4 border border-gray-300 rounded text-indigo-700" />
          </span>
          <p>{cronstrue.toString(clonedAction ? clonedAction.schedule : action.schedule)}</p>
        </span>
        {
            clonedAction && clonedAction.schedule !== action.schedule
              ? (
                <p className="text-xs text-gray-400">
                  Template Schedule:
                  {' '}
                  {cronstrue.toString(action.schedule)}
                </p>
              )
              : null
          }
      </div>
      <ActionsList
        templateCallbackActions={[action]}
        templateWorkflowActions={templateWorkflowActions}
        clonedWorkflowActions={clonedWorkflowActions}
        setCurrentActionTemplate={setCurrentActionTemplate}
        currentActionTemplate={currentActionTemplate}
      />
    </li>
  );
}

ScheduledWorkflowTrigger.propTypes = {
  action: ActionShape.isRequired,
  templateWorkflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  clonedWorkflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  setCurrentActionTemplate: PropTypes.func.isRequired,
  currentActionTemplate: ActionShape,
};

ScheduledWorkflowTrigger.defaultProps = {
  currentActionTemplate: null,
};
