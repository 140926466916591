function isKeyEnd(character) {
  return character === '=';
}

function isValueEnd(character) {
  return character === '&' || character === undefined;
}

export default {
  name: 'form-body',
  token(stream) {
    if (isKeyEnd(stream.peek()) || isValueEnd(stream.peek())) {
      // skip over separator
      stream.next();
      // return color for &/= here
      return null;
    }

    while (stream.next()) {
      const next = stream.peek();

      if (isKeyEnd(next)) {
        // return key color here
        return 'propertyName';
      }
      if (isValueEnd(next)) {
        // return value color here
        return 'variableName';
      }
    }

    // end of content
    return null;
  },
};
