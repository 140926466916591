import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import Form from './Form';

export default function EditPage() {
  const { id } = useParams();
  const client = useClient('connections');
  const { data: connection, isFetching } = useClient('connections').get(
    id,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  const { data: connectionTemplate, isFetching: isFetchingTemplate } = useClient('connection_templates').get(
    connection?.connection_template_id,
    {
      enabled: !!connection?.connection_template_id,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  if (isFetching || isFetchingTemplate) return <Loading />;

  return (
    <Form
      defaultValues={connection}
      client={client}
      connectionTemplate={connectionTemplate}
    />
  );
}
