/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon, LinkIcon } from '@heroicons/react/24/outline';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import { renderTemplate } from '../../../helpers/TemplateHelpers';
import useClient from '../../../hooks/useClient';
import ActionShape from '../../../shapes/ActionShape';
import JsonDisplay from '../../../components/JsonDisplay';
import LinkButton from '../../../components/buttons/LinkButton';
import useMe from '../../../context/CurrentUserContext';
import Header from '../../../components/layout/PageHeader';
import UrlPreview from '../../../components/UrlPreview';
import EventShape from '../../../shapes/EventShape';
import MidDot from '../../../components/MidDot';
import Footer from '../../../components/forms/Footer';

export default function ActionTestForm({ action, event }) {
  const { connections } = useMe();
  const connection = connections.find((c) => c.id === action.destination_connection_id);

  const [lastTestResult, setLastTestResult] = useState(null);
  const [displayBodyPreview, setDispalyBodyPreview] = useState(true);

  let headerMergeParams;
  const contentType = action.configuration.content_type;
  if (contentType === 'form') {
    headerMergeParams = { 'Content-Type': 'application/x-www-form-urlencoded' };
  } else if (contentType === 'xml') {
    headerMergeParams = {
      Accept: 'application/xml',
      'Content-Type': 'application/xml',
    };
  } else {
    headerMergeParams = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  let headerAuthMergeParams;
  const {
    api: {
      authentication: {
        record_type: recordType,
        key,
        value,
        token,
        username,
        password,
        auth_parameter: authParameter,
        access_token: accessToken,
      },
    },
  } = connection;

  if (recordType === 'api_key') {
    headerAuthMergeParams = { [key]: value };
  } else if (recordType === 'basic') {
    // eslint-disable-next-line no-undef
    headerAuthMergeParams = { Authorization: `Basic ${base64.encode(`${username}:${password}`)}` };
  } else if (recordType === 'bearer') {
    headerAuthMergeParams = { Authorization: `Bearer ${token}` };
  } else if (recordType === 'oauth2') {
    if (authParameter) {
      headerAuthMergeParams = { [authParameter]: token };
    } else {
      headerAuthMergeParams = { Authorization: `Bearer ${accessToken}` };
    }
  } else {
    headerAuthMergeParams = {};
  }

  const form = useForm({ defaultValues: { event } });
  const navigate = useNavigate();

  const { isLoading: isSubmitting, mutate } = useClient('actions').post(
    action.id,
    'test',
    {
      onSuccess: setLastTestResult,
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const initialBody = renderTemplate(
    action.configuration.body,
    {
      alias: action.alias,
      connection,
      event,
    },
  );

  let body;
  try {
    body = JSON.parse(action.configuration.body);
  } catch {
    body = action.configuration.body;
  }
  const finalBody = renderTemplate(initialBody, { connection, event });
  const bodyPreview = displayBodyPreview ? JSON.parse(finalBody) : body;

  let displayResponse;
  try {
    displayResponse = JSON.parse(lastTestResult?.response);
  } catch {
    displayResponse = lastTestResult?.response;
  }

  // This endpoint only expects an event key when it needs to build an event for the test
  const submissionBody = event ? { event } : {};
  return (
    <form onSubmit={form.handleSubmit(() => mutate(submissionBody))}>
      <Header
        name="Test Action"
        description="Test an action by firing off test API requests to ensure functionality."
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
          <div className="pt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Request Preview</h3>
            <p className="mt-1 text-sm text-gray-500">
              A general shape of this actions&apos;s Headers, Method, Path, and request body.
            </p>
          </div>
          {
            event
              ? (
                <div className="border border-indigo-500 rounded my-4 p-1">
                  <span className="flex text-sm text-gray-500 mb-1">
                    <InformationCircleIcon className="text-indigo-500 h-5 w-5 mr-1" />
                    <p className="font-medium mr-1 text-gray-700">
                      This request utilizes event data in the URL, Header, or Body Template.
                    </p>
                  </span>
                  <span className="xl:flex text-sm text-gray-500">
                    <p className="mr-1">
                      The following event was fetched to populate data for this test:
                    </p>
                    {' '}
                    <LinkButton buttonText={event?.id} path={`/events/${event?.id}`} />
                  </span>

                </div>
              )
              : null
          }
          <div className="mt-2">
            <label htmlFor="Request URL Preview" className="block text-sm font-medium text-gray-700 flex-0 mb-1">
              Request URL Preview
            </label>
            <div className="flex min-h-max p-1 border border-gray-300 rounded bg-white">
              <UrlPreview
                connection={connection}
                method={action.configuration.method}
                url={action.configuration.url}
                event={event}
              />
            </div>
          </div>
          <div className="mt-6">
            <label htmlFor="Request Headers Preview" className="block text-sm font-medium text-gray-700 flex-0 mb-1">
              Request Headers Preview
            </label>
            <JsonDisplay valueObject={{ ...(action.configuration.headers || {}), ...headerMergeParams, ...headerAuthMergeParams }} />
          </div>
          {
            action.configuration.method !== 'get'
              ? (
                <div className="my-4">
                  <div className="flex justify-between">
                    <label htmlFor="Request Body Preview" className="block text-sm font-medium text-gray-700 flex-0 mb-1">
                      Request Body Preview
                    </label>
                    {/* <div className="flex">
                      <LinkButton className={displayBodyPreview ? 'underline font-semibold' : null} buttonText="Preview View" onClick={() => setDispalyBodyPreview(true)} />
                      <MidDot />
                      <LinkButton className={!displayBodyPreview ? 'underline font-semibold' : null} buttonText="Template View" onClick={() => setDispalyBodyPreview(false)} />
                    </div> */}
                  </div>
                  <JsonDisplay valueObject={bodyPreview} />
                </div>
              )
              : null
          }
          <Footer
            backButtonText="Back"
            onBackButtonClick={() => navigate(-1)}
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
            isSubmitting={isSubmitting}
            submitButtonText="Send Request"
            isSubmittingText="Sending Request..."
            form={form}
          />
        </div>
      </div>
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
          <div className="pt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Request Response</h3>
            <p className="mt-1 text-sm text-gray-500">
              The JSON data returned by a request from this API request.
            </p>
          </div>
          {
            // eslint-disable-next-line no-nested-ternary
            isSubmitting
              ? (
                <div className="my-6 border rounded-md border-gray-300 flex justify-center">
                  <div className="my-6">
                    <h2 className="text-lg font-semibold tracking-tight text-gray-700 flex">
                      {/* <ExclamationTriangleIcon className="text-gray-500 h-5 w-5 mr-1 mt-1" /> */}
                      Submitting...
                    </h2>
                    <p className="text-base leading-7 text-gray-600">We are sending a test request!</p>
                  </div>
                </div>
              ) : (
                lastTestResult
                  ? (
                    <>
                      <div className="my-4">
                        <label htmlFor="Request Body Preview" className="block text-sm font-medium text-gray-700 flex-0 mb-1">
                          Response Status Code
                        </label>
                        <p className="text-sm text-gray-500">
                          {lastTestResult.http_status}
                        </p>
                      </div>
                      <div className="my-4">
                        <label htmlFor="Request Body Preview" className="block text-sm font-medium text-gray-700 flex-0 mb-1">
                          Request Response Body
                        </label>
                        <JsonDisplay valueObject={displayResponse} />
                      </div>
                    </>
                  )
                  : (
                    <div className="my-6 border rounded-md border-gray-300 flex justify-center">
                      <div className="my-6">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-700 flex">
                          {/* <ExclamationTriangleIcon className="text-gray-500 h-5 w-5 mr-1 mt-1" /> */}
                          Request Not Submitted
                        </h2>
                        <p className="text-base leading-7 text-gray-600">Reponse data from the test request will appear here!</p>
                      </div>
                    </div>
                  )
              )
          }
        </div>
      </div>
    </form>
  );
}

ActionTestForm.propTypes = {
  action: ActionShape.isRequired,
  event: EventShape,
};

ActionTestForm.defaultProps = {
  event: null,
};
