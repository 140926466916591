import React from 'react';
import ActionShape from '../../../../../shapes/ActionShape';
import LinkButton from '../../../../../components/buttons/LinkButton';

export default function ActionNotAttempted({ action, parentAction }) {
  return (
    <div className="border rounded bg-gray-100 border-gray-300 w-full p-2 text-gray-400">
      <LinkButton className="text-xs mt-0.5" buttonText={action.name} path={`/actions/${action.id}`} />
      {
        parentAction?.name
          ? (
            <span className="text-xs mt-0.5 flex">
              Awaiting response from
              <p className="text-gray-600 ml-1">
                {parentAction?.name}
              </p>
              .
            </span>
          ) : null
      }

    </div>
  );
}

ActionNotAttempted.propTypes = {
  action: ActionShape.isRequired,
  parentAction: ActionShape.isRequired,
};
