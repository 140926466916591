import PropTypes from 'prop-types';
import React from 'react';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connectionName, connectionAvatar } from '../../../helpers/ConnectionHelpers';
import { findConnection } from '../../../helpers/EventHelpers';
import EventShape from '../../../shapes/EventShape';
import EventStatusIcon from './StatusIcon';
import useMe from '../../../context/CurrentUserContext';

function EventIndexPageRow({ event }) {
  const { connections } = useMe();
  const connection = findConnection(event, connections);
  const actionProcessedLogs = event.logs.filter(({ topic }) => topic === 'action.processed');
  const successActionProcessedLogs = event.logs.filter(({ topic, status }) => topic === 'action.processed' && status === 'success');
  const actionIds = _.uniq(actionProcessedLogs.map((cl) => cl.records.action_id));
  const successActionIds = _.uniq(successActionProcessedLogs.map((cl) => cl.records.action_id));

  return (
    <tr key={event.id}>
      <td>
        {
        actionProcessedLogs.length
          ? (
            <EventStatusIcon
              event={event}
              actionsAttempted={actionIds.length}
              actionsResolved={successActionIds.length}
            />
          )
          : null
        }
      </td>
      <td className="py-3 pr-3 text-sm text-gray-700">
        <span className="flex">
          {connectionAvatar(connection, { size: 'xs', containerSize: 'xs', bgColor: 'bg-gray-100 m-1.5' })}
          <div>
            <p>{connectionName(connection)}</p>
            <p className="text-gray-500 text-xs">{event.topic}</p>
          </div>
        </span>
        <div className="sm:hidden font-normal text-gray-500 flex ml-2 text-xs">
          <TimeAgo date={`${event.inserted_at} GMT`} />
          <span className="mx-2" aria-hidden="true">&middot;</span>
          {successActionIds.length}
          {' '}
          /
          {' '}
          {actionIds.length}
          {' '}
          {actionIds.length === 1 ? 'action' : 'actions'}
          {' '}
          resolved
        </div>
      </td>
      <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 sm:table-cell">
        {successActionIds.length}
        {' '}
        /
        {' '}
        {actionIds.length}
        {' '}
        {actionIds.length === 1 ? 'action' : 'actions'}
        {' '}
      </td>
      {/* <td className="hidden whitespace-nowrap px-3 py-3 text-sm text-gray-500 lg:table-cell">
        {event.status}
      </td> */}
      <td className="hidden md:table-cell whitespace-nowrap px-3 py-3 text-sm text-gray-500">
        <TimeAgo date={`${event.inserted_at} GMT`} />
      </td>
      <td className="whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link to={event.id} className="text-indigo-600 hover:text-indigo-900">
          Details
        </Link>
      </td>
    </tr>
  );
}

EventIndexPageRow.propTypes = {
  event: EventShape.isRequired,
};

export default function EventIndexPageBody({ items }) {
  return (
    <tbody className="divide-y divide-gray-300 bg-white">
      {items.map((event) => (
        <EventIndexPageRow key={event.id} event={event} />
      ))}
    </tbody>
  );
}

EventIndexPageBody.propTypes = {
  items: PropTypes.arrayOf(EventShape).isRequired,
};
