/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import LogShape from '../../../../shapes/LogShape';

export default function GeneralInfo({ log }) {
  return (
    <>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Timestamp</dt>
        <dd className="mt-1 text-sm text-gray-900">{log.inserted_at}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Response Code</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {`${log.body.http_status}(${log.status})`}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Request URL</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <span className="mt-1 mr-1 text-teal-600 font-semibold">{log.body.method}</span>
          {log.body.url}
        </dd>
      </div>
    </>
  );
}

GeneralInfo.propTypes = {
  log: LogShape.isRequired,
};
