import PropTypes from 'prop-types';
import TemplateFieldShape from './TemplateFieldShape';

export default PropTypes.shape({
  record_type: PropTypes.oneOf(['action_template']),
  action_type: PropTypes.string,
  alias_form: PropTypes.arrayOf(TemplateFieldShape).isRequired,
  destination_connection_template_id: PropTypes.string.isRequired,
  source_connection_template_id: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  inserted_at: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object,
  name: PropTypes.string.isRequired,
  quick_action: PropTypes.bool,
  updated_at: PropTypes.string.isRequired,
});
