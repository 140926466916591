/* eslint-disable max-len */
import { SignalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import EmptyState from '../../../components/IndexPage/EmptyState';
import ItemList from '../../../components/ItemsList';
import FilterBar from '../../../components/layout/FilterBar/V2FitlerBar';
import PageHeader from '../../../components/layout/PageHeader';
import SearchBar from '../../../components/layout/SearchBar';
import Loading from '../../../components/Loading';
import useIndexFetchClient, { useIndexButtons } from '../../../hooks/useIndexFetchClient';
import { unique } from '../../../helpers/ArrayHelpers';
import useClient from '../../../hooks/useClient';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';

const config = {
  clientName: 'logs',
  resourceName: 'Request',
  description: 'A list of all requests that are occuring in your Destination Connections',
  mobileTitle: 'Request',
  icon: <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />,
};

function recordIds(logs, recordType) {
  const ids = (logs || [])
    .map((log) => log.records[recordType])
    .filter((id) => id);

  return unique(ids);
}

export default function IndexPage() {
  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(
    config.clientName,
    { searchParams: { topic: 'action.processed' } },
  );

  const { data: connections, isLoading: isLoadingConnections } = useClient('connections').list(
    { ids: recordIds(indexClient.data, 'destination_connection_id') },
    { enabled: !indexClient.isLoading },
  );

  // Display Loading or Empty States
  const isLoading = [
    indexClient.isLoading,
    isLoadingConnections,
  ].includes(true);

  if (isLoading) return (<Loading />);

  if (indexClient.requestFailed || !indexClient.data) return (<EmptyState name={config.resourceName} icon={SignalIcon} />);
  if (!indexClient.data.length && !Object.keys(indexClient.urlParamsObj).length) return (<EmptyState name={config.resourceName} icon={SignalIcon} />);

  // Buttons for Top Bar
  const buttons = useIndexButtons(config.resourceName, indexClient);

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        indexClient={indexClient}
      >
        <FilterForm
          onCloseModal={() => indexClient.setFilterOpen(false)}
          indexClient={indexClient}
        />
      </FilterBar>
      <SearchBar
        name="matcher"
        label="Search Actions"
        indexClient={indexClient}
        placeholder="Search for Actions by ID..."
      />
      <ItemList
        body={<TableBody items={indexClient.data} connections={connections} />}
        meta={indexClient.meta}
        header={<TableHeader />}
      />
    </div>
  );
}
