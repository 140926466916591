import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connectionAvatar } from '../../../../../../helpers/ConnectionHelpers';
import LogShape from '../../../../../../shapes/LogShape';
import ConnectionShape from '../../../../../../shapes/ConnectionShape';
import ModalBody from './ModalBody';
import Modal from '../../../../../../components/Modal';
import LinkButton from '../../../../../../components/buttons/LinkButton';
import MidDot from '../../../../../../components/MidDot';

export default function EventCreatedSubLine({ log, connection }) {
  const [displayModal, setDisplayModal] = useState(false);
  return (
    <>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={displayModal}
        onClose={() => setDisplayModal(false)}
      >
        <ModalBody
          log={log}
          onClose={() => setDisplayModal(false)}
        />
      </Modal>
      <span className="font-medium text-gray-500 ml-3">
        <span className="flex">
          <p>Topic: </p>
          <span className="mt-0.5 mx-1">
            {connectionAvatar(connection, { size: 'xxs' })}
          </span>
          {connection?.name
            ? (
              <Link
                className="text-indigo-700"
                to={`/connections/${connection.id}`}
              >
                {connection.name}
              </Link>
            )
            : 'the Dashboard'}
          <MidDot />
          <p className="text-gray-500">
            {log.body.topic}
          </p>
        </span>
        <span className="flex space-x-1">
          <p>Event Payload:</p>
          <LinkButton className="text-xs" buttonText="Display" onClick={() => setDisplayModal(true)} />
        </span>
      </span>

    </>
  );
}

EventCreatedSubLine.propTypes = {
  log: LogShape.isRequired,
  connection: ConnectionShape,
};

EventCreatedSubLine.defaultProps = {
  connection: null,
};
