import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import FailureAlert from '../components/FailureAlert';

const ErrorContext = createContext();
const { Provider } = ErrorContext;

function ErrorProvider({ children }) {
  const [serverError, setServerError] = useState(false);

  return (
    <Provider
      value={{
        serverError,
        setServerError,
      }}
    >
      {
        serverError
          ? (
            <FailureAlert
              open={serverError}
              clearError={() => setServerError(false)}
            />
          )
          : null
      }
      {children}
    </Provider>
  );
}

ErrorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ErrorContext, ErrorProvider };
