import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import View from './View';
import Loading from '../../../components/Loading';
import { ErrorContext } from '../../../context/ErrorContext';
import useClient from '../../../hooks/useClient';

export default function Show() {
  const { setServerError } = useContext(ErrorContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const workflowsClient = useClient('workflows');

  const response = workflowsClient.get(id);
  const { data: workflow, isLoading: isLoadingWorkflow } = response;

  const { isLoading: isDestroying, mutate: destroy } = workflowsClient.destroy(
    id,
    {
      onSuccess: () => navigate('/workflows'),
      onError: () => setServerError(true),
    },
  );

  if (isLoadingWorkflow) return <Loading />;
  return (<View onDestroy={destroy} workflow={workflow} />);
}
