/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LinkIcon } from '@heroicons/react/24/outline';
import ConnectionTemplateSubForm from '../Form/ConnectionTemplateSubForm';
import useClient from '../../../hooks/useClient';
import Header from '../../../components/layout/PageHeader';
import { formatHeaders } from '../../../helpers/ConnectionHelpers';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Form from '../Form';

export default function ConnectionForm() {
  const [connectionTemplate, setConnectionTemplate] = useState(null);
  const [skipUsingTemplate, setSkipUsingTemplate] = useState(null);

  const form = useForm({ defaultValues: {} });

  useEffect(() => {
    if (connectionTemplate) {
      form.reset({ ...connectionTemplate, template: false, parent_id: connectionTemplate?.id });
    }
  }, [connectionTemplate?.id]);

  const navigate = useNavigate();
  const client = useClient('connections');
  const { isLoading: isSubmitting, mutate } = client.create({
    onSuccess: ({ id }) => navigate(`/connections/${id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const { data: templates } = useClient('connections').list({ page_size: 50, template: true });

  const onSubmit = form.handleSubmit(
    (cxn) => {
      const formattedData = formatHeaders(cxn);
      return mutate({ connection: formattedData });
    },
  );

  return (
    <>
      <Header
        name="New Connection Form"
        description="Add a new Event Source or Action Destination"
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
          <ConnectionTemplateSubForm
            form={form}
            templates={templates}
            connectionTemplate={connectionTemplate}
            setConnectionTemplate={setConnectionTemplate}
            skipUsingTemplate={skipUsingTemplate}
            setSkipUsingTemplate={setSkipUsingTemplate}
          />
        </div>
      </div>
      {
        // eslint-disable-next-line no-extra-boolean-cast
        skipUsingTemplate || connectionTemplate
          ? (
            <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
              <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
                <Form
                  form={form}
                  onSubmit={onSubmit}
                  isSubmitting={isSubmitting}
                  connectionTemplate={connectionTemplate}
                  skipUsingTemplate={skipUsingTemplate}
                />
              </div>
            </div>
          )
          : null
      }
    </>
  );
}
