/* eslint-disable react/no-array-index-key */
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const PredicateShape = PropTypes.shape({
  // arguments: PropTypes.arrayOf(PredicateShape),
  arguments: PropTypes.arrayOf(PropTypes.shape({})),
  default: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  type: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
});

const makeConstant = (text) => <span className="mx-1 text-gray-500 truncate">{text}</span>;
const makeValue = (text) => <span className="mx-1 text-indigo-500 truncate">{text}</span>;
const makeOperator = (text) => <span className="mx-1 text-red-500">{text}</span>;

function Card({ children }) {
  return (
    <p className="bg-gray-100 border border-gray-500 ml-1 overflow-hidden rounded text-sm truncate w-min py-2 my-2">
      {children}
    </p>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

function Connector({ position }) {
  const isTop = position === 'top';

  return (
    <>
      <span
        className={classNames(isTop ? '-bottom-0.5' : '-top-0', 'absolute left-4  h-1/2 w-0.5 bg-gray-600')}
        aria-hidden="true"
      />
      <span
        className="absolute top-1/2 left-4 w-3 h-0.5 bg-gray-600"
        aria-hidden="true"
      />
    </>
  );
}

Connector.propTypes = {
  position: PropTypes.oneOf(['bottom', 'top']).isRequired,
};

function Boolean({ predicate, embedded, operator }) {
  return (
    <ul className={classNames(embedded ? 'ml-2' : '')}>
      {
        predicate.arguments.map((item, index) => (
          <Fragment key={`${item.id}${index}zero`}>
            <li key={`${item.id}${index}one`} className="bg-white pl-6 relative">
              <Connector position={index === 0 ? 'top' : 'bottom'} />
              {
                index < predicate.arguments.length - 1
                  ? <Connector position="top" />
                  : null
              }
              <PredicateTree predicate={item} embedded={false} />
            </li>
            {
              index !== predicate.arguments.length - 1
                ? (
                  <li key={`${item.id}${index}two`} className="px-1">
                    <span className="bg-white border border-gray-500 p-1 rounded text-xs">{operator}</span>
                  </li>
                )
                : null
            }
          </Fragment>
        ))
      }
    </ul>
  );
}

Boolean.propTypes = {
  predicate: PredicateShape.isRequired,
  embedded: PropTypes.bool.isRequired,
  operator: PropTypes.string.isRequired,
};

function Field({ predicate, embedded }) {
  const name = typeof predicate.name === 'string' ? predicate.name : predicate.name.join('.');

  if (embedded) return makeValue(name);

  return (
    <Card>
      {makeValue(name)}
    </Card>
  );
}

Field.propTypes = {
  predicate: PredicateShape.isRequired,
  embedded: PropTypes.bool.isRequired,
};

function formatValue(value) {
  if (typeof value === 'string') return `'${value}'`;
  if (value === null) return 'null';

  return value;
}

function Constant({ predicate, embedded }) {
  const value = formatValue(predicate.value);

  if (embedded) return makeConstant(value);

  return (
    <Card>
      {makeConstant(value)}
    </Card>
  );
}

Constant.propTypes = {
  predicate: PredicateShape.isRequired,
  embedded: PropTypes.bool.isRequired,
};

// eslint-disable-next-line no-unused-vars
function Comparison({ predicate, operator }) {
  return (
    <Card>
      <PredicateTree predicate={predicate.arguments[0]} embedded />
      {makeOperator(operator)}
      <PredicateTree predicate={predicate.arguments[1]} embedded />
    </Card>
  );
}

Comparison.propTypes = {
  predicate: PredicateShape.isRequired,
  operator: PropTypes.string.isRequired,
};

export default function PredicateTree({ predicate, embedded }) {
  switch (predicate.type) {
    case 'or':
      return <Boolean predicate={predicate} embedded={embedded} operator="OR" />;
    case 'and':
      return <Boolean predicate={predicate} embedded={embedded} operator="AND" />;
    case 'event_field':
      return <Field predicate={predicate} embedded={embedded} />;
    case 'field':
      return <Field predicate={predicate} embedded={embedded} />;
    case 'constant':
      return <Constant predicate={predicate} embedded={embedded} />;
    case 'equal':
      return <Comparison predicate={predicate} operator="==" />;
    case 'not_equal':
      return <Comparison predicate={predicate} operator="!=" />;
    case 'greater_than':
      return <Comparison predicate={predicate} operator=">" />;
    case 'greater_than_equal':
      return <Comparison predicate={predicate} operator=">=" />;
    case 'less_than':
      return <Comparison predicate={predicate} operator="<" />;
    case 'less_than_equal':
      return <Comparison predicate={predicate} operator="<=" />;
    default:
      return null;
  }
}

PredicateTree.propTypes = {
  predicate: PredicateShape.isRequired,
  embedded: PropTypes.bool,
};

PredicateTree.defaultProps = {
  embedded: false,
};
