import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorFallback({ error }) {
  if (process.env.NODE_ENV !== 'development') return null;
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};
