import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CodeInput from '../../../../components/forms/CodeInput';
import FormShape from '../../../../shapes/FormShape';
import LinkButton from '../../../../components/buttons/LinkButton';
import AvailableDataModal from '../../../../components/Modal/AvailableDataModal';
import EventShape from '../../../../shapes/EventShape';
import ConnectionShape from '../../../../shapes/ConnectionShape';

export default function BodyInputCard({
  form,
  onChangeToAliasInput,
  displayToggleAlias,
  event,
  destinationConnection,
}) {
  const [displayAvailableDataModal, setDisplayAvailableDataModal] = useState(false);

  return (
    <>
      <CodeInput
        form={form}
        label="Request Body Input"
        name="configuration.body"
        required
      />
      <div className="flex justify-end mr-1">
        {
          displayToggleAlias
            ? (
              <LinkButton
                onClick={onChangeToAliasInput}
                buttonText="Switch to Alias Input"
              />
            )
            : null
        }
        {displayToggleAlias ? <span className="mx-2" aria-hidden="true">&middot;</span> : null}
        <AvailableDataModal
          display={displayAvailableDataModal}
          onOpen={() => setDisplayAvailableDataModal(true)}
          onClose={() => setDisplayAvailableDataModal(false)}
          lastEvent={event}
          destinationConnection={destinationConnection}
          form={form}
        />
      </div>
    </>
  );
}

BodyInputCard.propTypes = {
  form: FormShape.isRequired,
  onChangeToAliasInput: PropTypes.func.isRequired,
  displayToggleAlias: PropTypes.bool.isRequired,
  event: EventShape,
  destinationConnection: ConnectionShape,
};

BodyInputCard.defaultProps = {
  event: null,
  destinationConnection: null,
};
