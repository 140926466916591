import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import Loading from '../../../components/Loading';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import useClient from '../../../hooks/useClient';
import Form from './Form';
import ConnectionShape from '../../../shapes/ConnectionShape';
import { formatHeaders } from '../../../helpers/ConnectionHelpers';

export default function ClonePageWrapper() {
  const { id } = useParams();
  const { data, isLoading } = useClient('connections').get(id);
  if (isLoading) return <Loading />;
  return (<ClonePage connection={data} />);
}

function ClonePage({ connection }) {
  const navigate = useNavigate();

  const formCxn = _.omit(
    connection,
    [
      'id',
      'description',
      'record_type',
      'template',
      'inserted_at',
      'updated_at',
      'event_publish_url',
      'api.authentication.access_token_expires_at',
      'api.authentication.access_token',
      'api.authentication.refresh_token_expires_at',
      'api.authentication.refresh_token',
    ],
  );
  const form = useForm({ defaultValues: formCxn });

  const { isLoading: isSubmitting, mutate } = useClient('connections').create({
    onSuccess: ({ id }) => navigate(`/connections/${id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit(
    (cxn) => {
      const formattedData = formatHeaders(cxn);
      return mutate({ connection: formattedData });
    },
  );

  return (
    <Form
      form={form}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    />
  );
}

ClonePage.propTypes = {
  connection: ConnectionShape.isRequired,
};
