/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Footer from '../../../../components/forms/Footer';
import Header from '../../../../components/layout/PageHeader';
import { formatHeaders, connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import mapErrorsToForm from '../../../../helpers/FormHelpers';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import FormBody from './FormBody';
import useClient from '../../../../hooks/useClient';

export default function WebhookConfigurationForm({ defaultValues, connection }) {
  const navigate = useNavigate();

  const form = useForm({ defaultValues });

  const { isLoading: isSubmitting, mutate } = useClient('connections').update(
    defaultValues.id,
    {
      onSuccess: ({ id }) => navigate(`/connections/${id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit(
    (c) => {
      const formattedData = formatHeaders(c);
      return mutate({ connection: formattedData });
    },
  );

  return (
    <form onSubmit={onSubmit}>
      <Header
        name={`Webhook Configuration for ${form.watch('name')}`}
        description={`Update the Webhook Configuration for your ${form.watch('name')} connection.`}
        avatar={connectionAvatar(form.watch(), { size: 'lg' })}
      />
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
          <FormBody connection={connection} form={form} />
          <Footer
            backButtonText="Back to Connections"
            onBackButtonClick={() => navigate(-1)}
            submitButtonText="Submit"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
          />
        </div>
      </div>
    </form>
  );
}

WebhookConfigurationForm.propTypes = {
  defaultValues: ConnectionShape,
};

WebhookConfigurationForm.defaultProps = {
  defaultValues: {},
};
