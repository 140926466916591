import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinkButton from '../../../../components/buttons/LinkButton';
import Input from '../../../../components/forms/Input';
import TextArea from '../../../../components/forms/TextArea';
import ActionShape from '../../../../shapes/ActionShape';
import ActionTemplateShape from '../../../../shapes/ActionTemplateShape';
import EventShape from '../../../../shapes/EventShape';
import FormShape from '../../../../shapes/FormShape';
import TemplateFieldShape from '../../../../shapes/TemplateFieldShape';
import AvailableDataModal from '../../../../components/Modal/AvailableDataModal';
import ConnectionShape from '../../../../shapes/ConnectionShape';

function AliasInput({ form, templateField }) {
  return (
    <div className="my-2">
      {
        templateField.input_type === 'textarea'
          ? (
            <TextArea
              form={form}
              label={templateField.label}
              name={`alias.${templateField.name}`}
              required={templateField.required}
              inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          )
          : (
            <Input
              form={form}
              label={templateField.label || templateField.name}
              name={`alias.${templateField.name}`}
              required={templateField.required}
              // placeholder="Super Descriptive Rule Name..."
              inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          )
      }
    </div>
  );
}

AliasInput.propTypes = {
  form: FormShape.isRequired,
  templateField: TemplateFieldShape.isRequired,
};

// eslint-disable-next-line no-unused-vars
export default function AliasInputCard({
  action,
  actionTemplate,
  destinationConnection,
  event,
  form,
  onChangeToJsonInput,
}) {
  const [displayAvailableDataModal, setDisplayAvailableDataModal] = useState(false);
  return (
    <div className="w-full rounded border border-gray-300 bg-gray-100 px-4 py-2">
      {(action || actionTemplate).alias_form.map((templateField) => (
        <AliasInput
          key={`aliasInput${templateField.name}`}
          form={form}
          templateField={templateField}
        />
      ))}
      <div className="flex justify-end mr-1">
        <LinkButton
          onClick={onChangeToJsonInput}
          buttonText="Switch to JSON Input"
        />
        <span className="mx-2" aria-hidden="true">&middot;</span>
        <AvailableDataModal
          display={displayAvailableDataModal}
          onOpen={() => setDisplayAvailableDataModal(true)}
          onClose={() => setDisplayAvailableDataModal(false)}
          lastEvent={event}
          destinationConnection={destinationConnection}
          form={form}
        />
      </div>
    </div>
  );
}

AliasInputCard.propTypes = {
  actionTemplate: ActionTemplateShape,
  destinationConnection: ConnectionShape.isRequired,
  event: EventShape,
  form: FormShape.isRequired,
  onChangeToJsonInput: PropTypes.func.isRequired,
};

AliasInputCard.defaultProps = {
  actionTemplate: null,
  event: null,
};
