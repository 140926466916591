import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from './InputBase';
import FormShape from '../../shapes/FormShape';
import LinkButton from '../buttons/LinkButton';

export default function LockableInput({
  displayLockButton,
  form,
  label,
  defaultLocked,
  name,
  helperText,
  wrapperClass,
}) {
  const [disabled, setValueDisabled] = useState(defaultLocked);
  useEffect(() => {
    setValueDisabled(defaultLocked);
  }, [defaultLocked]);

  return (
    <div className={wrapperClass}>
      <Input
        form={form}
        disabled={disabled}
        label={(
          <span className="flex justify-between">
            <h3>{label}</h3>
            {
              displayLockButton
                ? (
                  <LinkButton
                    buttonText={disabled ? 'Unlock' : 'Lock'}
                    onClick={() => setValueDisabled(!disabled)}
                  />
                )
                : null
            }
          </span>
        )}
        name={name}
        placeholder={`${label}...`}
        helperText={helperText}
      />
    </div>
  );
}

LockableInput.propTypes = {
  displayLockButton: PropTypes.bool,
  form: FormShape.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  defaultLocked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
};

LockableInput.defaultProps = {
  displayLockButton: false,
  helperText: null,
  defaultLocked: false,
  wrapperClass: 'col-span-2 lg:col-span-1',
};
