/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import useClient from '../../../../hooks/useClient';
import Modal from '../../../../components/Modal';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Loading from '../../../../components/Loading';
import { formatDateTime } from '../../../../helpers/DateHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import { logStatusColor } from '../../../../helpers/LogHelpers';
import LogDetailModalBody from '../../../Action/ShowPage/LogDetailModalBody';

export default function ApiHistory({ connection }) {
  const [currentLog, setCurrentLog] = useState(null);
  const [startDate] = useState(DateTime.now().minus({ days: 6 }).toISO());

  const { data: logs, isLoading: isLoadingLogs } = useClient('logs').list({
    records: { destination_connection_id: connection.id },
    page_size: 100,
    topic: 'action.processed',
    start_date: startDate,
  });

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              API Request History
            </h2>
          </div>
          <Modal maxWidth="sm:max-w-5xl sm:w-full" open={!!currentLog} onClose={() => setCurrentLog(null)}>
            <LogDetailModalBody log={currentLog} onClose={() => setCurrentLog(null)} />
          </Modal>
          {
            isLoadingLogs
              ? (<Loading />)
              : (
                logs.length
                  ? (
                    <Table logs={logs.slice(1, 10)} setCurrentLog={setCurrentLog} />
                  )
                  : (
                    <p className="m-4 max-w-2xl text-sm text-gray-500">
                      There are currently no API Request for this connection.
                    </p>
                  )
              )
          }
        </div>
      </ErrorBoundary>
    </section>
  );
}

ApiHistory.propTypes = {
  connection: ConnectionShape.isRequired,
};

export function Table({ logs, setCurrentLog }) {
  return (
    <>
      {/* Projects list (only on smallest breakpoint) */}
      <div className="sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-sm font-medium text-gray-900">Projects</h2>
        </div>
        <ul className="mt-3 divide-y divide-gray-100 border-t border-gray-200">
          {logs.map((log) => (
            <li key={log.id}>
              <a
                className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentLog(log);
                }}
              >
                <span className="flex items-center space-x-3 truncate">
                  <span className="truncate text-sm font-medium leading-6">
                    {log.body.method}
                    {' '}
                    <span className="truncate font-normal text-gray-500">
                      {log.body.url}

                    </span>
                  </span>
                </span>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="inline-block min-w-full border-b border-gray-200 align-middle">
          <table className="min-w-full">
            <thead>
              <tr className="border-t border-gray-200">
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="lg:pl-2">Method + URL</span>
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Timestamp
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900"
                />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {logs.map((log) => (
                <tr key={log.id}>
                  <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-900">
                    <div className="flex flex-wrap items-center space-x-3 lg:pl-2">
                      <span className="truncate">
                        <span>
                          {log.body.method}

                          {' '}
                          <span className="font-normal text-gray-500">
                            {log.body.url}
                          </span>
                        </span>
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-3 text-xs font-medium text-gray-500">
                    {
                      log.body.http_status
                        ? (
                          <div className="flex items-center space-x-2">
                            <div className="flex flex-shrink-0 -space-x-1">
                              <p className={classNames(logStatusColor(log), 'rounded-sm px-0.5 text-xs')}>
                                {log.body.http_status}
                              </p>
                            </div>
                          </div>
                        )
                        : 'N/A'
                    }
                  </td>
                  <td className="hidden whitespace-nowrap px-6 py-3 text-right text-xs text-gray-500 md:table-cell">
                    {formatDateTime(log.inserted_at)}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                    <LinkButton className="text-xs" buttonText="Details" onClick={() => setCurrentLog(log)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>

  );
}
