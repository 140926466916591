export function any(elements, callback = (value) => value) {
  return elements.find(callback) !== undefined;
}

export function groupArray(group, size, length) {
  // if (index modulus size) equals 0 then concat a group of
  // length 'size' as a new entry to the accumulator array and
  // return it, else return the accumulator
  const reducerFunc = (accumulator, current, index, original) => (((index % size) === 0)
    ? accumulator.concat([original.slice(index, index + size)])
    : accumulator);

  // if the current index is greater than the supplied length filter it out
  const filterFunc = (single, index) => index < length;

  // reduce and filter original group
  return group.reduce(reducerFunc, []).filter(filterFunc);
}

export function groupBy(elements, callback) {
  return elements.reduce((grouped, element) => {
    const key = callback(element);
    const value = [...(grouped[key] || []), element];

    return { ...grouped, [key]: value };
  }, {});
}

export function unique(elements) {
  return [...new Set(elements || [])];
}

export function uniqueBy(elements, byFunction) {
  return unique(elements.map(byFunction))
    .map((id) => elements.find((element) => byFunction(element) === id));
}

export function extractQueryableArray(arr, key) {
  if (arr instanceof Array) {
    return unique(arr.map((i) => i[key])).filter((e) => e);
  }
  return [];
}

export const readableArray = (array) => {
  const arr = array.slice(0); const
    last = arr.pop();
  return `${arr.join(', ')} & ${last}`;
};

export default {
  groupBy,
  unique,
};
