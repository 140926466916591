import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import qs from 'qs';
import { AxiosContext } from '../context/AxiosContext';
import { SocketContext } from '../context/SocketContext';
// eslint-disable-next-line import/no-cycle
import withMany from './withMany';
// eslint-disable-next-line import/no-cycle
import withOne from './withOne';

export function useChannel(channelName) {
  const [channel, setChannel] = useState();
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const phoenixChannel = socket.channel(channelName);
    phoenixChannel.join().receive('ok', () => {
      setChannel(phoenixChannel);
    });

    return () => {
      phoenixChannel.leave();
    };
  }, [channelName, setChannel, socket]);

  return [channel];
}

export function useQueryInvalidator(resource, queryClient) {
  const [channel] = useChannel(`${resource}:all`, queryClient);

  useEffect(() => {
    if (!channel) return () => {};

    const createdListener = channel.on('created', () => {
      queryClient.invalidateQueries(resource);
    });

    const updatedListener = channel.on('updated', () => {
      queryClient.invalidateQueries(resource);
    });

    const deletedListener = channel.on('deleted', () => {
      queryClient.invalidateQueries(resource);
    });

    return () => {
      channel.off('created', createdListener);
      channel.off('updated', updatedListener);
      channel.off('deleted', deletedListener);
    };
  }, [channel, queryClient]);
}

export function paramsSerializer(params) {
  return qs.stringify(params, { arrayFormat: 'brackets' });
}

export default function useClient(resource) {
  // const queryClient = useQueryClient();
  // useQueryInvalidator(resource, queryClient);

  // const queryClient = useQueryClient();
  const { privateAxios, publicAxios } = useContext(AxiosContext);

  function about(queryConfiguration = null) {
    const query = async () => {
      const { data: { data } } = await privateAxios.get(`/${resource}/about`);

      return data;
    };

    return useQuery({
      queryKey: [resource, 'about'],
      queryFn: query,
      ...queryConfiguration,
    });
  }

  function create(callbacks = {}) {
    const mutation = async (params) => {
      const { data: { data } } = await privateAxios.post(`/${resource}`, params);

      return data;
    };

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  function destroy(id, callbacks = {}) {
    const mutation = () => privateAxios.delete(`/${resource}/${id}`);

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  function fetchMe(queryConfiguration = null) {
    const query = async () => {
      const { data: { user, entity } } = await privateAxios.get(`/${resource}/me`);

      return { user, entity };
    };

    const result = useQuery({
      queryKey: [resource, 'me'],
      queryFn: query,
      ...queryConfiguration,
    });

    return {
      ...result,
      withMany: (relation, options) => withMany(result, relation, { resource, ...options }),
      withOne: (relation, options) => withOne(result, relation, { resource, ...options }),
    };
  }

  function get(id, queryConfiguration = null) {
    const query = async () => {
      const { data: { data } } = await privateAxios.get(`/${resource}/${id}`);

      return data;
    };

    const result = useQuery({
      queryKey: [resource, id],
      queryFn: query,
      ...queryConfiguration,
    });

    return {
      ...result,
      withMany: (relation, options) => withMany(result, relation, { resource, ...options }),
      withOne: (relation, options) => withOne(result, relation, { resource, ...options }),
    };
  }

  function list(filters = {}, queryConfiguration = null) {
    const query = async () => {
      const { data } = await privateAxios.get(`/${resource}`, { params: filters, paramsSerializer });

      return data;
    };

    const result = useQuery({
      queryKey: [resource, filters],
      queryFn: query,
      ...queryConfiguration,
    });

    return {
      ...result,
      ...(result.data || {}),
      withMany: (relation, options) => withMany(result, relation, options),
      withOne: (relation, options) => withOne(result, relation, options),
    };
  }

  function post(recordId, extension, callbacks = {}) {
    const mutation = async (params) => {
      const { data: { data } } = await privateAxios.post(`/${resource}/${recordId}/${extension}`, params);

      return data;
    };

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  function postPublic(extension, callbacks = {}) {
    const mutation = async (params) => {
      const { data: { data } } = await publicAxios.post(`/${resource}/${extension}`, params);

      return data;
    };

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  function statistics(id, filters = {}, queryConfiguration = null) {
    const query = async () => {
      const { data: { data } } = await privateAxios.get(`/${resource}/${id}/statistics`, { params: filters, paramsSerializer });

      return data;
    };

    return useQuery({
      queryKey: [resource, 'statistics'],
      queryFn: query,
      ...queryConfiguration,
    });
  }

  function update(recordId, callbacks = {}) {
    const mutation = async (params) => {
      const { data: { data } } = await privateAxios.put(`/${resource}/${recordId}`, params);

      return data;
    };

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  return {
    about,
    create,
    destroy,
    fetchMe,
    get,
    list,
    post,
    postPublic,
    resource,
    statistics,
    update,
  };
}
