/* eslint-disable max-len */
import React from 'react';
import { GoWorkflow } from 'react-icons/go';
import FilterBar from '../../../components/layout/FilterBar';
import PageHeader from '../../../components/layout/PageHeader';
import ItemList from '../../../components/ItemsList';
import useIndexFetchClient, { useIndexButtons } from '../../../hooks/useIndexFetchClient';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';
import SearchBar from '../../../components/layout/SearchBar';

const config = {
  clientName: 'workflows',
  resourceName: 'Workflows',
  description: 'A list of all Automation Workflows between your connections.',
  mobileTitle: 'Workflow',
  icon: <GoWorkflow className="h-8 w-8 m-1 text-indigo-700" />,
  indexClientOptions: {
    latentSearchParams: { template: false },
  },
};
export default function IndexPage() {
  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config.clientName);
  const {
    data: workflows,
    isLoading,
  } = indexClient;

  // Buttons for Top Bar
  const buttons = useIndexButtons(config.resourceName, indexClient);

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        open={indexClient.filterOpen}
        setOpen={indexClient.setFilterOpen}
      >
        <FilterForm onCloseModal={() => indexClient.setFilterOpen(false)} />
      </FilterBar>
      <SearchBar
        name="search"
        label="Search Workflows"
        indexClient={indexClient}
        placeholder="Search for Workflows by Name..."
        statusTabConfig={{
          defaultValue: config.indexClientOptions.latentSearchParams.template,
          filterKey: 'template',
          tabs: [
            { name: 'Active', value: false },
            { name: 'Templates', value: true },
          ],
        }}
      />
      <ItemList
        body={<TableBody items={workflows || []} />}
        meta={indexClient.meta}
        header={<TableHeader />}
        isLoading={isLoading}
      />
    </div>
  );
}
