import { flatten } from 'flat';

function addError(form, key, error) {
  form.setError(
    key,
    { type: 'server', message: error },
  );
}

export default function mapErrorsToForm(response, form) {
  const errors = response.errors || {};

  Object.entries(errors).map(([key, message]) => addError(form, key, message));
}

export function displayInputError(errors, name) {
  const flattenedErrors = flatten(errors, { maxDepth: 2 });
  const error = flattenedErrors[name] || errors[name];
  if (!error) return;
  const message = error?.message;
  const errType = error?.type;
  // eslint-disable-next-line consistent-return
  return message.length ? message : errType;
}
