// import { RadioGroup } from '@headlessui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import { displayInputError } from '../../helpers/FormHelpers';
import FormShape from '../../shapes/FormShape';

export default function RadioInput({
  form, label, name, required, helperText, options, inputClass,
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      // eslint-disable-next-line no-unused-vars
      onChange, onBlur, ref, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <fieldset className="mt-2">
        <legend className="sr-only">{name}</legend>
        <div className={inputClass}>
          {options.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                name={name}
                onChange={onChange}
                type="radio"
                value={option.value}
              />
              <label htmlFor={option.value} className="ml-2 block text-sm text-gray-700">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      <p className="mt-2 text-sm text-gray-500">{helperText}</p>
      <p>{displayInputError(errors, name)}</p>
    </>
  );
}

RadioInput.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  required: PropTypes.bool,
  inputClass: PropTypes.string,
};

RadioInput.defaultProps = {
  required: false,
  helperText: null,
  inputClass: 'space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6',
};
