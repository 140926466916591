/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Input from '../../../../components/forms/InputBase';
import FormShape from '../../../../shapes/FormShape';
import ComboBox from '../../../../components/forms/ComboBox';

export default function FormBody({ form }) {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="pt-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Webhook Validation</h3>
          <p className="mt-1 text-sm text-gray-500">
            Configure Webhook validation for this Connection.
          </p>
        </div>
        <div className="flex flex-col space-y-6 mt-6">
          <div className="w-full">
            <Input
              form={form}
              label="Client Secret"
              placeholder="Client Secret..."
              name="client_secret"
              required
              helperText="The client secret is a hashing key provided by the webhook sender."
            />
          </div>
          <div className="w-full">
            <label htmlFor="signature" className="block text-sm font-medium text-gray-700">
              Signature Extraction
            </label>
            <div className="mt-1 min-h-max p-1 border border-gray-300 rounded-md gap-y-6 gap-x-4 p-4">
              <div className="mt-1 min-h-max p-1 border border-blue-500 rounded flex w-full p-2 mb-2">
                <InformationCircleIcon className="mt-0.5 mr-1 w-4 h-4 text-blue-500" />
                <p className="text-sm text-gray-700">The signature is provided by the sender of a webhook to verify the authenticity of a webhook.</p>
              </div>
              <div className="lg:flex lg:space-x-4">
                <div className="lg:w-1/3">
                  <ComboBox
                    options={[
                      { label: 'Webhook Header', value: 'header' },
                      { label: 'Webhook Body', value: 'parameter' },
                    ]}
                    labelKey="label"
                    valueKey="value"
                    label="Location"
                    name="webhook.signature.type"
                    form={form}
                  />
                </div>
                <div className="lg:w-1/3">
                  <Input
                    label="Key Name"
                    placeholder="Key Name..."
                    name="webhook.signature.name"
                    form={form}
                  />
                </div>
                <div className="lg:w-1/3">
                  <Input
                    label="Extraction Regex"
                    placeholder="Extraction Regex..."
                    name="webhook.signature.pattern"
                    form={form}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Input
              form={form}
              label="Comparison String Template"
              placeholder="Comparison String Template..."
              name="webhook.body"
              required
              helperText={(
                <>
                  <p>The comparison string is typically composed of the Webhook Payload and some combination of the Request Method, Request URL, Client Secret, and a Timestamp.</p>
                  <p>Values are extracted from each webhook and formed into a comparison string with the shape defined here.</p>
                  <p>This string will be hashed and then encoded before being compared to the signature.</p>
                </>
              )}

            />
          </div>
          <div className="w-full">
            <label htmlFor="signature" className="block text-sm font-medium text-gray-700">
              Timestamp Extraction
            </label>
            <div className="mt-1 min-h-max p-1 border border-gray-300 rounded-md gap-y-6 gap-x-4 p-4">
              <div className="mt-1 min-h-max p-1 border border-blue-500 rounded flex w-full p-2 mb-2">
                <InformationCircleIcon className="mt-0.5 mr-1 w-4 h-4 text-blue-500" />
                <div>
                  <p className="text-sm text-gray-700">The timestamp may be included to verify the authenticity and to prevent stale webhooks from being used in replay attacks.</p>
                </div>
              </div>
              <div className="lg:flex lg:space-x-4">
                <div className="lg:w-1/3">
                  <ComboBox
                    options={[
                      { label: 'Webhook Header', value: 'header' },
                      { label: 'Webhook Body', value: 'parameter' },
                    ]}
                    labelKey="label"
                    valueKey="value"
                    label="Location"
                    name="webhook.timestamp.type"
                    form={form}
                  />
                </div>
                <div className="lg:w-1/3">
                  <Input
                    label="Key Name"
                    placeholder="Key Name..."
                    name="webhook.timestamp.name"
                    form={form}
                  />
                </div>
                <div className="lg:w-1/3">
                  <Input
                    label="Extraction Regex"
                    placeholder="Extraction Regex..."
                    name="webhook.timestamp.pattern"
                    form={form}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="signature" className="block text-sm font-medium text-gray-700">
              Hasing and Encoding Functions
            </label>
            <div className="mt-1 min-h-max p-1 border border-gray-300 rounded-md gap-y-6 gap-x-4 p-4">
              <div className="mt-1 min-h-max p-1 border border-blue-500 rounded flex w-full p-2 mb-2">
                <InformationCircleIcon className="mt-0.5 mr-1 w-4 h-4 text-blue-500" />
                <div>
                  <p className="text-sm text-gray-700">
                    The comparison string that is built from each webhook will be hashed and then encoded using these methods.
                  </p>
                </div>
              </div>
              <div className="lg:flex lg:space-x-4">
                <div className="lg:w-1/2">
                  <ComboBox
                    options={[
                      { label: 'hmac_sha256', value: 'hmac_sha256' },
                    ]}
                    labelKey="label"
                    valueKey="value"
                    label="Hashing Function"
                    name="webhook.security.method"
                    form={form}
                  />
                </div>
                <div className="lg:w-1/2">
                  <ComboBox
                    options={[
                      { label: 'Base64', value: 'base64' },
                      { label: 'Hex', value: 'hex' },
                    ]}
                    labelKey="label"
                    valueKey="value"
                    label="Location"
                    name="webhook.security.encoding"
                    form={form}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FormBody.propTypes = {
  form: FormShape.isRequired,
};
