import PropTypes from 'prop-types';

export default PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  formState: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
});
