import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import useMe from '../../../context/CurrentUserContext';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import useClient from '../../../hooks/useClient';
import ChangePasswordForm from './Form';

export default function ChangePassword({ onClose }) {
  const { currentUser } = useMe();
  const form = useForm({ defaultValues: currentUser });

  const { isLoading: isSubmitting, mutate } = useClient('users').update('', {
    onSuccess: onClose,
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  return (
    <ChangePasswordForm
      user={currentUser}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onUpdate={(user) => mutate({ user })}
      form={form}
    />
  );
}

ChangePassword.propTypes = {
  onClose: PropTypes.func.isRequired,
};
