import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import FormShape from '../../shapes/FormShape';

export default function CheckBox({
  form,
  label,
  name,
  options,
}) {
  const valueArray = form.watch(name)?.split(' ') || [];

  const onChange = ({ target }) => {
    const { value: inputVal } = target;
    const newFormVal = _.xor(valueArray, [inputVal]);
    form.setValue(name, newFormVal.join(' '));
  };

  return (
    <>
      <label htmlFor="source" className="block font-medium text-sm text-gray-700 ml-1 mt-1">
        {label}
      </label>
      <div className="flex flex-wrap">
        {options.map(({ key }) => (
          <div className="mr-4 mt-1 flex" key={key}>
            <input
              className="mt-1.5 mr-1 h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              name={name}
              value={key}
              checked={valueArray.includes(key)}
              type="checkbox"
              onChange={onChange}
              // eslint-disable-next-line react/jsx-props-no-spreading
              // {...register(name)}
            />
            <label htmlFor="source" className="block text-sm text-gray-700 ml-1 mt-1">
              {key}
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
CheckBox.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};
