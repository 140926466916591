/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Select from '../../../components/layout/FilterBar/Select';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';

export default function FilterForm({ onCloseModal }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValues = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of searchParams.keys()) {
    defaultValues[key] = searchParams.get(key);
  }

  const aboutClient = useClient('events/about');
  const { data, isLoading } = aboutClient.list();

  const {
    control,
    handleSubmit,
  } = useForm({ defaultValues });

  const onChangeFilter = (filter) => {
    const scrubbedFilter = Object.entries(filter).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});
    onCloseModal();
    setSearchParams(scrubbedFilter);
  };

  const options = (data?.topics || []).map((i) => ({ name: i, value: i }));
  if (isLoading) return <Loading />;

  return (
    <form className="" onSubmit={handleSubmit(onChangeFilter)}>
      <Select label="Topic" options={options} control={control} name="topic" />
      {/* <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email address
        </label>
        <div className="mt-6">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div> */}
      <div className="mt-6 flex justify-space-between">
        <button
          onClick={onCloseModal}
          type="button"
          className="mr-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={isSubmitting}
          className="ml-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Filter
        </button>
      </div>
    </form>
  );
}

FilterForm.propTypes = {
  // isSubmitting: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
