/* eslint-disable max-len */
import { SignalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';
import FilterBar from '../../../components/layout/FilterBar/V2FitlerBar';
import PageHeader from '../../../components/layout/PageHeader';
import Loading from '../../../components/Loading';
import EmptyState from '../../../components/IndexPage/EmptyState';
import ItemList from '../../../components/ItemsList';
import useIndexFetchClient, { useIndexButtons } from '../../../hooks/useIndexFetchClient';
import SearchBar from '../../../components/layout/SearchBar';

const config = {
  clientName: 'actions',
  resourceName: 'Action',
  description: 'A list of all Actions that are occuring in your Source Connections',
  mobileTitle: 'Action',
  icon: <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />,
};

export default function IndexPage() {
  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config.clientName);

  // Display Loading or Empty States
  if (indexClient.isLoading) return (<Loading />);
  if (indexClient.requestFailed || !indexClient.data) return (<EmptyState name={config.resourceName} icon={SignalIcon} />);
  if (!indexClient.data.length && !Object.keys(indexClient.urlParamsObj).length) return (<EmptyState name={config.resourceName} icon={SignalIcon} />);

  // Buttons for Top Bar
  const buttons = useIndexButtons(config.resourceName, indexClient);

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        indexClient={indexClient}
      >
        <FilterForm
          onCloseModal={() => indexClient.setFilterOpen(false)}
          indexClient={indexClient}
        />
      </FilterBar>
      <SearchBar
        name="search"
        label="Search Actions"
        indexClient={indexClient}
        placeholder="Search for Actions by Name..."
      />
      <ItemList
        body={<TableBody items={indexClient.data} />}
        meta={indexClient.meta}
        header={<TableHeader />}
      />
    </div>
  );
}
