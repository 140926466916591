/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import ReactJson from 'react-json-view';
import LogShape from '../../../../shapes/LogShape';

export default function RequestHeaders({ log }) {
  return (
    <div className="sm:col-span-2">
      <dt className="text-sm font-medium text-gray-500">Request Headers</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <div className="min-h-max p-1 border rounded-md bg-white">
          <ReactJson src={log.body.headers} name={false} collapsed={1} />
        </div>
      </dd>
    </div>
  );
}

RequestHeaders.propTypes = {
  log: LogShape.isRequired,
};
