import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import FormShape from '../../../shapes/FormShape';
import JSONSuperInput from '../../../components/forms/JSONSuperInput';
import Footer from '../../../components/forms/Footer';

export default function SuperEditForm({
  form,
  onSubmit,
  isSubmitting,
}) {
  const navigate = useNavigate();
  return (
    <form onSubmit={onSubmit}>
      <Header
        name="Connection Clone Form"
        description="Create a connection by starting with a template to clone."
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <div className="mx-auto max-w-3xl lg:max-w-7xl">
        <div className="bg-white px-10 border border-gray-300 rounded mt-6 py-3">
          <JSONSuperInput form={form} label="Connection Object" />
          <Footer
            backButtonText="Back to Connection Index Page"
            onBackButtonClick={() => navigate(-1)}
            submitButtonText="Submit"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
          />
        </div>
      </div>
    </form>
  );
}

SuperEditForm.propTypes = {
  form: FormShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
