import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import WebAppSubForm from './SubForms/WebApp';
import Footer from '../../../components/forms/Footer';
import AuthSubForm from './SubForms/AuthSubForm';
import FormShape from '../../../shapes/FormShape';
import ConnectionTemplateShape from '../../../shapes/ConnectionTemplateShape';

export default function ConnectionForm({
  form,
  onSubmit,
  isSubmitting,
  connectionTemplate,
  skipUsingTemplate,
}) {
  const navigate = useNavigate();
  return (
    <form onSubmit={onSubmit}>
      <WebAppSubForm form={form} connectionTemplate={connectionTemplate} />
      <AuthSubForm
        form={form}
        connectionTemplate={connectionTemplate}
        skipUsingTemplate={skipUsingTemplate}
      />
      <Footer
        backButtonText="Back to Connections"
        onBackButtonClick={() => navigate(-1)}
        submitButtonText="Submit"
        submitButtonDisabled={isSubmitting}
        submitButtonType="submit"
      />
    </form>
  );
}

ConnectionForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: FormShape.isRequired,
  connectionTemplate: ConnectionTemplateShape,
  skipUsingTemplate: PropTypes.bool.isRequired,
};

ConnectionForm.defaultProps = {
  connectionTemplate: null,
};
