import PropTypes from 'prop-types';
import LogShape from './LogShape';

export default PropTypes.shape({
  record_type: PropTypes.oneOf(['event']),
  connection_id: PropTypes.string,
  id: PropTypes.string,
  inserted_at: PropTypes.string,
  topic: PropTypes.string,
  logs: PropTypes.arrayOf(LogShape),
  // eslint-disable-next-line react/forbid-prop-types
  payload: PropTypes.object,
});
