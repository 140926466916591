/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { EditorView } from 'codemirror';
import LogShape from '../../../../shapes/LogShape';

export default function GeneralInfo({ log }) {
  return (
    <div className="sm:col-span-2">
      <dt className="text-sm font-medium text-gray-500">Related Records</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <div className="min-h-max p-1 border rounded-md bg-white">
          <CodeMirror
            editable={false}
            extensions={[
              json(),
              EditorView.lineWrapping,
            ]}
            theme={githubLight}
            value={JSON.stringify(log.records, null, ' ')}
            minHeight="60px"
            basicSetup={{
              highlightActiveLine: false,
              bracketMatching: true,
            }}
          />
        </div>
      </dd>
    </div>
  );
}

GeneralInfo.propTypes = {
  log: LogShape.isRequired,
};
