import { Socket } from 'phoenix';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import useAuth from './AuthContext';
import { websocketURL } from '../config';

const SocketContext = createContext();
const { Provider } = SocketContext;

function SocketProvider({ children }) {
  const { accessToken } = useAuth();
  const [socket, setSocket] = useState();

  useEffect(() => {
    const newSocket = new Socket(
      `${websocketURL}/socket`,
      {
        params: { token: accessToken },
      },
    );

    if (accessToken) {
      newSocket.connect();
    }
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [accessToken, setSocket]);

  if (!socket) return null;

  return (
    <Provider
      value={{
        socket,
      }}
    >
      {children}
    </Provider>
  );
}

SocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SocketContext, SocketProvider };
