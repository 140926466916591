import {
  CheckCircleIcon, InformationCircleIcon, XCircleIcon, XMarkIcon,
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FlashContext } from '../../context/FlashContext';
import FlashShape from '../../shapes/FlashShape';

function flashIcon({ type }) {
  switch (type) {
    case 'information': return <InformationCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
    case 'success': return <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
    case 'error': return <XCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;
    default: return null;
  }
}

function flashColor({ type }) {
  switch (type) {
    case 'information': return 'blue';
    case 'success': return 'green';
    case 'error': return 'red';
    default: return null;
  }
}

function Flash({ flash, dismiss }) {
  const color = flashColor(flash);

  return (
    <div className={`rounded-md bg-${color}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {flashIcon(flash)}
        </div>
        <div className="ml-3">
          { flash.primary
            ? <h3 className={`text-sm font-medium text-${color}-800`}>{flash.primary}</h3>
            : null }
          { flash.secondary
            ? (
              <div className={`mt-2 text-sm text-${color}-700`}>
                <p>{flash.secondary}</p>
              </div>
            )
            : null }
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex bg-${color}-50 rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
              onClick={dismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Flash.propTypes = {
  flash: FlashShape.isRequired,
  dismiss: PropTypes.func.isRequired,
};

export default function FlashBanner() {
  const { flashes, removeFlash } = useContext(FlashContext);

  return flashes.map((flash) => (
    <Flash flash={flash} key={flash.key} dismiss={() => removeFlash(flash)} />
  ));
}
