import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { basicSetup } from 'codemirror';
import { foldService } from '@codemirror/language';
import ErrorBoundary from './ErrorBoundary';

const foldingOnIndent = foldService.of((state, from, to) => {
  const line = state.doc.lineAt(from); // First line
  const { lines } = state.doc; // Number of lines in the document
  const indent = line.text.search(/\S|$/); // Indent level of the first line
  let foldStart = from; // Start of the fold
  let foldEnd = to; // End of the fold

  // Check the next line if it is on a deeper indent level
  // If it is, check the next line and so on
  // If it is not, go on with the foldEnd
  let nextLine = line;
  while (nextLine.number < lines) {
    nextLine = state.doc.line(nextLine.number + 1); // Next line
    const nextIndent = nextLine.text.search(/\S|$/); // Indent level of the next line

    // If the next line is on a deeper indent level, add it to the fold
    if (nextIndent > indent) {
      foldEnd = nextLine.to; // Set the fold end to the end of the next line
    } else {
      break; // If the next line is not on a deeper indent level, stop
    }
  }

  // If the fold is only one line, don't fold it
  if (state.doc.lineAt(foldStart).number === state.doc.lineAt(foldEnd).number) {
    return null;
  }

  // Set the fold start to the end of the first line
  // With this, the fold will not include the first line
  foldStart = line.to;

  // Return a fold that covers the entire indent level
  return { from: foldStart, to: foldEnd };
});

export default function JsonDisplay({ wrapperClass, valueStr }) {
  const ref = useRef(0);

  if (!valueStr) return <p className="text-xs text-gray-500">N/A</p>;
  return (
    <ErrorBoundary>
      <div className="overflow-scroll border border-gray-300 rounded">
        <div className={wrapperClass} ref={ref}>
          <CodeMirror
            editable={false}
            extensions={[
              // basicSetup,
              foldingOnIndent,
            ]}
            theme={githubLight}
            value={valueStr}
            minHeight="60px"
            basicSetup={{
              highlightActiveLine: false,
              bracketMatching: true,
            }}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

JsonDisplay.propTypes = {
  wrapperClass: PropTypes.string,
  valueStr: PropTypes.string,
};

JsonDisplay.defaultProps = {
  wrapperClass: null,
  valueStr: null,
};
