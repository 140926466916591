import React from 'react';
import PropTypes from 'prop-types';

export default function PageWrapper({ children }) {
  return (
    <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl">
      {/* <div className="max-w-3xl lg:max-w-7‘xl"> */}
      {children}
    </div>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
