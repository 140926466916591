import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import qs from 'qs';
import useClient from '../../../hooks/useClient';
import Form from '../Form';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Loading from '../../../components/Loading';
import EventShape from '../../../shapes/EventShape';

export default function ActionCreatePageEventWrapper() {
  const { search } = useLocation();
  const searchParams = qs.parse(search.substring(1));
  const eventId = searchParams.event_id;
  const { data: exampleEvent, isFetching } = useClient('events').get(
    eventId,
    { enabled: !!eventId, staleTime: Infinity, cacheTime: Infinity },
  );

  if (isFetching) return <Loading />;

  return (<ActionCreatePage searchParams={searchParams} exampleEvent={exampleEvent} />);
}

function ActionCreatePage({ searchParams, exampleEvent }) {
  const defaultValues = {
    topic: '',
    schedule: '',
    trigger_type: '',
    configuration: {
      record_type: 'request',
      url: '{{destination_connection.api.url}}/',
    },
  };

  const form = useForm({ defaultValues: { ...defaultValues, ...searchParams } });
  const client = useClient('actions');
  const navigate = useNavigate();
  const { isLoading: isSubmitting, mutate } = client.create({
    onSuccess: ({ id }) => navigate(`/actions/${id}`),
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((action) => mutate({ action }));

  return (
    <Form
      description="Create a new action to make cool things happen."
      form={form}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      exampleEvent={exampleEvent}
      title="New Action Form"
    />
  );
}

ActionCreatePage.propTypes = {
  exampleEvent: EventShape,
  searchParams: PropTypes.shape({
    source_connection_id: PropTypes.string,
    topic: PropTypes.string,
    event_id: PropTypes.string,
  }),
};

ActionCreatePage.defaultProps = {
  searchParams: {},
  exampleEvent: null,
};
