import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ActionShape from '../../../../../shapes/ActionShape';
import LogShape from '../../../../../shapes/LogShape';
import LinkButton from '../../../../../components/buttons/LinkButton';
import MidDot from '../../../../../components/MidDot';
import useClient from '../../../../../hooks/useClient';

import { connectionAvatar } from '../../../../../helpers/ConnectionHelpers';
import useMe from '../../../../../context/CurrentUserContext';
import ModalBody from '../../../../Action/ShowPage/LogDetailModalBody';
import Modal from '../../../../../components/Modal';
import RequestListModalBody from './RequestListModalBody';
import EventBody from './EventBody';

function logStatusColor(log) {
  const statusCode = log.body?.http_status;
  if (statusCode >= 200 && statusCode < 299) return 'bg-teal-50 text-teal-500 ring-teal-500';
  if (statusCode >= 400 && statusCode < 499) return 'bg-yellow-50 text-yellow-500 ring-yellow-500';
  if (statusCode >= 500) return 'bg-red-50 text-red-500 ring-red-500';
  return 'bg-gray-50 text-gray-500 ring-gray-500';
}
export default function YetToSucceed({ action, actionLogs }) {
  const [displayEvent, setDisplayEvent] = useState(null);
  const [displayRequest, setDisplayRequest] = useState(null);
  const [displayRequestList, setDisplayRequestList] = useState(false);

  const attempts = actionLogs.length;
  // eslint-disable-next-line radix
  const maxRetryCount = parseInt(action.retry_strategy?.max_attempts || 5);
  const maxAttemptsReached = attempts >= maxRetryCount;
  const eventId = actionLogs[0].records.event_id;

  // const { data: jobs, isLoading: isLoadingJobs } = useClient('jobs').list(
  //   { records: { event_id: eventId }, page_size: 100 },
  //   { enabled: !!eventId },
  // );

  const { isLoading: isSubmitting, mutate } = useClient('logs').post(
    actionLogs[0].id,
    'retry',
    {
      onSuccess: (r) => console.log('boom', r),
      onError: (error) => console.log(error.response.data),
    },
  );

  const { connections } = useMe();
  const destinationConnection = connections.find((c) => c.id === action?.destination_connection_id);

  const processLog = actionLogs[0];

  return (
    <div key={action.id} className="rounded p-2 text-gray-500 ring-2 ring-red-500 text-xs space-y-1">
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={!!displayEvent}
        onClose={() => setDisplayEvent()}
      >
        <EventBody
          eventId={eventId}
          onClose={() => setDisplayEvent()}
        />
      </Modal>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={displayRequestList}
        onClose={() => setDisplayRequestList(false)}
      >
        <RequestListModalBody
          logs={actionLogs}
          onClose={() => setDisplayRequestList(false)}
          setCurrentLog={setDisplayRequest}
        />
      </Modal>
      <Modal
        maxWidth="sm:max-w-5xl sm:w-full"
        open={!!displayRequest}
        onClose={() => setDisplayRequest(null)}
      >
        <ModalBody log={displayRequest} onClose={() => setDisplayRequest(null)} />
      </Modal>
      <div className="flex justify-between border-b w-full pb-1">
        <Link to={`/actions/${action?.id}`} className="text-indigo-700">
          {action?.name}
        </Link>
        <span className={classNames('flex space-x-1 font-semidbold', maxAttemptsReached ? 'text-red-500' : 'text-gray-500')}>
          { maxAttemptsReached ? 'Failed' : 'Retry Pending'}
          <ExclamationCircleIcon className={classNames('h-4 w-4 hover:cursor-pointer', maxAttemptsReached ? 'text-red-500' : 'text-gray-500')} />
        </span>
      </div>
      <span className="flex space-x-1 md:flex">
        <p className="text-gray-500">Endpoint:</p>
        <div className="text-gray-500 flex space-x-1">
          <span>
            {connectionAvatar(destinationConnection, { size: 'xxs' })}
          </span>
          <span className="hidden xl:block">
            {destinationConnection?.configuration?.url}
          </span>
          <p className="text-teal-600 font-semibold">
            {processLog.body.method}
          </p>
          <p>{processLog.body.url?.replace(destinationConnection?.configuration?.url, '')}</p>
          <p
            className={
              classNames(
                'max-h-4 inline-flex items-center rounded px-0.5 text-xs font-medium ring-1 ring-inset',
                logStatusColor(processLog),
              )
            }
          >
            {processLog.body.http_status}
          </p>
        </div>
      </span>
      <span className="flex space-x-1 w-full">
        <p className="text-gray-500">Attempts:</p>
        <span className="flex">
          <LinkButton
            className="font-medium text-xs"
            onClick={() => setDisplayRequestList(true)}
            buttonText={`${actionLogs?.length} Attempt${actionLogs?.length === 1 ? '' : 's'}`}
          />
          { maxAttemptsReached ? <p>(Max attempts reached)</p> : null }
        </span>
      </span>
      <span className="flex justify-end w-full">
        <LinkButton className="text-xs mt-0.5" buttonText="Event Body" onClick={() => setDisplayEvent(eventId)} />
        <MidDot />
        <LinkButton className="text-xs mt-0.5" buttonText="Retry Request" onClick={() => mutate()} />
      </span>
    </div>
  );
}

YetToSucceed.propTypes = {
  action: ActionShape.isRequired,
  actionLogs: PropTypes.arrayOf(LogShape).isRequired,
};
