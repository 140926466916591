import React from 'react';

export function userAvatar({ name }, size = 8) {
  const [first] = name;

  // return <img alt="profile-pic" src="https://tuk-cdn.s3.amazonaws.com/assets/components/boxed_layout/bl_1.png" className="w-8 h-8 rounded-md" />;
  // return <img className="rounded-full h-10 w-10 object-cover" src="https://tuk-cdn.s3.amazonaws.com/assets/components/horizontal_navigation/hn_2.png" alt="logo" />;
  return (
    <div className={`bg-gray-400 h-${size} w-${size} flex items-center justify-center text-white inline-block mr-1 rounded-md font-bold`}>
      {first}
    </div>
  );
}

export default {
  avatar: userAvatar,
};
