/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/24/outline';
import cronstrue from 'cronstrue';
import ActionShape from '../../../shapes/ActionShape';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import UrlPreview from '../../../components/UrlPreview';
import LinkButton from '../../../components/buttons/LinkButton';
import useMe from '../../../context/CurrentUserContext';

const findConnection = (a, key, connections) => (
  connections?.find(({ id }) => id === a[key])
);

export default function Body({ items }) {
  const { connections } = useMe();
  return (
    <tbody className="divide-y divide-gray-300 bg-white">
      {
        items.map((item) => {
          const sourceConnection = findConnection(item, 'source_connection_id', connections);
          const destinationConnection = findConnection(item, 'destination_connection_id', connections);
          return (
            <tr key={item.id}>
              <td className="relative py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="font-medium text-gray-900 mb-2">
                  <LinkButton buttonText={item.name || item.id} path={`/actions/${item.id}`} />
                </div>
                <div className="text-gray-500 sm:block xl:hidden ml-3">
                  <div className="mt-1 flex">
                    <span className="font-medium text-gray-700 mr-2">Trigger:</span>
                    {
                      sourceConnection
                        ? (
                          <>
                            <span className="mr-1 mt-0.5">
                              {connectionAvatar(sourceConnection, { size: 'xxs' })}
                            </span>
                            <LinkButton
                              className="text-gray-700"
                              buttonText={sourceConnection?.name}
                              path={`/connections/${sourceConnection?.id}`}
                            />
                            {' '}
                            <span className="mx-1" aria-hidden="true">&middot;</span>
                            {' '}
                            {item.topic}
                          </>
                        )
                        : (
                          <>
                            <span className="mr-1 mt-0.5=">
                              <ClockIcon className="mr-1 mt-0.5 w-4 h-4 border border-gray-300 rounded" />
                            </span>
                            <p className="mt-0.5">Scheduled Task</p>
                            <span className="mx-1" aria-hidden="true">&middot;</span>
                            {' '}
                            <p className="mt-0.5">
                              {item.schedule ? cronstrue.toString(item.schedule) : null}
                            </p>
                          </>
                        )
                    }
                  </div>
                  <div className="flex mt-1">
                    <span className="font-medium text-gray-700 mr-2 flex">Destination:</span>
                    <span className="mr-1 mt-0.5">
                      {connectionAvatar(destinationConnection, { size: 'xxs' })}
                    </span>
                    <LinkButton
                      className="text-gray-700"
                      buttonText={destinationConnection?.name}
                      path={`/connections/${destinationConnection?.id}`}
                    />
                  </div>
                  <div className="flex mt-1">
                    <span className="font-medium text-gray-700 mr-2 flex">Request:</span>
                    <span className="mt-0.5">
                      <UrlPreview
                        method={item.configuration.method}
                        url={item.configuration.url}
                        connection={destinationConnection}
                        config={{ hideConnectionUrl: true }}
                      />
                    </span>
                  </div>
                </div>
                <div className="hidden xl:block ml-4">
                  {
                    sourceConnection
                      ? (
                        <>
                          <span className="text-gray-700 font-medium flex">
                            <span className="mr-1 mt-0.5">
                              {connectionAvatar(sourceConnection, { size: 'xxs' })}
                            </span>
                            <LinkButton
                              className="text-gray-700"
                              buttonText={sourceConnection?.name}
                              path={`/connections/${sourceConnection?.id}`}
                            />
                          </span>
                          <p className="text-gray-500 text-xs">{item.topic}</p>
                        </>
                      )
                      : (
                        <>
                          <span className="text-gray-700 font-medium flex">
                            <ClockIcon className="mr-1 mt-0.5 w-4 h-4 border border-gray-300 rounded" />
                            Scheduled Task
                          </span>
                          <p className="text-gray-500 text-xs">
                            {item.schedule ? cronstrue.toString(item.schedule) : null}
                          </p>
                        </>
                      )
                  }
                </div>
              </td>
              <td className="hidden px-3 py-3.5 text-sm text-gray-500 xl:table-cell">
                <span className="text-gray-700 font-medium flex mt-6">
                  <span className="mr-1 mt-0.5">
                    {connectionAvatar(destinationConnection, { size: 'xxs' })}
                  </span>
                  <LinkButton
                    className="text-gray-700"
                    buttonText={destinationConnection?.name}
                    path={`/connections/${destinationConnection?.id}`}
                  />
                </span>
                <div className="text-xs">
                  <UrlPreview
                    method={item.configuration.method}
                    url={item.configuration.url}
                    connection={destinationConnection}
                    config={{ hideConnectionUrl: true }}
                  />
                </div>
              </td>
            </tr>
          );
        })
      }
    </tbody>
  );
}

Body.propTypes = {
  items: PropTypes.arrayOf(ActionShape).isRequired,
};
