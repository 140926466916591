import PropTypes from 'prop-types';

export default PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  inserted_at: PropTypes.string.isRequired,
  records: PropTypes.shape({
    action: PropTypes.string,
    event: PropTypes.string,
    inbound_connection: PropTypes.string,
    outbound_connection: PropTypes.string,
    rule: PropTypes.string,
  }),
  topic: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});
