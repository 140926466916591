/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
/* eslint-disable jsx-a11y/label-has-associated-control */
import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { EditorView } from 'codemirror';
import ActionShape from '../../../../shapes/ActionShape';
import UrlPreview from '../../../../components/UrlPreview';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import LinkButton from '../../../../components/buttons/LinkButton';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';

export default function RequestCard({ action }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Request Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about the path and body of this actions request.</p>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 m-4">
            <div className="col-span-1">
              <dt className="text-sm font-medium text-gray-500">Destination</dt>
              <dd className="mt-1 text-sm text-gray-900 flex">
                {
                  action.destinationConnection
                    ? (
                      <>
                        <span className="mr-1">
                          {connectionAvatar(action.destinationConnection, { size: 'xs' })}
                        </span>
                        <LinkButton
                          buttonText={action.destinationConnection?.name}
                          path={`/connections/${action.destinationConnection?.id}`}
                        />
                      </>
                    )
                    : 'N/A'
                }

              </dd>
            </div>
            <div className="col-span-2">
              <dt className="text-sm font-medium text-gray-500">Request Path</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <UrlPreview
                  method={action.configuration.method}
                  url={action.configuration.url}
                  event={action.lastEvent}
                  connection={action.destinationConnection}
                />
              </dd>
            </div>
            {
              action.configuration.method !== 'get'
                ? (
                  <div className="col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Request Body</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="min-h-max p-1 border border-gray-300 rounded-md bg-white">
                        <CodeMirror
                          editable={false}
                          extensions={[
                            json(),
                            EditorView.lineWrapping,
                          ]}
                          theme={githubLight}
                          value={action.configuration.body}
                          minHeight="60px"
                          maxHeight={expanded ? null : '200px'}
                          basicSetup={{
                            highlightActiveLine: false,
                            bracketMatching: true,
                          }}
                        />
                      </div>
                    </dd>
                    <div className="mt-1 flex justify-end">
                      <LinkButton buttonText={!expanded ? 'Expand' : 'Reduce'} onClick={() => setExpanded(!expanded)} />
                    </div>
                  </div>
                )
                : null
            }
          </dl>
        </div>
      </ErrorBoundary>
    </section>
  );
}

RequestCard.propTypes = {
  action: ActionShape.isRequired,
};
